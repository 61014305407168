const LOCAL_REQUSTED_KEY = 'transportRequests'

function getShippingStatusText(shippingStatus) {
    switch (shippingStatus) {
      case 2:
        return 'Vehicle has been shipped'
      case 3:
        return 'Your transportation order has been accepted'
      case 4:
        return 'Vehicle was returned'
      case 5:
        return 'Your transportation order has been requested'
      case 6:
        return 'Your transportation order has been rejected'
      case 7:
        return 'Vehicle was shipped to auction'
      case 8:
        return 'Vehicle was delivered'
      case 9:
        return 'Vehicle is at auction'
      case 10:
        return 'Vehicle delivery is scheduled'
      case 11:
        return 'Your transportation order has been cancelled'
      case 12:
        return 'Your transportation order is pending'
      case 13:
        return 'Your transportation order has been booked'
      case 14:
        return 'Vehicle has been picked up'
      case 15:
        return 'Vehicle is in transit'
      case 16:
        return 'Your transportation order has been closed'
      default:
        return 'Shipping not available for this vehicle'
    }
}

/** `getRequestedItems` - reads local storage and returns
 *  items requested for transportation service
 * 
 * @returns {string[]} `transportItems`
 */
function getRequestedItems () {
    try {
        const transportItemsStr = localStorage.getItem(LOCAL_REQUSTED_KEY);
        if (transportItemsStr === null) {
            return [];
        }
        const transportItems = JSON.parse(transportItemsStr);
        if (transportItems instanceof Array ) {
            return transportItems;
        } else {
            console.log(transportItemsStr); // debug
            return [transportItems];
        }
        
    } catch (err) {
        console.error('Error reading local storage, transportRequest tracking will not work properly.')
        return [];
    }
} 

/** 
 * 
 * @param {string} itemId unique itemId of vehicle requested for transport
 * @returns {void}
 */
function addRequstedItem (itemId) {
    if (itemId && typeof itemId === 'string') {
        const currentItems = getRequestedItems();
        currentItems.push(itemId);
        const requestedItemsSet = [...new Set(currentItems)];
        localStorage.setItem(LOCAL_REQUSTED_KEY, JSON.stringify([...requestedItemsSet.values()]));
        return;
    } else {
        console.error(`addRequestedItem:  Item ID was either: empty string, not a string, or undefined.`);
    }
}

/** `clearAllRequstedForTransport` - 
 * @returns {void}
 */
function clearAllRequstedForTransport () {
    localStorage.removeItem(LOCAL_REQUSTED_KEY);
}

export const transportRequests = {
    getRequestedItems,
    addRequstedItem,
    getShippingStatusText,
    clearAllRequstedForTransport
}
