import { getConfig } from "../helpers";

/** Adds StratosLoader (a.k.a. 'Darwin Bookout' | 'Vehicle Values' ) script to the DOM if it does not already exist.
 * 
 * @param {StratosConfig} stratosConfig Object from MP Web configuration - `webConfig.stratosConfig`
 * @returns {void}
 */
const addStratosLoaderScript = (stratosConfig) => {
    
    if (stratosConfig && stratosConfig.dealerGuid && stratosConfig.scriptSrc) {
      const elementId = "StratosLoader";

      try {
        const existingStratosElement = document.getElementById(elementId);
        
        if (existingStratosElement) {
          return; 
        
        } else {
          const {scriptSrc, dealerGuid} = stratosConfig;
          const scriptElement = document.createElement('script');
          scriptElement.src = scriptSrc;
          scriptElement.id = elementId;
          scriptElement.defer = true;
          scriptElement.setAttribute('dealerguid', dealerGuid);
          document.body.appendChild(scriptElement);
        }

      } catch (stratosLoaderError) {
        console.error(stratosLoaderError?.message || stratosLoaderError);
      }
    }
  }

/** Tell Darwin Bookout to refresh it's metadata scan
 * @param {StratosConfig} stratosConfig
 * @returns {void}
 */
const postStratosMessage = (stratosConfig) => {
  if (!stratosConfig) return;
  if (!window) return;
  if (!(window.top === window.parent)) return;
  
  const elementId = "StratosLoader"
  const darwinDataClassName = ".darwinVehicleData"
  const buttonClassName = ".darwinVehicleButton"

  try {
    const existingStratosElement = document.getElementById(elementId);
    const existingDataElement = document.querySelector(darwinDataClassName);
    const existingButtonElement = document.querySelector(buttonClassName);

    if (!existingStratosElement) {
      return; 
  
    } else if (existingDataElement && existingButtonElement) {
      const {origin} = window;
      const stratosEventMessage = {event: "dorefresh", origin};
      console.info(`Posting message to StratosLoader: ${JSON.stringify(stratosEventMessage, null, '\t')}`);
      window.postMessage(stratosEventMessage, origin);
    }

  } catch (stratosInitError) {
    console.error("Error sending message to Stratos scraper", stratosInitError?.message || stratosInitError);
  }
    
}

/**
 * @typedef {Object} StratosConfig
 * @property {string} dealerGuid
 * @property {string} scriptSrc
 * 
 */

/** @type {StratosConfig} */

export {addStratosLoaderScript, postStratosMessage};