import React, { useState, useEffect, useReducer } from 'react';
import { toastr } from 'react-redux-toastr';
import { Button } from 'semantic-ui-react';
import { fetchGetShippingQuote, resetShippingQuote } from '../../actions/index.js'
import { useDispatch, useSelector } from "react-redux";
import { commafyCurrency, transportRequests } from '../../utils/helpers/index.js';
import runbuggyImg from '../../assets/images/runbuggy.png';
import acertusImg from '../../assets/images/acertus.png';
import royaltyImg from '../../assets/images/royalty.png';
import { PROVIDERS, TransportationModalMode } from './TransporationConstants.js';

const providerImgMap = {
  Acertus: {
    src: acertusImg,
    styles: {}
  },
  RoyaltyShips: {
    src: royaltyImg,
    styles: {}
  },
  Runbuggy: {
    src: runbuggyImg,
    styles: {backgroundColor: "#000000"}
  },
}

const checkIfValidZip = (zip="") => {
  return zip && /\d{5}/.test(zip);
} 

/** 
 * @param {TransportationQuotesProps} props 
 * @returns {React.JSX.Element}
 */
export default function TransportationQuotes({
  destinationZip, 
  mpId,
  itemData,
  onSelect,
  provider='Acertus',
  providers=[],
}) {
    /* Redux */  
    const dispatch = useDispatch();
    const accountList = useSelector(({entities}) => entities.userProfile?.user?.accountList) || [];
    
    /* State */
    const [zip, setZip] = useState(destinationZip);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const initialQuotesState = {};
    if (!providers.length) providers.push(provider);
    providers.forEach(providerKey => {
        initialQuotesState[providerKey] = {
          errorMessage: "",
          isLoading: true, 
          rate: 0, 
          selectionMode: TransportationModalMode[providerKey.toUpperCase()],
        }
    });
    const [quotes, dispatchQuotes] = useReducer(quoteReducer, initialQuotesState);

    /* Effects */
    useEffect(() => {
        dispatch(resetShippingQuote());
      }, [itemData.vin, dispatch]);

    /* Submit handlers */
    const handleSubmit = (e) => {
      const { itemId, vehicleZip } = itemData;
      const isValidOriginZip = checkIfValidZip(vehicleZip);
      const isValidDestinationZip = checkIfValidZip(zip);
      if (!(isValidOriginZip && isValidDestinationZip)) {
        toastr.error(
          'Shipping quote error',
          !isValidDestinationZip ? 'Destination ZIP code not found' : 'Origin ZIP code not found or is invalid',
          {position: 'top-center'}
        );
        return;
      }
      
      dispatch(resetShippingQuote());
      setIsLoading(true);
      Promise.all(providers.map(async quoteProvider => {
        return await dispatch(fetchGetShippingQuote(
          mpId,
          itemId,
          vehicleZip,
          zip,
          quoteProvider))
        .then(res=>dispatchQuotes({quoteProvider, data: res.response}))
      }))
      .then((val)=>{
        setIsLoaded(true);
        setIsLoading(false);
      })
      .catch(err => {
        toastr.error(
          'Shipping quote error', 
          err?.message || err, 
          {position: 'top-center'}
        );
        setIsLoaded(true);
        setIsLoading(false);
      });
    }

    const handleSelection = (selectionData) => {
      const {selectionMode} = selectionData;
      onSelect(selectionMode, selectionData);
    }

    const isItemSold = [itemData?.listingStatus, itemData?.status].includes("Sold");
    let isUserBuyer = false;
    if (isItemSold){
      isUserBuyer = accountList.some(acct => acct.accountNumber === itemData.buyerNumber);
    }
    
    /* Shipping already requested */
    if (!([0,1].includes(itemData.shippingDetails?.shippingStatusId))) {
      return (
        <div className='marketplace__modal_transport-container' style={{marginTop: 25}}>
          <span>{transportRequests.getShippingStatusText(itemData.shippingDetails?.shippingStatusId ?? 0)}</span>
        </div>
      )
    }
    
    return (
    !providers.length 
      ? null 
      : (
      <>
        {/* ZIP input | 'Get Quote' button */}
        <div className='marketplace__modal_transport-container'>
        <TransportationQuotesInput
          onSubmit={handleSubmit}
          onZipChange={setZip}
          zip={zip}
          isLoading={isLoading}
          quoteCount={providers.length}
        />
        </div>
        <hr style={styles.divider} />
        {/* Provider Quote results */}
        <div className={`marketplace_modal_transport-providers${(isLoading||isLoaded) ? ' transport-providers_visible' : ''}`}>
          {providers.map((providerName,i) => {
            const quoteState = quotes[providerName];
            const providerImg = providerImgMap[providerName];
            if (!PROVIDERS.includes(providerName)) return null;
            return (
              <div 
                className={'marketplace_modal_transport-provider'}
                style={{
                  borderBottom: (i !== providers.length - 1) ? '1px dashed rgba(144,144,144,0.5)' : 'none',
                  paddingBottom: (i !== providers.length - 1) ? 10 : 0,
                }} 
                key={i}
              >
                <img 
                  alt={providerName.toLowerCase()}
                  src={providerImg?.src || ""}
                  style={{
                    ...styles.transporterProviderLogo, 
                    ...providerImg.styles,
                  }}
                  onClick={(e)=>null}
                />
                {quoteState.errorMessage
                  ? (
                    <div className='marketplace__modal_transport-quote_container'>
                      <span style={{ color: 'red' }}>{quoteState.errorMessage}</span>
                    </div>
                  )
                
                  : (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div className='marketplace__modal_transport-quote_container'>
                        <div style={{ fontWeight: 700 }}>Shipping Quote</div>
                        
                        <blockquote
                          style={{
                            backgroundColor: 'rgba(238,238,238,0.25)',
                            fontSize: 16,
                            margin: 0,
                            padding: '10px 5px',
                            textAlign: 'right',
                            width: 90,
                          }}
                        >
                          {quoteState.isLoading
                            ? isLoading ? 'Loading ...' : ' '
                            : (quoteState.rate && commafyCurrency(quoteState.rate)) || 'N/A'
                          }
                        </blockquote>
                      </div>
                      {quoteState.isLoading || !(isItemSold && isUserBuyer)
                        ? null
                        : (
                          <button
                            style={styles.linkButton}
                            onClick={()=>handleSelection({...quoteState})}
                          >
                            Book Transportation
                          </button>
                        )}
                    </div>
                  )
                }
              </div>)
            })}
        </div>
      </>
    )
  )
}

/**
 * 
 * @param {{[key in Providers]: unknown}} prevState 
 * @param {{quoteProvider: Providers, data: object, [key:string]: any}} action 
 */
const quoteReducer = (prevState, action) => {
  switch (action.quoteProvider) {
    case 'Acertus': 
      return {...prevState, Acertus: {...prevState.Acertus, ...action.data, isLoading: false}};
    case 'RoyaltyShips':
      return {...prevState, RoyaltyShips: {...prevState.RoyaltyShips, ...action.data, isLoading: false}};
    case 'Runbuggy':
      return {...prevState, Runbuggy: {...prevState.Runbuggy, ...action.data, isLoading: false}};
    default: 
      console.error(action?.data?.errorMessage)
      return prevState;
  }
}

/** Transport quote zip input and submit button
 * 
 * @param {{
 *  zip:string|number, 
 *  isLoading:boolean, 
 *  onChange:()=>void, 
 *  onSubmit:()=>void,
 *  quoteCount:number|undefined}} props 
 * @returns 
 */
function TransportationQuotesInput ({zip, isLoading, onZipChange, onSubmit, quoteCount}) {
  const [isFocused, setIsFocused] = useState(false);
  const buttonText = quoteCount && quoteCount > 1 ? "Get Quotes" : "Get Quote";
  const isValidZip = checkIfValidZip(zip);
  return (
    <>
      <label style={{ paddingBottom: 2.5 }} htmlFor="destinationZip">
        Enter your zip code and get an instant quote
      </label>
      {(isFocused && !isValidZip)
        ? (
          <p 
            style={{color: 'red', textAlign: 'center', margin: '0px auto', paddingBottom: 2.5}}
          >
            Please enter a valid zip code.
          </p>
        )
        : null 
      }
      <div style={{ display: 'flex', alignItems: 'flex-end', gap: 15 }}>
        <input
          className='marketplace__modal_transport-input_zip'
          id="destinationZip"
          type="text"
          maxLength={5}
          style={styles.transportationInput}
          placeholder="Destination ZIP"
          value={zip}
          onChange={e => {
            onZipChange(e.target.value.replace(/\D/g, ''));
          }}
          onFocus={(e => setIsFocused(true))}
        />

        <Button
          style={styles.transportationQuoteButton}
          disabled={!isValidZip || isLoading}
          onClick={onSubmit}
        >
          {isLoading ? '...Please wait' : buttonText}
        </Button>
      </div>
    </>
  )
}

const styles = {
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 5,
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#6492bd',
    cursor: 'pointer',
    margin: '.25em 0 0',
    padding: '10px 0 5px',
    textAlign: 'center',
  },
  transportationInput: {
      backgroundClip: 'padding-box',
      backgroundColor: '#fff',
      border: '1px solid #a9a9a9',
      borderRadius: 4,
      maxWidth: '100%',
      padding: '3px 6px',
      marginTop: 0,
      verticalAlign: 'middle',
      width: 100,
      display: 'block',
      height: 40,
    },
    transportationQuoteButton: {
      borderRadius: 4,
      width: 'auto',
      color: 'white',
      backgroundColor: 'black',
      height: 40,
    },
    transporterProviderLogo: {
      height: 40,
      border: '1px solid #ccc',
      borderRadius: 3,
      cursor: 'pointer',
      backgroundColor: '#fff',
      margin: 5,
      padding: 5
    },
};



/** Transport Quotes - props
 * @typedef {'Acertus'|'Runbuggy'|'RoyaltyShips'} Providers
 */
/** @type {Providers} */

/**
 * @typedef {keyof typeof object as TransporationModalMode} ModalModeKeys
 */
/** @type {ModalModeKeys} */

/**
 * @typedef {Object} TransportationQuotesProps
 * @property {string|number} destinationZip
 * @property {string|number} mpId
 * @property {{vin: string, [key:string]: any}} itemData
 * @property {Providers} [provider="Acertus"] default = _'Acertus'_
 * @property {(Providers)[]} [providers] Array of enabled providers by MP feature #
 *                                               * takes precedence over `provider`
 * @property {(selection: TransportationModalMode[keyof typeof TransportationModalMode<T>], quote: object )=>void} onSelect
 * 
 */
/** @type {TransportationQuotesProps} */
