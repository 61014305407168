import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import 'react-table/react-table.css';
import DataTablePrintable from './DataTablePrintable';
let styles = {};

export default class TableButtonPrint extends Component {
  state = {
    printableContent: null,
  };

  componentDidMount() {
    this.setState({
      printableContent: this.makePrintableContent(),
    });
  }

  /* Limit # of rows */
  makePrintableData() {
    const ROW_LIMIT = 300; // ~30 pages
    const {dataForButtonActions=[]} = this.props;
    if (dataForButtonActions.length > ROW_LIMIT) {
      return dataForButtonActions.slice(0, ROW_LIMIT);
    } else {
      return dataForButtonActions;
    }
  }

  makePrintableContent() {
    return (
      <div style={{ display: 'none' }}>
        <div ref={node => (this.printRef = node)}>
          <DataTablePrintable
            columns={this.props.columns}
            data={this.props.data}
            dataForButtonActions={this.makePrintableData()}
            selectedData={this.props.selectedData}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <ReactToPrint
          pageStyle="@page { size: landscape; margin: 0cm !important; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }"
          documentTitle={this.props.activityType}
          onBeforePrint={() => false}
          onAfterPrint={() => false}
          removeAfterPrint={true}
          trigger={() => (
            <button className="btn btn-primary" style={styles.button}>
              Print
            </button>
          )}
          content={() => this.printRef}
        />

        {this.state.printableContent}
      </div>
    );
  }
}

styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: 2,
    marginRight: 12,
    marginBottom: 2,
    marginLeft: 0,
    padding: '2px 5px',
    height: 24,
    width: 60,
  },
};

TableButtonPrint.defaultProps = {};

TableButtonPrint.propTypes = {
  activityType: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  selectedData: PropTypes.array,
  dataForButtonActions: PropTypes.array,
  headers: PropTypes.array,
};
