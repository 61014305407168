import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { values } from 'lodash';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { loadNotifications, loadUnReadNotification, loadReadNotification } from '../actions';
import { getConfig, stripHtml } from '../utils/helpers';
import { alertTypes } from '../utils/constants';
const timezone = jstz.determine().name();

class Notifications extends Component {
  componentDidMount() {
    this.loadNotifications();
  }

  loadNotifications = () => {
    this.props.loadNotifications(getConfig('marketplaceId'));
  };

  markAsReadOrUnread = async (event, item) => {
    event.stopPropagation();
    const mpId = getConfig('marketplaceId');
    try {
      if (item) {
        const {markAsRead, notificationId, receiverUserId} = item;
        markAsRead
          ? await this.props.loadUnReadNotification(mpId, notificationId, receiverUserId)
          : await this.props.loadReadNotification(mpId, notificationId, receiverUserId);
        
      } else {
        console.error(' Error:  no notification item in arg')
        return;
      }
    } catch (err) {
      console.error(' Notification Read Error: ', err?.message || err)
    } finally {
      this.props.loadNotifications(mpId);
    }
  }
 
  createNotificationList() {
    const notificationItems = this.props.notifications.items;
    const items = values(notificationItems).map((item, index) => {
      const notificationTitle = alertTypes.find(i => i.id === item.notificationTypeId)?.notificationTitle;
      const notificationLink = item.notificationTypeId === 1040 ? `/savedsearches` : `/item/${item.listingId}`;
      // * Note (2025.02) - Unsure what the intention was with 
      //                    the 1030 id other than reversing the 'read'/unread' colors?
      // if (item.notificationTypeId !== 1030 && item.markAsRead === false) {
      return (
          <div className='notifications-list__item' key={index}>
            {this.renderStatusIcon(item.markAsRead)}
            <div className="notification-item__message">
              <h4>
                <Link
                  to={notificationLink}
                  className="notification-type"
                >
                  {notificationTitle}
                </Link>{' '}
                <br /> 
                {item.subject}
              </h4>
              {this.renderMessage(item)}
            </div>
            <div className="notification-item__footer">
              {moment.tz(`${item.dateCreated}`, timezone).fromNow()}
              <br />
              {this.renderDetailsButton(item, notificationLink)}
              {this.renderReadButton(item)}
            </div>
          </div>
        );
    });

    return items;
  }

  /** Message body */
  renderMessage(item) {
    const {notificationTypeId, savedSearches=[]} = item;
    switch (notificationTypeId) {
      // Saved Searches
      case 1040:
        if (savedSearches.length) {
          return (
            <ul>
              {savedSearches.map(((ss, i) => (
                <li 
                  key={i}
                >
                  {this.renderSavedSearchMessage(ss)}
                </li>
              )))}
            </ul>
          );
        } else {
          // No Saved Search item(s)
          return (
            <span>
              {"You have updates in your "}
              <Link 
                to={`/savedsearches`} 
                onClick={(event) => {
                  this.markAsReadOrUnread(event, item);
                  sessionStorage.removeItem('navItems');
                }}
              >
                Saved Searches
              </Link>.
            </span>
          )
        }
      
      // All others
      default:
        return (
          <p>{stripHtml(item.message)}</p>
        )
    }
  }

  /** Message body -- Saved Search
   * Builds out Saved Search update with embedded link
   * @param {{countAdded: string, name: string, url: string}} searchUpdate 
   */
  renderSavedSearchMessage (searchUpdate={}) {
    const {url, name, countAdded} = searchUpdate;
    const plurality = [1, '1'].includes(countAdded) ? 'item' : 'items';
    return (
      <span className='notification-item__message-content'>
        <label style={{fontWeight: 'bold'}}>{`'${name}' `}</label>
        {" has "}
        {(<Link style={{fontStyle: 'italic'}} to={url} >{` ${countAdded} new ${plurality}`}</Link>)}.
      </span>
    )
  }

  /** 'Read' or "Unread' icon (green circle)" */
  renderStatusIcon (markAsRead) {
    return (
      <div
      className="notification-item__status"
    >
      <span
        className={`${markAsRead ? 'far' : 'fas'} fa-circle`}
        style={{ color: markAsRead ? 'rgb(92, 190, 127)' : 'rgb(92, 190, 127)' }}
      >
        {' '}
      </span>
    </div>
    )
  }

  /**
   * @param {object} item 
   * @param {boolean} markAsRead 
   */
  renderReadButton (item) {
    const markedAsReadClassName = item.markAsRead ? "" : ` notification-type-read`;
    const markedAsReadLabel = item.markAsRead ? "Mark As Unread" : "Mark As Read";
    return (
      <Link
        to="#"
        onClick={(event) => this.markAsReadOrUnread(event, item)}
        className={`notification-item__footer_markasread btn btn-primary${markedAsReadClassName}`}
        style={{ minWidth: '152.83px' }}
      >
        {markedAsReadLabel}
      </Link>
    )
  }

  renderDetailsButton (item, notificationLink) {
    const excludedIds = [1040];
    return (
      !excludedIds.includes(item.notificationTypeId)
        ? (<Link
            className="notification-item__footer_viewdetails btn btn-primary"
            to={notificationLink}
            onClick={(event) => {
              this.markAsReadOrUnread(event, item);
              sessionStorage.removeItem('navItems');
            }}
          >
            View Vehicle Details
          </Link>)
        : null
    )
  }

  render() {
    const title = getConfig('name')
      ? getConfig('name') + ' | Notifications'
      : 'Notifications';

    return (
      <DocumentTitle title={title}>
        <div>
          <div className="search-nav">
            <div className="container">
              <div className="search-title pull-left">
                <h4>Notifications</h4>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{ backgroundColor: '#f9f9f9', marginTop: 10, padding: 15 }}
          >
            <div id="notifications-header" >
              <p style={{ marginTop: 10 }}>
                These are notifications that you are subscribed to. You can update
                those setting by going to alerts in your{' '}
                <Link to="/profile">profile</Link>.
              </p>
              <p>
                To view your activity timeline go{' '}
                <Link to="/myactivity" state={{ routeName: 'My Activity' }}>
                  here
                </Link>
                .
              </p>
            </div>
            <hr />
            <div className="notifications-list__container">
              {this.createNotificationList()}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Notifications.propTypes = {
  loadNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { notifications, notificationRead } = state.entities;
  return { notifications, notificationRead };
};

export default connect(
  mapStateToProps,
  { loadNotifications, loadReadNotification, loadUnReadNotification }
)(Notifications);
