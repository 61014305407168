import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button } from 'semantic-ui-react';
import { filterLogicStartsWith, filterLogicIncludes } from '../../utils/helpers';
import { loadImpersonateList, loadImpersonateUser } from '../../actions';
import { getConfig } from '../../utils/helpers';
import TableTextFilter from '../tables/TableTextFilter';
class Impersonate extends Component {
  state = { loader: true, impersonateList: null };

  componentDidMount() {
    const mpId = getConfig('marketplaceId');
    this.props.loadImpersonateList(mpId).then(({ response }) => {
      const impersonateList =
        response && response.wsStatus === 'Success'
          ? response.impersonateUsers
          : [];
      this.setState({ loader: false, impersonateList });
    });
  }

  handleClick(email, isCopy = false) {
    const mpId = getConfig('marketplaceId');
    const [userId] = this.state.impersonateList
      .filter(user => user.email === email)
      .map(user => user.userId);

    this.props.loadImpersonateUser(mpId, userId).then(({ response }) => {
      if (response && response.wsStatus === 'Success') {
        const impersonateLink = response.impersonateUrl;
        if (isCopy) {
          navigator.clipboard.writeText(impersonateLink);
          toastr.success('Impersonate link copied');
        } else {
          window.location.href = impersonateLink;
        }
      } else {
        toastr.error('Error getting impersonate link');
      }
    });
  }

  render() {
    const columns = [
      {
        Header: 'First Name',
        accessor: 'firstName',
        filterable: true,
        sortable: true,
        multisort: false,
        disableFilters: true,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterable: true,
        sortable: true,
        multisort: false,
        disableFilters: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        sortable: true,
        multisort: false,
        disableFilters: true,
        filterMethod: filterLogicIncludes,
        Filter: props => (
          <TableTextFilter
            placeholder={''}
            filter={props.filter}
            onChange={props.onChange}
            updateCurrentData={this.updateCurrentData}
          />
        )
      },
      {
        Header: 'Actions',
        headerStyle: { background: 'none' },
        id: 'impersonate-button',
        sortable: false,
        filterable: false,
        Cell: row => (
          <div
            className='profile-tab__impersonate_td-actions'
          >
            <Button
              style={{
                backgroundColor: 'rgb(49, 131, 122)',
                color: 'rgb(255, 255, 255)',
                border: 'none',
                borderRadius: '3px',
                outline: 'none',
                padding: '5px',
                fontWeight: '500',
                height: 35,
                fontSize: 14,
              }}
              onClick={() => this.handleClick(row.original.email)}
            >
              Impersonate
            </Button>
            <Button
              style={{
                backgroundColor: 'rgb(49, 131, 122)',
                color: 'rgb(255, 255, 255)',
                border: 'none',
                borderRadius: '3px',
                outline: 'none',
                padding: '5px',
                fontWeight: '500',
                height: 35,
                fontSize: 14,
              }}
              onClick={() => this.handleClick(row.original.email, true)}
            >
              Copy Link
            </Button>
          </div>
        ),
      },
    ];

    return (
      !this.state.loader && (
        <ReactTable
          data={this.state.impersonateList}
          columns={columns}
          defaultPageSize={10}
          resizable={false}
          defaultFilterMethod={filterLogicStartsWith}
        />
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userProfile } = state.entities;
  return { userProfile };
};

export default connect(
  mapStateToProps,
  { loadImpersonateList, loadImpersonateUser }
)(Impersonate);
