import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import DocumentTitle from 'react-document-title';
import BuyerArbs from './buyerActivity/BuyerArbs';
import BuyerBids from './buyerActivity/BuyerBids';
import BuyerIfs from './buyerActivity/BuyerIfs';
import BuyerOffers from './buyerActivity/BuyerOffers';
import BuyerPurchased from './buyerActivity/BuyerPurchased';
import BuyerTitles from './buyerActivity/BuyerTitles';
import BuyerTransportation from './buyerActivity/BuyerTransportation';
import StatusNav from '../components/StatusNav';
import { getConfig, getConfigs } from '../utils/helpers';

class Status extends Component {
  state = {
    tab: null,
    tabs: [],
  };

  componentDidMount() {
    this.loadTabs();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.marketplaceFeatures.features !==
      prevProps.marketplaceFeatures.features
    ) {
      this.loadTabs();
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadTab();
    }
  }

  loadTabs = () => {
    // TODO: - activity tabs should be an AWG config
    const { marketplaceFeatures, userProfile, location } = this.props;
    const mpFeatures = (get(marketplaceFeatures, 'features') || '').split(','); // called by TopNav.js, no need to call it here
    const userFeatures = get(userProfile, 'user.userFeatures') || [];

    const [
      disableBuyerActivity,
      enableOffersTab,
      enableIfSales,
      enableArbitration,
      enableBuyerTitles,
      enableBuyerTransportationTab,
    ] = getConfigs(
      'disableBuyerActivity',
      'enableOffersTab',
      'enableIfSales',
      'enableArbitration',
      'enableBuyerTitles',
      'enableBuyerTransportationTab'
    );

    const tabs = [];

    if (!disableBuyerActivity) {
      tabs.push({
        name: 'Bids',
        link: '/bids',
        section: 'bids',
      });

      if (enableOffersTab) {
        tabs.push({
          name: 'Offers',
          link: '/offers',
          section: 'offers',
        });
      }

      if (enableIfSales) {
        tabs.push({
          name: 'If’s / Reserved',
          link: '/ifs',
          section: 'ifs',
        });
      }

      tabs.push({
        name: 'Purchased',
        link: '/purchased',
        section: 'purchased',
      });

      if (enableArbitration) {
        tabs.push({
          name: 'Arbitration',
          link: '/arbs',
          section: 'arbs',
        });
      }

      if (enableBuyerTitles) {
        tabs.push({
          name: 'Titles',
          link: '/titles',
          section: 'titles',
        });
      }
      // TODO - 2024-03-19: section not functional, no customer with this enabled.
      if (enableBuyerTransportationTab) {
        tabs.push({
          name: 'Transportation',
          link: '/transportation',
          section: 'transportation',
        });
      }
    }

    const tab =
      find(tabs, {
        section: (location.pathname || '').split('/').reverse()[0],
      }) || tabs[0];

    this.setState({ tabs, tab });
  };

  loadTab() {
    const tab = find(this.state.tabs, {
      section: (this.props.location.pathname || '').split('/').reverse()[0],
    });

    if (tab) this.setState({ tab });
  }

  handleTabSelect = (e, tab) => {
    if (e) e.preventDefault();
    this.setState({ tab });
    this.props.navigate('/status/' + tab.section);
  };

  renderSection(section) {
    switch (section) {
      case 'bids':
        return <BuyerBids />;
      case 'offers':
        return <BuyerOffers />;
      case 'ifs':
        return <BuyerIfs />;
      case 'purchased':
        return <BuyerPurchased />;
      case 'titles':
        return <BuyerTitles />;
      case 'arbs':
        return <BuyerArbs />;
      case 'transportation':
        return <BuyerTransportation />;
      default:
        return null;
    }
  }

  render() {
    const section = this.state.tab ? this.state.tab.section : 'bids';
    const title = getConfig('name')
      ? getConfig('name') + ' | ' + section
      : section;

    return (
      <DocumentTitle title={title}>
        <div>
          <StatusNav
            tabs={this.state.tabs}
            tab={this.state.tab}
            handleTabSelect={this.handleTabSelect}
          />
          <div className="container-fluid">{this.renderSection(section)}</div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => {
  const { marketplaceFeatures, userProfile } = state.entities;
  return { marketplaceFeatures, userProfile };
};

export default connect(mapStateToProps, {})(Status);
