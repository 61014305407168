import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import ListVehicleModal from './ListVehicleModal';
import UpdatePriceModal from '../UpdatePriceModal';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadSellerInventory,
  resetSellerInventory,
  loadRemoveItem,
} from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';
import 'bootstrap';
import CoreButton from '../core/components/ui/buttons/CoreButton';
import TableError from '../tables/TableError';
class SellerUnlisted extends Component {
  state = {
    title: 'seller unlisted',
    item: null,
    coreEnabled: undefined,
    isListModalOpen: false,
    isUpdatePriceModalOpen: false,
    isErrorState: false,
  };

  componentDidCatch(exception) {
    console.error(exception);
    this.setState({isErrorState: true})
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const { features } = this.props.marketplaceFeatures;
    if (features && this.state.coreEnabled === undefined) {
      const coreEnabled = features.includes('555');
      this.setState({ coreEnabled });
    }
  }

  loadData = () => {
    this.props.resetSellerInventory();
    this.props.loadSellerInventory(getConfig('marketplaceId'));
  };

  handleListClick = item => {
    this.setState({ item, isListModalOpen: true });
  };

  handleUpdateClick = item => {
    this.setState({ item, isUpdatePriceModalOpen: true });
  };

  handleRemoveClick = item => {
    confirm(
      'Are you sure you want to remove the item from the Marketplace?'
    ).then(
      () => {
        this.props
          .loadRemoveItem(getConfig('marketplaceId'), item.itemId)
          .then(({ response }) => {
            if (response) {
              if (response.wsStatus === 'Error') {
                toastr.error('Error', response.wsMessage);
              } else if (response.wsStatus === 'Success') {
                toastr.success('Success', response.wsMessage);
                this.loadData();
              }
            }
          });
      },
      () => null
    );
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  makeActionButtons = (data) => {
    return {
      Header: 'Actions',
      headerStyle: { background: 'none' },
      id: 'buttons',
      sortable: false,
      filterable: false,
      Cell: row => (
        <div style={styles.buttonGroup}>
          <Button
            style={styles.button}
            onClick={() => this.handleDetailsClick(row.original.itemId, data)}
          >
            View Details
          </Button>
          {this.state.coreEnabled ? (
            <CoreButton
              sellerActivityList="Unlisted"
              label="Edit"
              existingVin={row.original.vin}
              style={styles.button}
            />
          ) : (
            <Button
              style={styles.button}
              onClick={() => this.handleUpdateClick(row.original)}
            >
              Update
            </Button>
          )}
          <Button
            style={styles.button}
            onClick={() => this.handleListClick(row.original)}
          >
            List
          </Button>
          <Button
            style={styles.button}
            onClick={() => this.handleRemoveClick(row.original)}
          >
            Remove
          </Button>
        </div>
      ),
    }
  }

  render() {
    const loading = isEmpty(this.props.sellerInventory);
    const data = (this.props.sellerInventory.data || []).filter(
      item => item.listingStatusId === 8
    );
    const lockedColumn = this.state.coreEnabled ? 'locked' : null;
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      this.makeActionButtons(data),
      'vin',
      'year',
      'make',
      'model',
      'mileage',
      'seller',
      'reservePrice',
      'buyNow',
      'newOrUsed',
      'retailOrWholesale',
      'daysInMarketplace',
      'dateAdded',
      lockedColumn,
    ]);

    return (
    this.state.isErrorState
      ? (<TableError errorMessage="An error occurred while attempting to build out the data table.  Please contact Support." />)
      : (
      <div className="st-container shadow padding background-white padding">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>Unlisted Vehicles</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {this.state.coreEnabled && (
              <CoreButton
                style={{ minHeight: 32 }}
                sellerActivityList="Unlisted"
                label="Add New +"
              />
            )}
          </div>
        </div>

        <div>
          <UpdatePriceModal
            isOpen={this.state.isUpdatePriceModalOpen}
            onClose={() =>
              this.setState({ isUpdatePriceModalOpen: false, item: null })
            }
            item={this.state.item}
            loadData={this.loadData}
            navigate={this.props.navigate}
          />
          <ListVehicleModal
            isOpen={this.state.isListModalOpen}
            onClose={() =>
              this.setState({ isListModalOpen: false, item: null })
            }
            item={this.state.item}
            loadData={this.loadData}
            navigate={this.props.navigate}
          />
          <DataTable
            loading={loading}
            title={this.state.title}
            data={data}
            columns={columns}
            showCSVButton={true}
            showPrintButton={true}
          />
        </div>
      </div>)
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sellerInventory, marketplaceFeatures } = state.entities;
  return { sellerInventory, marketplaceFeatures };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadSellerInventory,
    resetSellerInventory,
    loadRemoveItem,
  })
)(SellerUnlisted);
