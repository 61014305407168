import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { find, isEmpty } from 'lodash';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import Accounts from './Accounts';
import Alerts from './Alerts';
import Impersonate from './Impersonate';
import IASConnectModal from '../IASConnectModal';
import DeviceTime from '../common/DeviceTimeComponent';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadUpdateUserProfile,
  loadARIAddressValidation,
  loadMarketplaces,
  loadCarfaxLoginCheck,
  resetCarfaxCode,
} from '../../actions';
import { formatPhoneNumber, getConfig } from '../../utils/helpers';
import { mp3rdPartyIntegratorButtonsMap } from '../../utils/constants';
import carfaxImg from '../../assets/images/carfax.svg';
import { ProfileInformation } from './ProfileInformation';

const mp3rdPartyIntegratorButtonsFallback = '2,3';

class Profile extends Component {
  state = {
    tabTitle: '',
    tabAlerts: '',
    tabAccounts: '',
    tabProfile: '',
    tabThirdParty: '',
    tabImpersonate: '',

    hasChanged: false,
    mp3rdParties: null,
    iasConnectModalOpen: false,
    iasConnectUrl: '',

    // also the profile fields will be populated here
    // UPDATE (2025.03) - profile fields moved to ProfileInformation component. 
    //                    Keeping initial userProfile load in case data is needed
    //                    at parent level.
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadTabs();
    this.loadsProfileData();
    this.loadThirdPartyData();
    if (this.props.carfaxCode) {
      this.handleCarfaxLoginReturn(); // when returning from carfax
      this.props.resetCarfaxCode();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.loadsProfileData();
    }

    if (this.props.marketplaces && !this.state.mp3rdParties) {
      this.loadThirdPartyData();
    }

    if (
      (!prevState.tabThirdParty && this.state.tabThirdParty) ||
      (!isEmpty(this.props.carfaxLoginCheck) &&
        this.props.carfaxLoginCheck.isLoggedIn !==
          prevProps.carfaxLoginCheck.isLoggedIn)
    ) {
      this.props.loadCarfaxLoginCheck(getConfig('marketplaceId'));
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadTabs();
    }
  }

  loadTabs() {
    const tabId = (this.props.location.pathname || '').split('/').reverse()[0];
    let tabTitle = '';
    let tabProfile = '';
    let tabAccounts = '';
    let tabAlerts = '';
    let tabThirdParty = '';
    let tabImpersonate = '';
    switch (tabId) {
      case 'profile': {
        tabTitle = 'Profile';
        tabProfile = 'active';
        break;
      }
      case 'accounts': {
        tabTitle = 'Accounts';
        tabAccounts = 'active';
        break;
      }
      case 'alerts': {
        tabTitle = 'Alerts';
        tabAlerts = 'active';
        break;
      }
      case 'thirdparty': {
        tabTitle = 'Third Party';
        tabThirdParty = 'active';
        break;
      }
      case 'impersonate': {
        tabTitle = 'Impersonate';
        tabImpersonate = 'active';
        break;
      }
      default: {
        tabTitle = 'Profile';
        tabProfile = 'active';
      }
    }

    this.setState({
      tabTitle,
      tabProfile,
      tabAccounts,
      tabAlerts,
      tabThirdParty,
      tabImpersonate,
    });
  }

  loadsProfileData() {
    const { user } = this.props.userProfile;
    if (!user) return null;

    this.setState({
      editMode: false,
      hasChanged: false,
      validating: false,
    });
  }

  loadThirdPartyData = () => {
    const mpId = getConfig('marketplaceId');

    if (this.props.marketplaces.marketplaceList) {
      const marketplace = find(
        this.props.marketplaces.marketplaceList,
        mp => mp.marketplaceId == mpId
      );

      if (marketplace) {
        const mp3rdParties = (
          marketplace.mp3rdPartyIntegratorButtons &&
          marketplace.mp3rdPartyIntegratorButtons !== '0'
            ? marketplace.mp3rdPartyIntegratorButtons
            : mp3rdPartyIntegratorButtonsFallback
        )
          .split(',')
          .map(key => {
            const val = mp3rdPartyIntegratorButtonsMap.get(key.trim());
            if (!val && key !== '0') {
              console.error(
                `mp3rdPartyIntegratorButtons does not contain key ${key}`
              );
            }
            return val;
          });

        this.setState({
          mp3rdParties,
        });
      }
    }
  };

  handleCarfaxClick = () => {
    const { isLoggedIn, loginUrl } = this.props.carfaxLoginCheck;

    if (isLoggedIn) {
      this.handleCarfaxLogout();
    } else {
      this.handleCarfaxLogin(loginUrl);
    }
  };

  handleCarfaxLogin = loginUrl => {
    const carfaxRedirectPath = window.location.pathname;
    const carfaxLoginUrl = loginUrl + window.location.origin;
    localStorage.setItem('carfaxRedirectPath', carfaxRedirectPath);
    window.open(carfaxLoginUrl, '_self');
  };

  handleCarfaxLogout = () => {
    const apiRoot = getConfig('apiRoot');
    const apiKey = this.props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');

    const url =
      `${apiRoot}carfax/v2/logout` +
      `?apiKey=${apiKey}` +
      `&mpId=${mpId}` +
      `&deviceId=54321`;

    axios({
      url,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 204) {
          window.open(`https://auth.carfax.com/v2/logout`, '_blank');
          this.props.loadCarfaxLoginCheck(mpId);
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'logout'));
  };

  handleCarfaxLoginReturn = () => {
    const apiRoot = getConfig('apiRoot');
    const apiKey = this.props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');
    const { carfaxCode } = this.props;
    const redirectUri = window.location.origin;

    const url =
      `${apiRoot}carfax/v2/login` +
      `?apiKey=${apiKey}` +
      `&mpId=${mpId}` +
      `&code=${carfaxCode}` +
      `&redirect_uri=${redirectUri}` +
      `&deviceId=54321`;

    axios({
      url,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // no response on success
        if (response.status === 204) {
          this.props.loadCarfaxLoginCheck(mpId);
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'login'));
  };

  handleCarfaxError = (err, message) => {
    // the errs from awg are not very helpful
    toastr.error(
      `There was a problem with CARFAX \n ${message && '(' + message + ')'}`
    );
    console.error({ err, message });
  };

  renderSubheader(user) {
    return (
      <div className="col-xs-12 no-padding">
        <div
          className="list-group profile-details"
          style={{ cursor: 'default' }}
        >
          <div className="list-group-item clearfix">
            <span className="list-title">Member Since</span>
            <span className="list-content pull-right">
              <DeviceTime sourceTime={user.createdOn} format="MMM DD, YYYY" />
            </span>
          </div>
          <div className="list-group-item clearfix">
            <span className="list-title">Username / Email</span>
            <span className="list-content pull-right">{user.username}</span>
          </div>

          <div className="list-group-item clearfix">
            <span className="list-title">Mobile</span>
            <span className="list-content pull-right">{user.mobile}</span>
          </div>
        </div>
      </div>
    );
  }

  renderTabs() {
    const handleTabClick = () => {
      if (this.state.hasChanged)
        confirm('Please save or cancel your changes', { hideCancel: true });
    };
    const linkStyle = {
      pointerEvents: this.state.hasChanged ? 'none' : 'auto',
    };

    // NOTE - CARFAX is the only thing we have content for atm, but this could change.
    const showThirdPartyTab =
      this.state.mp3rdParties && this.state.mp3rdParties.includes('CARFAX');

    const features = this.props.userProfile.user.userFeatures || [];

    return (
      <ul
        id="profileTabs"
        className="nav nav-tabs profile-tabs"
        data-tabs="tabs"
      >
        <li className={this.state.tabProfile} onClick={handleTabClick}>
          <Link to="/profile" style={linkStyle}>
            Profile Information
          </Link>
        </li>
        <li className={this.state.tabAccounts} onClick={handleTabClick}>
          <Link to="/profile/accounts" style={linkStyle}>
            My Accounts
          </Link>
        </li>
        <li className={this.state.tabAlerts} onClick={handleTabClick}>
          <Link to="/profile/alerts" style={linkStyle}>
            My Alerts
          </Link>
        </li>
        {showThirdPartyTab && (
          <li className={this.state.tabThirdParty} onClick={handleTabClick}>
            <Link to="/profile/thirdparty" style={linkStyle}>
              Third Party
            </Link>
          </li>
        )}
        {features.includes('546') && (
          <li className={this.state.tabImpersonate} onClick={handleTabClick}>
            <Link to="/profile/impersonate" style={linkStyle}>
              Impersonate
            </Link>
          </li>
        )}
      </ul>
    );
  }

  renderProfile() {
    return (
      <ProfileInformation 
        tabProfile={this.state.tabProfile} 
        tabTitle={this.state.tabTitle} 
        onHasChanged={(hasChanged)=>this.setState({hasChanged})}
      />
    )
  }

  renderAccounts() {
    return (
      <div className={'tab-pane ' + this.state.tabAccounts} id="myAccounts">
        {/* <h3>My Accounts</h3> */}
        <Accounts accountList={this.props.userProfile.user.accountList} />
      </div>
    );
  }

  renderAlerts() {
    return (
      <div className={'tab-pane ' + this.state.tabAlerts} id="myAlerts">
        <Alerts />
      </div>
    );
  }

  renderThirdParty(user) {
    const { mp3rdParties } = this.state;

    if (mp3rdParties) {
      const content = mp3rdParties.map(item => {
        switch (item) {
          case 'CARFAX': {
            const linkStyle = {
              textDecoration: 'underline',
              cursor: 'pointer',
              marginLeft: 4,
              marginRight: 4,
            };

            const carfaxContent = (
              <div key={item}>
                <img
                  alt="carfax"
                  src={carfaxImg}
                  style={{
                    height: 40,
                    cursor: 'pointer',
                    margin: 5,
                    marginRight: 15,
                    border: 'none',
                  }}
                  onClick={this.handleCarfaxClick}
                />
                {this.props.carfaxLoginCheck.isLoggedIn ? (
                  <span>
                    You are logged in to CAFAX. Click
                    <span style={linkStyle} onClick={this.handleCarfaxClick}>
                      here
                    </span>
                    to logout.
                  </span>
                ) : (
                  <span>
                    Click
                    <span style={linkStyle} onClick={this.handleCarfaxClick}>
                      here
                    </span>
                    to login.
                  </span>
                )}
              </div>
            );

            return carfaxContent;
          }

          default:
            return null;
        }
      });

      return (
        <div
          className={'tab-pane ' + this.state.tabThirdParty}
          id="myThirdParty"
          style={{ minHeight: 200 }}
        >
          {content}
        </div>
      );
    }
  }

  renderImpersonate() {
    return (
      <div className={'tab-pane ' + this.state.tabImpersonate} id="impersonate">
        <h3>Impersonate a user</h3>
        <Impersonate />
      </div>
    );
  }

  render() {
    const { user } = this.props.userProfile;
    const { marketplaceList } = this.props.marketplaces;
    if (!user || !marketplaceList) return null;

    return (
      <DocumentTitle title={this.state.tabTitle}>
        <div
          className="container profile-container"
          // /* moved to 'assets/css/style.css' */
          // style={{
          //   backgroundColor: '#f9f9f9',
          //   marginTop: 20,
          //   padding: 20,
          //   borderRadius: 4,
          // }}
        >
          <IASConnectModal
            isOpen={this.state.iasConnectModalOpen}
            onClose={() => this.setState({ iasConnectModalOpen: false })}
            url={this.state.iasConnectUrl}
          />
          <h2>Profile</h2>
          {this.renderSubheader(user)}
          <div className="col-xs-12 no-padding">
            {this.renderTabs(user)}
            <div
              id="profileTabContent"
              className="tab-content profile-tab-content clearfix"
            >
              {this.renderProfile(user)}
              {this.renderAccounts(user)}
              {this.renderAlerts(user)}
              {this.renderThirdParty(user)}
              {this.renderImpersonate(user)}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    userProfile,
    marketplaces,
    carfaxLoginCheck,
    carfaxCode,
    marketplaceFeatures,
  } = state.entities;
  return {
    userProfile,
    marketplaces,
    carfaxLoginCheck,
    carfaxCode,
    marketplaceFeatures,
  };
};

export default connect(mapStateToProps, {
  loadUpdateUserProfile,
  loadARIAddressValidation,
  loadMarketplaces,
  loadCarfaxLoginCheck,
  resetCarfaxCode,
})(Profile);
