export const ifSalePurchaseDisclaimer = {
    HEADING: "You are reserving this vehicle for purchase upon grounding.",
    DISCLAIMER: 
        "Seller will confirm availability, mileage and condition. Once confirmed, this item will move to in-process as a new sale. " +  
        "For more information or questions: open a chat, see the event details, or view the terms and conditions.",
    DISCLAIMER_BUY: 
      "This item is listed as an 'If-Sale' purchase. " + 
      "A successful purchase will be moved to 'If' status. " + 
      "These If's are handled in the order received and may take up to 48-hours before action is taken by the seller.",
    DISCLAIMER_OFFER: 
      "This item is listed as an 'If-Sale' purchase. " + 
      "If your offer is accepted, the purchase will be moved to 'If' status. " + 
      "These If's are handled in the order received and may take up to 48-hours before action is taken by the seller.",
    DISCLAIMER_BID: 
      "This item is listed as an 'If-Sale' purchase. " + 
      "If you have the winning bid, the purchase will be moved to 'If' status. " + 
      "These If's are handled in the order received and may take up to 48-hours before action is taken by the seller.",
    DISCLAIMER_ACKNOWLEDGE:
      "I acknowledge the above disclaimer and that this is not a guarantee of purchase."
  }