import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { cloneDeep } from 'lodash';
import TableError from './TableError';

export default class DataTablePrintable extends Component {
  state = {
    printableColumns: [],
    isErrorState: false,
  };

  componentDidMount() {
    this.setState({
      printableColumns: this.makePrintableColumns(),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.columns !== prevProps.columns) {
      this.setState({
        printableColumns: this.makePrintableColumns(),
      });
    }
  }

  componentDidCatch(dataTablePrintableErr) {
    console.error(dataTablePrintableErr?.message || dataTablePrintableErr);
    this.setState({isErrorState: true})
  }

  makePrintableColumns() {
    /* Note: 'Invocation error' occurs in 'cloneDeep' call when an HTML or HTML-like item is present */
    const excludeFromPrint = ['itemId', 'checkbox', 'buttons', 'details'];
    const {columns} = this.props;
    // UPDATE (2025.01): Converted clone & width adj to css styling
    const printColumns = columns
      .filter(column => !excludeFromPrint.includes(column.id))
      .map(column => {
        return column;
      });
    return printColumns;
  }

  render() {
    return (
      this.state.isErrorState
      ? (<TableError errorMessage='Error building out printable data.' />)
      : (<ReactTable
        className="-striped -highlight datatable-printable"
        data={this.props.dataForButtonActions}
        columns={this.state.printableColumns}
        sortable={false}
        multiSort={false}
        filterable={false}
        resizable={false}
        defaultPageSize={this.props.dataForButtonActions.length}
        getTableProps={() => ({ style: styles.tableStyle })}
        getTheadThProps={() => ({ style: styles.headerCellStyle })}
        getTdProps={() => ({ style: styles.cellStyle })}
        showPagination={false}
      />)
    );
  }
}

DataTablePrintable.propTypes = {
  data: PropTypes.array,
  dataForButtonActions: PropTypes.array,
};

const styles = {
  tableStyle: {
    border: '1px solid gray',
    overflow: 'hidden',
  },
  headerCellStyle: {
    maxHeight: 80,
    minHeight: 50,
    fontWeight: 'bold',
    fontSize: `0.85em`,
    textAlign: 'left',
    padding: 5,
    outline: 'none',
    borderBottom: '1px solid gray',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipses',
    whiteSpace: 'normal',
    wordBreak: 'normal',
  },
  cellStyle: {
    maxHeight: 80,
    textAlign: 'left',
    fontSize: `0.7em`,
    paddingLeft: 5,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipses',
    whiteSpace: 'normal',
    wordBreak: 'normal',
  },
};
