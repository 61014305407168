import React, { Component } from 'react';
import { connect } from 'react-redux';
import { forceCheck } from 'react-lazyload';
import {
  ActionBar,
  ActionBarRow,
  BoolMust,
  CheckboxFilter,
  HitsStats,
  InitialLoader,
  Layout,
  LayoutBody,
  LayoutResults,
  MenuFilter,
  NoHits,
  Pagination,
  Panel,
  RangeFilter,
  RangeQuery,
  RangeSliderInput,
  RefinementListFilter,
  ResetFilters,
  SearchBox,
  SearchkitManager,
  SearchkitProvider,
  Select as SearchKitSelect,
  SelectedFilters,
  SideBar,
  SortingSelector,
  TermQuery,
  ViewSwitcherHits,
  ViewSwitcherToggle,
} from 'searchkit';
import Geosuggest from 'react-geosuggest';
import WishlistButton from './wishlist/WishlistButton';
import PrintFrame from './common/PrintFrame';
import Select from 'react-select';
import { find, findIndex, get, uniqBy, isEmpty, uniq } from 'lodash';
import moment from 'moment-timezone';
import $ from 'jquery';
import {
  loadAggregationsES,
  loadAttributes,
  loadCategories,
  loadMarketplaces,
  loadUserSelectedDistance,
  loadUserSelectedLocation,
} from '../actions';
import {
  commafy,
  commafyCurrency,
  makeDynamicCategoriesList,
  findStateAbbreviation,
  getConfig,
  isJSON,
  makeSearchTree,
  findDistanceUnits,
} from '../utils/helpers';
import { mpSortOptionsMap, mpSearchColumnsMap } from '../utils/constants';
import SelectedFilterCustom from './search/SelectedFilterCustom';
import HitStatsCustom from './search/HitStatsCustom';
import NoHitsDisplayCustom from './search/NoHitsDisplayCustom';
import ResetFiltersDisplayCustom from './search/ResetFiltersDisplayCustom';
import HierarchicalMenuFilterCustom from './search/HierarchicalMenuFilterCustom';
import GridItem from './search/GridItem';
import ListItem from './search/ListItem';
// import MapSearch from './search/MapSearch';
import '../assets/css/searchkit-custom.css';
import '../assets/css/geoSuggest.css';
import { RangeSliderInputCustom } from './search/RangeSliderInputCustom';

window.$ = window.jQuery = $;
const mpSortColumnsFallback = '1';
const mpSearchColumnsFallback =
  '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22';
const enableDynamicAttributes = getConfig('enableDynamicAttributes');
// const mapConfig = getConfig('mapConfig');
const searchRoot = getConfig('searchRoot');
const dropdownStyle = {
  control: base => ({
    ...base,
    border: '1px solid #E8E8E8',
    boxShadow: 0,
    '&:hover': {
      border: '1px solid #E8E8E8',
    },
    fontSize: '16px',
  }),
  singleValue: base => ({
    ...base,
    color: '#787878',
  }),
  option: base => ({
    ...base,
    cursor: 'pointer',
    color: '#787878',
    borderBottom: '1px solid #EAEAEA',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    fontSize: '16px',
  }),
};

class Search extends Component {
  constructor(props) {
    super(props);
    const isMobileDevice = window.matchMedia('(max-width: 650px)').matches;
    // * TEMP * - Disabling Darwin CTA in SRP
    // const stratosConfig = getConfig('stratosConfig');
    const showVehicleValuesButton =false;
    // const showVehicleValuesButton = !!(stratosConfig && stratosConfig.dealerGuid && stratosConfig.scriptSrc);
    this.state = {
      isMobileDevice,
      mapOn: false,
      markers: [],
      userSelectedDistance: props.userSelectedDistance || '100000',
      dropdownSelectedDistance: undefined,
      listingStatusIds: props.listingStatusIds,
      search: '',

      staticAggregations: undefined,
      staticFilterList: undefined,
      staticSortOptions: undefined,

      dynamicAggregations: undefined,
      dynamicFilterList: undefined,
      dynamicSortOptions: undefined,
      aggregationTree: undefined,
      dynamicCategoriesList: undefined,

      defaultSortOption: undefined,
      showSearchPanel:
        localStorage.getItem('showSearchPanel') === '0' ? false : true,
      /* Default to 'list' view if on Mobile device */
      defaultView: isMobileDevice
        ? 'list'
        : localStorage.getItem('defaultView'),
      page: undefined,
      showVehicleValuesButton,
    };

    this.searchkit = new SearchkitManager(searchRoot);
  }

  componentDidMount() {
    this.init();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    this.loadLocation(prevProps);
    this.handleSortChange(prevState);
    this.handleView();
    this.checkForReconnect(prevProps);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener('resize', this.handleResize)
  }

  init() {
    const mpId = getConfig('marketplaceId');

    this.props
      .loadMarketplaces(mpId)
      .then(this.loadMarketplaceSettings)
      .then(parentMarketplace => {
        this.addSearchKitListeners();
        this.addSearchKitDefaultQuery();
        this.loadLocation();
        if (enableDynamicAttributes) {
          this.loadDynamicData();
        } else {
          this.loadStaticAggregations();
          this.loadStaticFilters(parentMarketplace);
          this.loadStaticSort(parentMarketplace);
        }
      })
      .catch(error => console.error(error));
  }

  checkForReconnect(prevProps) {
    if (
      (prevProps.onlineConnected === false &&
        this.props.onlineConnected === true) ||
      (prevProps.realTimeConnected === false &&
        this.props.realTimeConnected === true)
    ) {
      this.searchkit.reloadSearch();
    }
  }

  addSearchKitListeners() {
    this.searchkit.addResultsListener(results => {
      this.loadScrollPosition();
      this.handleBreadcrumbs();
      this.handleNavItems(results);
      this.loadStaticAggregations();
      // this.handleMapping(results);
    });
  }

  addSearchKitDefaultQuery() {
    // addDefaultQuery
    this.searchkit.addDefaultQuery(query => {
      const location = this.props.userSelectedLocation.location || {};
      const latitude = Number(location.lat);
      const longitude = Number(location.lng);
      const distanceUnits = findDistanceUnits(this.props.marketplaceFeatures);

      const queryObj = {
        bool: {
          must: [{ terms: { listingStatusId: this.state.listingStatusIds } }],
          must_not: [
            { terms: { eventId: this.props.unauthorizedEventIds || [] } },
            { range: { parentListingId: { gt: 0 } } }, // don't show bulk-lot children
          ],
          filter: [],
        },
      };

      if (this.isSortedByEndingSoonest()) {
        // this filters vehicles which have ended
        queryObj.bool.filter.push({
          range: {
            epochEndTime: {
              gte: moment().valueOf() - 28800000, // now minus 8 hours (diff btwn UTC and server time)lt: null,
            },
          },
        });
      }

      if (
        latitude &&
        longitude &&
        this.state.userSelectedDistance &&
        distanceUnits
      ) {
        // this filters by distance
        queryObj.bool.filter.push({
          geo_distance: {
            distance: `${this.state.userSelectedDistance}${distanceUnits}`,
            coordinates: {
              lat: latitude,
              lon: longitude,
            },
          },
        });
      }

      return query.addQuery(queryObj);
    });

    // setQueryProcessor
    this.searchkit.setQueryProcessor(plainQueryObject => {
      plainQueryObject.track_total_hits = true;

      const location = this.props.userSelectedLocation.location || {};
      const latitude = Number(location.lat);
      const longitude = Number(location.lng);

      // this adds distance to the hits
      const distanceUnits = findDistanceUnits(this.props.marketplaceFeatures);
      if (latitude && longitude && distanceUnits) {
        plainQueryObject.script_fields = {
          distance: {
            script: {
              source:
                "doc['coordinates'].planeDistance(params.lat,params.lon) * params.multiplier",
              params: {
                lat: latitude,
                lon: longitude,
                multiplier: distanceUnits === 'km' ? 0.001 : 0.00062137,
              },
            },
          },
        };
      }

      return plainQueryObject;
    });
  }

  handleDistanceSliderFinish = (newValue) => {
    const userSelectedDistance = typeof newValue === 'number' 
      ? String(newValue)
      : newValue;
    this.setState({ userSelectedDistance }, () => {
      this.searchkit.reloadSearch();
      this.props.loadUserSelectedDistance(
        userSelectedDistance
      );
    })
  }

  loadMarketplaceSettings = ({ response }) => {
    const mpId = getConfig('marketplaceId');
    if (response.wsStatus === 'Success') {
      const marketplace = find(
        response.marketplaceList,
        mp => mp.marketplaceId == mpId
      );

      if (marketplace) {
        return marketplace;
      }
    }
  };

  loadStaticAggregations() {
    const filters = get(this.searchkit, 'query.index.filters') || [];
    const eventNames = filters
      .filter(filter => filter.term && filter.term['eventName.raw'])
      .map(filter => filter.term['eventName.raw']);

    const body = {
      aggs: {
        all_inventory: {
          filter: {
            terms: { listingStatusId: [1, 2, 3, 4, 5, 6, 7, 8] },
          },
          aggs: {
            listingStatusId: { terms: { field: 'listingStatusId' } },
          },
        },

        available_inventory: {
          filter: {
            bool: {
              must: [
                { terms: { listingStatusId: this.state.listingStatusIds } },
              ],
              must_not: [
                { terms: { eventId: this.props.unauthorizedEventIds } },
              ],
            },
          },
          aggs: {},
        },
      },

      query: {
        bool: {
          filter: [],
        },
      },
    };

    if (eventNames && eventNames.length) {
      body.aggs.available_inventory.filter.bool.must.push({
        terms: { 'eventName.raw': eventNames },
      });
    }

    if (this.isSortedByEndingSoonest()) {
      body.query.bool.filter.push({
        range: { epochEndTime: { gte: moment().valueOf() - 28800000 } },
      });
    }

    const fields = ['year', 'mileage', 'outrightPrice', 'feedPrice', 'imageCount'];
    fields.forEach(field => {
      body.aggs.available_inventory.aggs[field] = { stats: { field } };
    });

    this.props.loadAggregationsES(searchRoot, body).then(({ response }) => {
      const staticAggregations = response ? response.aggregations : {};
      this.setState({ staticAggregations });
    });
  }

  loadStaticFilters(marketplace) {
    const staticFilterList = (
      marketplace.mpSearchColumns && marketplace.mpSearchColumns !== '0'
        ? marketplace.mpSearchColumns
        : mpSearchColumnsFallback
    )
      .split(',')
      .map(key => {
        const val = { ...mpSearchColumnsMap.get(key.trim()) }; // don't mutate the map item!
        if (!val && key !== '0') {
          console.error(`mpSearchColumns does not contain key ${key}`);
        }
        return val;
      })
      .filter(filter => filter);

    this.setState({ staticFilterList });
  }

  loadStaticSort(marketplace) {
    const staticSortOptions = (
      marketplace.mpSortColumns && marketplace.mpSortColumns !== '0'
        ? marketplace.mpSortColumns
        : mpSortColumnsFallback
    )
      .split(',')
      .map(key => {
        const val = { ...mpSortOptionsMap.get(key.trim()) }; // don't mutate the map item!
        if (!val && key !== '0') {
          console.error(`mpSortColumns does not contain key ${key}`);
        }
        return val;
      });

    // check for stored sort option and check if valid
    // if valid set as default, else set the first option as default
    const storedDefaultSortOption = localStorage.getItem('defaultSortOption');
    const storedDefaultSortOptionIndex = findIndex(staticSortOptions, {
      field: (storedDefaultSortOption || '_').split('_')[0],
      order: (storedDefaultSortOption || '_').split('_')[1],
    });

    let defaultSortOption = undefined;

    if (storedDefaultSortOptionIndex > -1) {
      defaultSortOption = storedDefaultSortOption;
      staticSortOptions[storedDefaultSortOptionIndex].defaultOption = true;
    }

    this.setState({
      defaultSortOption,
      staticSortOptions,
    });
  }

  loadDynamicData = async () => {
    const mpId = getConfig('marketplaceId');
    const categories = await this.props
      .loadCategories(mpId)
      .then(({ response }) => (response ? response.categories : []));
    const attributes = await this.props
      .loadAttributes(mpId)
      .then(({ response }) => (response ? response.attributes : []));

    if (categories && attributes) {
      this.loadDynamicAggregations();
    }
  };

  loadDynamicAggregations = async () => {
    const { categories } = this.props.categories;
    const { attributes } = this.props.attributes;
    const { listingStatusIds } = this.state;

    const body = {
      aggs: {
        all_inventory: {
          filter: { terms: { listingStatusId: [1, 2, 3, 4, 5, 6, 7, 8] } },
          aggs: {
            listingStatusId: { terms: { field: 'listingStatusId' } },
            categoryIds: { terms: { field: 'categories.categoryId' } },
            attributeIds: { terms: { field: 'attributes.attributeId' } },
          },
        },
        available_inventory: {
          filter: { terms: { listingStatusId: listingStatusIds } },
          aggs: {
            categoryIds: { terms: { field: 'categories.categoryId' } },
            attributeIds: { terms: { field: 'attributes.attributeId' } },
          },
        },
      },
    };

    // add the range mins and maxes for attributes with range sliders
    attributes.forEach(attribute => {
      let { attributeName, settings, typeId } = attribute;
      settings = isJSON(settings) ? JSON.parse(settings) : settings || {};
      const field = get(settings, 'filter.filters[0].field');
      if (field && [2, 3].includes(typeId)) {
        body.aggs.available_inventory.aggs[attributeName] = {
          stats: { field },
        };
      }
    });

    const dynamicAggregations = await this.props
      .loadAggregationsES(searchRoot, body)
      .then(({ response }) => (response ? response.aggregations : {}));

    const aggregationTree = get(makeSearchTree(categories), 'children[0]', {});

    this.setState(
      {
        dynamicAggregations,
        aggregationTree,
      },
      () => {
        // TODO: - verify logic
        const option_a = 'all_inventory.categoryIds.buckets';
        const option_b = 'available_inventory.categoryIds.buckets';
        const filterIds_cats = get(dynamicAggregations, option_a, []).map(
          bucket => Number(bucket.key)
        );

        const dynamicCategoriesList = makeDynamicCategoriesList(
          aggregationTree,
          filterIds_cats
        );

        const categoryId =
          this.findCategoryId() || aggregationTree.categoryId || '';

        const filterIds_atts = get(
          find(dynamicCategoriesList, { categoryId }),
          'ancestors',
          []
        );
        this.loadDynamicFilters(filterIds_atts);
        this.loadDynamicSort(filterIds_atts);
      }
    );
  };

  loadDynamicFilters(filterIds_atts) {
    let dynamicFilterList = (this.props.attributes.attributes || [])
      .filter(attribute => attribute.searchable)
      // .filter(attribute => filterIds_atts.includes(attribute.categoryId))
      .map((attribute, index) => {
        let { attributeName, settings } = attribute;
        settings = isJSON(settings) ? JSON.parse(settings) : settings || {};
        return { attributeName, ...settings.filter };
      })
      .filter(filter => filter && filter.panel && filter.filters)
      .sort((a, b) => a.panel.order - b.panel.order);

    // categories is not an attribute but we want it to act as a filter
    dynamicFilterList.splice(1, 0, {
      attributeName: 'Categories',
      filters: [{ filterTypeId: 7 }],
      panel: {
        title: '',
        collapsable: true,
        defaultCollapsed: true,
        order: 1,
      },
    });

    // TODO:  - experimental
    dynamicFilterList.push({
      attributeName: 'eventName',
      filters: [{ filterTypeId: 8, field: 'eventName.raw' }],
      panel: {},
    });

    this.setState({
      dynamicFilterList,
    });
  }

  loadDynamicSort(filterIds_atts) {
    let dynamicSortOptions = [
      { label: 'Relevance', field: '_score', order: 'desc' },
    ];

    (this.props.attributes.attributes || [])
      .filter(attribute => attribute.sortable)
      // .filter(attribute => filterIds_atts.includes(attribute.categoryId))
      .map(attribute => {
        let { attributeName, settings = {}, typeId } = attribute;
        settings = isJSON(settings) ? JSON.parse(settings) : settings || {};
        const field = get(settings, 'filter.filters[0].field');
        const order = get(settings, 'filter.panel.order') || 0;
        return { attributeName, field, order, typeId };
      })
      .sort((a, b) => a.order - b.order)
      .forEach((item, index) => {
        const { attributeName, field, typeId } = item;

        if (field) {
          if ([2, 3].includes(typeId)) {
            dynamicSortOptions.push({
              label: `${attributeName} - Highest First`,
              field,
              order: 'desc',
            });
            dynamicSortOptions.push({
              label: `${attributeName} - Lowest First`,
              field,
              order: 'asc',
            });
          } else {
            dynamicSortOptions.push({
              label: attributeName,
              field,
              order: 'asc',
            });
          }
        }
      });

    const defaultOptionObj =
      find(dynamicSortOptions, { defaultOption: true }) ||
      dynamicSortOptions[0];
    const defaultSortOption = `${defaultOptionObj.field}_${defaultOptionObj.order}`;

    this.setState({
      dynamicSortOptions,
      defaultSortOption,
    });
  }

  findCategoryId() {
    return Number(get(this.searchkit, 'state.categories[0][0]'));
  }

  isSortedByEndingSoonest = () => {
    // and either the user has selected Ending Soonest sort *or*
    // it's the initial page load and the default sort is Ending Soonest
    return (
      get(this.searchkit, 'state.sort') === 'epochEndTime_asc' ||
      (!get(this.searchkit, 'state.sort') &&
        this.state.defaultSortOption === 'epochEndTime_asc')
    );
  };

  /**
   * @description Create dynamic categories list based on `state.aggregationTree`
   *  **Note**: only applies to Dynamic Filters
   * @returns {object}
   */
  makeSelectedFilterTranslations() {
    return enableDynamicAttributes
      ? makeDynamicCategoriesList(this.state.aggregationTree, null).reduce(
          (obj, cat) => ({ ...obj, [cat.categoryId]: cat.label }),
          {}
        )
      : {};
  }

  loadLocation(prevProps) {
    if (
      this.geoSuggest &&
      get(this.props, 'userSelectedLocation.address') !==
        get(prevProps, 'userSelectedLocation.address')
    ) {
      this.geoSuggest.update(this.props.userSelectedLocation.address || '');
    }
  }

  tick = () => {
    // This function is called every 50 ms. It updates the
    // elapsed counter. Calling setState causes the component to be re-rendered
    var map = false;
    if ($($('.sk-toggle-option')[4]).hasClass('is-active')) map = true;
    if (map !== this.state.mapOn) this.setState({ mapOn: map });
  };

  handleResize = (event) => {
    const isMobileDevice = window.matchMedia('(max-width: 650px)').matches;
    if (isMobileDevice !== this.state.isMobileDevice) {
      this.setState({isMobileDevice})
    }
  }

  handleSearchPanel = show => {
    this.setState({ showSearchPanel: show });
    localStorage.setItem('showSearchPanel', show ? '1' : '0');
  };

  loadScrollPosition = val => {
    const searchScroll =
      val || val === 0 ? val : sessionStorage.getItem('searchScroll');
    if (searchScroll || searchScroll === 0) {
      setTimeout(() => {
        window.scrollTo({ top: searchScroll });
      }, 0);
      sessionStorage.removeItem('searchScroll');
    }
  };

  handleSortChange = prevState => {
    const sortOption = get(this.searchkit, 'state.sort');

    if (sortOption && sortOption !== this.state.defaultSortOption) {
      this.setState({ defaultSortOption: sortOption });
      localStorage.setItem('defaultSortOption', sortOption);
    }
  };

  handleView = () => {
    const view = get(this.searchkit, 'state.view');

    if (view && view !== this.state.defaultView) {
      this.setState({ defaultView: view });
      localStorage.setItem('defaultView', view);
    } 
  };

  handleBreadcrumbs = () => {
    // the navbar handles almost all the breadcrumbs, however isn't aware of any search page filters.
    // so the search page has to do a little work here to update the breadcrumbs regarding the filters
    const { pathname, search } = this.searchkit.history.location;
    const newBreadcrumbs = {
      path: '/search',
      crumbs: [{ text: 'Search', link: pathname + search }],
    };
    sessionStorage.setItem('bcCrumbs', JSON.stringify(newBreadcrumbs));
  };

  handleNavItems(results) {
    // typically nav items are handled when a user clicks a vehicle card (or Details button button) to go to a VDP.
    // but we can't do that on search page vehicle cards so we do it here on results changes.
    sessionStorage.setItem('navItems', results.hits.ids || '');
  }

  handleMapping(results) {
    // TODO - what is all this?
    var mapMarkers = [];
    $('#latLonDiv select > option').each(function () {
      var latlon = this.text.split(',');
      if (latlon.length > 1) {
        // mapMarkers.push({
        //   position: { lat: Number(latlon[0]), lng: Number(latlon[1]) },
        //   showInfo: false,
        //   key: this.value,
        //   defaultAnimation: 1,
        //   infoContent: (
        //     <div style={{ whitespace: 'nowrap' }}>{latlon[2]}</div>
        //   ),
        // });
      }
    });

    this.setState({
      markers: mapMarkers,
    });
    forceCheck();
  }

  handleMarkerMouseOver = targetMarker => {
    const markers = this.state.markers.map(marker => {
      return {
        ...marker,
        showInfo: marker === targetMarker,
      };
    });

    this.setState({ markers });
  };

  handleMarkerMouseOut = targetMarker => {
    const markers = this.state.markers.map(marker => {
      return {
        ...marker,
        // showInfo: marker === targetMarker
      };
    });

    this.setState({ markers });
  };

  handleMarkerClick = targetMarker => {
    $('#latLonDiv select').val(targetMarker.key);
    var evt = document.createEvent('HTMLEvents');
    evt.initEvent('change', true, true);
    $('#latLonDiv select')[0].dispatchEvent(evt);
  };

  handleMarkerClose = () => {
    return null;
  };

  handleUserSelectedLocation = data => {
    // user selected account location
    if (data.account) {
      this.props.loadUserSelectedLocation({
        key: 'account',
        location: data.location,
        address: data.label,
        city: data.account.city,
        state: findStateAbbreviation(data.account.state),
        zipcode: data.account.zipcode,
        accountId: data.account.accountId,
      });
    }
    // user selected some other location
    else {
      this.props.loadUserSelectedLocation({
        key: 'other',
        location: data.location,
        address: data.label,
      });
    }

    this.geoSuggest.update(data.label);
    this.searchkit.reloadSearch();
  };

  resetDistanceAndLocation = () => {
    this.setState({ userSelectedDistance: '100000' }, () => {
      this.geoSuggest.update(this.props.userSelectedLocation.address || '');
      this.searchkit.reloadSearch();
      this.props.loadUserSelectedLocation({});
      this.props.loadUserSelectedDistance('');
    });
  };

  renderAccountLocations() {
    // the account addresses will be at the top of the list a convenience to the user
    const accountListAddresses = get(
      this.props.userProfile,
      'user.accountList',
      []
    )
      .map(account => {
        // account must have city, state, AND zip to show up in list
        if (!account.city || !account.state || !account.zipcode) {
          return null;
        }

        // the "*" in the label keeps geo-suggest from suggesting locations too similar to these account locations
        // and we want the user to select the exact account location and not a similar one.
        // this is because when the user selects an account location the distance will match the transportation calculation distance
        const label = `* ${account.city}, ${findStateAbbreviation(
          account.state
        )} ${account.zipcode}`;

        return { className: 'fixtures', label, account };
      })
      .filter(fixture => fixture);

    return uniqBy(accountListAddresses, 'label');
  }

  makeDistanceOptions() {
    const distanceUnits = findDistanceUnits(this.props.marketplaceFeatures);
    const distances =
      distanceUnits === 'km'
        ? [100000, 5, 10, 25, 50, 100, 250, 500, 1000, 2500]
        : [100000, 5, 10, 25, 50, 100, 250, 500, 1000, 2500];

    return distances.map((value, index) => ({
      key: index,
      value: String(value),
      label:
        value === 100000
          ? 'Any Distance'
          : `Within ${commafy(value)} ${distanceUnits}`,
    }));
  }

  makeDistanceValue() {
    const { userSelectedDistance } = this.state;
    const { marketplaceFeatures } = this.props;
    const units = findDistanceUnits(marketplaceFeatures);
    const label =
      userSelectedDistance === '100000'
        ? 'Any Distance'
        : `${commafy(userSelectedDistance)} ${units}`;

    return {
      value: userSelectedDistance,
      label,
    };
  }

  renderSidebarFilters() {
    return enableDynamicAttributes
      ? this.renderDynamicFilters()
      : this.renderStaticFilters();
  }

  renderStaticFilters() {
    // this renders both the static and dynamic filters from a single list created by this.loadFilters().
    // static filters don't have a typeId, but dynamic filters do, hence the split.
    return (this.state.staticFilterList || []).map((filterObj, index) => {
      const {
        collapsable = true,
        defaultCollapsed = true,
        panel,
        typeId,
      } = filterObj;
      let filter = null;

      if (!typeId) {
        // static filters
        let {
          field = '',
          label,
          operator = 'OR',
          orderDirection = 'asc',
          orderKey = '_term',
          size = 10,
          title = '',
          translations = { '': 'Not Specified' },
        } = filterObj;
        let id = filterObj.id || field.replace('.raw', '');

        switch (panel) {
          case 'Search Vehicles': {
            filter = (
              <SearchBox
                translations={{
                  'searchbox.placeholder': 'search vehicles',
                }}
                autofocus={true}
                searchOnChange={true}
                queryOptions={{
                  analyzer: 'standard',
                  default_operator: 'AND',
                  minimum_should_match: '100%',
                }}
                prefixQueryFields={[
                  'name^10',
                  'sellerName^5',
                  'exteriorColor^5',
                  'drive^5',
                  'engine^5',
                  'transmission^5',
                  'vIN^5',
                  'stockNumber^5',
                  'make^5',
                  'model^5',
                  'series^5',
                ]}
              />
            );
            break;
          }

          case 'Seller': {
            filter = (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/*<SearchBox
                  translations={{
                    'searchbox.placeholder': 'search sellers',
                  }}
                  autofocus={true}
                  searchOnChange={true}
                  queryOptions={{
                    analyzer: 'standard',
                    default_operator: 'AND',
                    minimum_should_match: '100%',
                  }}
                  prefixQueryFields={['sellerName^10']}
                />*/}
                <RefinementListFilter
                  id={id}
                  field={field}
                  title={title}
                  orderKey={orderKey}
                  orderDirection={orderDirection}
                  operator={operator}
                  size={size}
                  translations={translations}
                />
              </div>
            );
            break;
          }

          case 'Body Style':
          case 'City':
          case 'Color':
          case 'Drive':
          case 'Engine':
          case 'Event':
          case 'Fuel Type':
          case 'Make':
          case 'Marketplace Type':
          case 'Model':
          case 'Options':
          case 'State':
          case 'Transmission': 
          case 'Trim': {
            filter = (
              <RefinementListFilter
                id={id}
                field={field}
                title={title}
                orderKey={orderKey}
                orderDirection={orderDirection}
                operator={operator}
                size={size}
                translations={translations}
              />
            );
            break;
          }

          case 'Year': {
            const stats = get(
              this.state.staticAggregations,
              `available_inventory[${field}]`
            );
            const min = stats.min || 1980;
            let max = stats.max || 2024;
            if (min === max) max += 1; // if all items have same value there is no range and searchkit breaks

            filter = (
              <RangeFilter
                id={id}
                field={field}
                title=""
                min={min}
                max={max}
                rangeComponent={RangeSliderInput}
              />
            );
            break;
          }

          case 'Odometer': {
            const stats = get(
              this.state.staticAggregations,
              `available_inventory[${field}]`
            );
            const min = stats.min || 0;
            let max = stats.max || 400000;
            if (min === max) max += 1; // if all items have same value there is no range and searchkit breaks

            filter = (
              <RangeFilter
                id={id}
                field={field}
                title=""
                min={min}
                max={max}
                rangeFormatter={commafy}
                rangeComponent={RangeSliderInput}
                
              />
            );
            break;
          }

          case 'Buy Now Price': {
            const stats = get(
              this.state.staticAggregations,
              `available_inventory[${field}]`
            );

            const min = stats.min || 0;
            let max = stats.max || 100000;
            if (min === max) max += 1; // if all items have same value there is no range and searchkit breaks

            filter = (
              <div>
                <CheckboxFilter
                  id="hasPrice"
                  title=""
                  label="Has Buy Now Price"
                  // UPDATE 2025.04 - Exclude 'If-Sale' / 'Reserve' listings
                  filter={{
                    bool: {
                      must: [{ range: { [field]: {gt: 0} } }],
                      must_not: [{ term: { isReserveUnit: true } }]
                    }
                  }}
                />
                <RangeFilter
                  id="outRightPrice"
                  field={field}
                  title=""
                  min={min}
                  max={max}
                  rangeFormatter={commafyCurrency}
                  rangeComponent={RangeSliderInput}
                />
              </div>
            );

            break;
          }

          case 'Retail Price': {
            const features = (
              this.props.marketplaceFeatures.features || ''
            ).split(',');
            const retailPriceEnabled = features.includes('547');

            const retailStats = get(
              this.state.staticAggregations,
              `available_inventory.feedPrice`
            );
            const min = retailStats.min || 0;
            let max = retailStats.max || 100000;
            if (min === max) max += 1; // if all items have same value there is no range and searchkit breaks

            filter = retailPriceEnabled ? (
              <div>
                <CheckboxFilter
                  id="hasRetailPrice"
                  title=""
                  label="Has Retail Price"
                  filter={BoolMust([RangeQuery('feedPrice', { gt: 0 })])}
                />
                <RangeFilter
                  id="retailPrice"
                  field="feedPrice"
                  title=""
                  min={min}
                  max={max}
                  rangeFormatter={commafyCurrency}
                  rangeComponent={RangeSliderInput}
                />
              </div>
            ) : null;

            break;
          }
          
          case 'Photo Count': {
            const stats = get(
              this.state.staticAggregations,
              `available_inventory[${field}]`
            );
            const min = stats.min || 0;
            let max = stats.max || 50;
            if (min === max) max += 1; 
            filter = (
              <RangeFilter
                id={id}
                field={field}
                label="Photo Count"
                title=""
                min={min}
                max={max}
                rangeFormatter={commafy}
                rangeComponent={RangeSliderInput}
              />
            );
            break;
          }

          case 'Bid or Offer': {
            filter = (
              <CheckboxFilter
                id={id}
                title={title}
                label={label}
                filter={TermQuery(id, 'true')}
              />
            );
            break;
          }

          case 'Condition Report': {
            filter = (
              <CheckboxFilter
                id="hasCR"
                title=""
                label="Has CR"
                filter={TermQuery('hasCR', 'true')}
              />
            );
            break;
          }
          case 'CR Grade': {
            filter = (
              <RangeFilter
                id="conditionRating"
                field="ratingValue"
                title=""
                min={0.0}
                max={5.0}
              />
            );
            break;
          }

          case 'Location': {
            filter = (
              <MenuFilter
                id={id}
                field={field}
                title={title}
                operator={operator}
                size={size}
                translations={translations}
                listComponent={Select}
              />
            );
            break;
          }

          case 'Distance': {
            const distanceLabel = this.makeDistanceValue();
            filter = (
              <div>
                <div className="form-group">
                  <Geosuggest
                    ref={el => (this.geoSuggest = el)}
                    initialValue={this.props.userSelectedLocation.address || ''}
                    placeholder="Enter buyer location"
                    onFocus={() => this.geoSuggest.update(' ')}
                    onBlur={() => {
                      this.geoSuggest.update(
                        this.props.userSelectedLocation.address || ''
                      );
                    }}
                    fixtures={this.renderAccountLocations()}
                    autoComplete="true"
                    autoActivateFirstSuggest={false}
                    highlightMatch={true}
                    onSuggestSelect={this.handleUserSelectedLocation}
                    onSuggestNoResults={() => {}}
                    // country={['us', 'ca', 'mx', ]}
                    style={{
                      input: { fontSize: 16 },
                      suggests: { fontSize: 14 },
                      suggestItem: { fontSize: 14 },
                    }}
                  />
                </div>
                <div className="form-group"> 
                  <RangeSliderInputCustom 
                    onFinished={this.handleDistanceSliderFinish}
                    isActive={true}
                    isDisabled={!this.props.userSelectedLocation.key}
                    maxValue='2500'
                    marketplaceFeatures={this.props.marketplaceFeatures}
                    nextValue={this.state.dropdownSelectedDistance}
                    showLabel={true}
                    style={{padding: '0px', margin: '0px auto 10px'}}
                  />
                  <Select
                    id="searchDistance"
                    styles={dropdownStyle}
                    disabled={!this.props.userSelectedLocation.key}
                    options={this.makeDistanceOptions()}
                    value={this.makeDistanceValue()}
                    onChange={option => {
                      const userSelectedDistance = option.value;
                      const dropdownSelectedDistance = userSelectedDistance;
                      this.setState({ userSelectedDistance, dropdownSelectedDistance }, () => {
                        this.searchkit.reloadSearch();
                        this.props.loadUserSelectedDistance(
                          userSelectedDistance
                        );
                      });
                    }}
                  />
                </div>
                <div id="latLonDiv" className="hidden">
                  <MenuFilter
                    id="latLon"
                    field="latLon.raw"
                    title=""
                    operator="OR"
                    size={5000}
                    translations={{ All: 'All Locations' }}
                    listComponent={Select}
                  />
                  <MenuFilter
                    id="marketplaceId"
                    field="marketplaceId"
                    title=""
                    operator="OR"
                    size={5000}
                    translations={{ All: 'All Locations' }}
                    listComponent={Select}
                  />
                </div>
              </div>
            );
            break;
          }

          case 'No-Sales': {
            const buckets = get(
              this.state.staticAggregations,
              'all_inventory.listingStatusId.buckets',
              []
            );
            const noSalesCount = buckets.reduce(
              (c, b) => ([5].includes(b.key) ? c + b.doc_count : c),
              0
            );
            const allCount = buckets.reduce(
              (c, b) => ([1, 3, 5].includes(b.key) ? c + b.doc_count : c),
              0
            );

            filter =
              noSalesCount > 0 ? (
                <div>
                  <div style={{ display: 'flex' }}>
                    <input
                      style={{ marginRight: 5 }}
                      type="checkbox"
                      checked={this.state.listingStatusIds.length === 3}
                      onChange={() => {
                        this.setState(
                          prevState => ({
                            listingStatusIds:
                              prevState.listingStatusIds.length === 3
                                ? [1, 3]
                                : [1, 3, 5],
                          }),
                          () => this.searchkit.reloadSearch()
                        );
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div>Show No-Sales</div>
                      <div style={{ color: '#ccc' }}>{allCount}</div>
                    </div>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <input
                      style={{ marginRight: 5 }}
                      type="checkbox"
                      checked={this.state.listingStatusIds.length === 1}
                      onChange={() => {
                        this.setState(
                          prevState => ({
                            listingStatusIds:
                              prevState.listingStatusIds.length === 1
                                ? [1, 3]
                                : [5],
                          }),
                          () => this.searchkit.reloadSearch()
                        );
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div>Only Show No-Sales</div>
                      <div style={{ color: '#ccc' }}>{noSalesCount}</div>
                    </div>
                  </div>
                </div>
              ) : null;
            break;
          }

          // NOTE - for future use?
          case 'Featured': {
            filter = (
              <CheckboxFilter
                id="featured"
                title=""
                label="Featured"
                filter={TermQuery('featured', 'true')}
              />
            );
            break;
          }

          // NOTE - for future use?
          case 'Recently Added': {
            filter = (
              <CheckboxFilter
                id="recentlyAdded"
                title=""
                label="Recently Added"
                filter={{
                  bool: {
                    must: [
                      { term: { hasImage: true } },
                      { range: { dateCreated: { gte: 'now-24h', lt: 'now' } } },
                    ],
                    must_not: [{ term: { eventFeatures: 41 } }],
                  },
                }}
              />
            );
            break;
          }

          // NOTE - for future use?
          case 'Recently Viewed': {
            const ids = get(this.props.userItems, 'itemList') || [];
            filter = (
              <CheckboxFilter
                id="recentlyViewed"
                title=""
                label="Recently Viewed"
                filter={{ bool: { must: [{ terms: { id: uniq(ids) } }] } }}
              />
            );
            break;
          }

          // NOTE - for future use?
          case 'Watchlist': {
            const ids = Object.keys(this.props.watchlistObject).filter(
              id => !isNaN(id)
            );
            filter = (
              <CheckboxFilter
                id="watchlist"
                title=""
                label="Watchlist"
                filter={{ bool: { must: [{ terms: { id: ids } }] } }}
              />
            );
            break;
          }

          default: {
            break;
          }
        }
      }

      return filter !== null ? (
        <div
          key={index}
          style={{ paddingBottom: panel === 'HyperSearch' ? 10 : 'auto' }}
        >
          <Panel
            key={index}
            title={['HyperSearch'].includes(panel) ? '' : panel}
            collapsable={collapsable}
            defaultCollapsed={defaultCollapsed}
          >
            {filter}
          </Panel>
        </div>
      ) : null;
    });
  }

  showPanel(attributeName, field) {
    // this is the crazy way to have the panel hide/show depending on whether there are values
    // in the resulting data.
    const resultAggs = get(this.searchkit, 'results.aggregations', {});
    const aggKeys = Object.keys(resultAggs);
    const aggKey = find(aggKeys, key => key.includes(attributeName)) || '';
    const aggObj = get(resultAggs, `[${aggKey}]`);

    const countObj =
      aggObj && aggObj[`${field}_count`]
        ? aggObj[`${field}_count`]
        : aggObj && aggObj[`${attributeName}`]
        ? aggObj[`${attributeName}`]
        : {};

    const count =
      countObj && countObj.value
        ? countObj.value
        : countObj && countObj.buckets
        ? countObj.buckets.reduce((sum, bucket) => {
            return sum + bucket.doc_count;
          }, 0)
        : null;

    return count === 0 ? false : true;
  }

  renderDynamicFilters() {
    return (this.state.dynamicFilterList || []).map((filter, index) => {
      const { attributeName = '', panel = {}, filters = [] } = filter;

      let _filterTypeId = 0;

      let showPanel = true;

      // each item might have more than 1 filter
      const filtersArr = filters.map((filterItem, index) => {
        const {
          field = '',
          filterTypeId,
          id,
          label = '',
          operator = 'OR',
          orderDirection = 'asc',
          orderKey = '_term',
          range = {},
          size = 10,
          title = '',
          translations = { '': 'Not Specified' },
        } = filterItem;

        _filterTypeId = filterTypeId;

        let filterEl = null;

        showPanel = this.showPanel(attributeName, field);

        switch (filterTypeId) {
          case 1: {
            filterEl = (
              <SearchBox
                translations={translations}
                autofocus={true}
                searchOnChange={true}
                queryOptions={{
                  analyzer: 'standard',
                  default_operator: 'AND',
                }}
                queryFields={[]}
                prefixQueryFields={[
                  'name^10',
                  'make^5',
                  'model^5',
                  'exteriorColor^5',
                  'drive^5',
                  'engine^5',
                  'transmission^5',
                  'vIN^5',
                  'stockNumber^5',
                  'eventName^10',
                ]}
              />
            );
            break;
          }
          case 2: {
            filterEl = (
              <MenuFilter
                id={attributeName}
                field={field}
                title={title}
                operator={operator}
                size={size}
                translations={translations}
                listComponent={Select}
              />
            );
            break;
          }
          case 3: {
            filterEl = (
              <RefinementListFilter
                id={attributeName}
                field={field}
                title={title}
                orderKey={orderKey}
                orderDirection={orderDirection}
                operator={operator}
                translations={translations}
              />
            );
            break;
          }
          case 4: {
            const stats = get(
              this.state.dynamicAggregations,
              `available_inventory.${attributeName}`,
              {}
            );
            const min = stats.min || range.min || 0;
            let max = stats.max || range.max || 999999;
            if (min === max) max += 1; // if all items have same value there is no range and searchkit breaks

            filterEl = (
              <RangeFilter
                id={attributeName}
                field={field}
                title={title}
                min={min}
                max={max}
                rangeFormatter={count =>
                  field === 'year' ? count : commafy(count)
                }
                rangeComponent={RangeSliderInput}
              />
            );
            break;
          }
          case 5: {
            // TODO: - verify id vs. attributeName
            filterEl = (
              <CheckboxFilter
                id={attributeName}
                title={title}
                label={label}
                filter={TermQuery(id, 'true')}
              />
            );
            break;
          }
          case 6: {
            filterEl = (
              <div>
                <div className="form-group">
                  <Geosuggest
                    ref={el => (this.geoSuggest = el)}
                    style={{
                      input: { fontSize: 12 },
                      suggests: { fontSize: 12 },
                      suggestItem: { fontSize: 12 },
                    }}
                    placeholder="Enter buyer location"
                    onFocus={() => this.geoSuggest.update(' ')}
                    onBlur={() => {
                      this.geoSuggest.update(
                        this.props.userSelectedLocation.address || ''
                      );
                    }}
                    fixtures={this.renderAccountLocations()}
                    autoComplete="true"
                    autoActivateFirstSuggest={false}
                    highlightMatch={true}
                    onSuggestSelect={this.handleUserSelectedLocation}
                    onSuggestNoResults={() => {}}
                    // country={['us', 'ca', 'mx', ]}
                  />
                </div>
                <div className="form-group">
                  <Select
                    id="searchDistance"
                    styles={dropdownStyle}
                    disabled={!this.props.userSelectedLocation.key}
                    options={this.makeDistanceOptions()}
                    value={this.makeDistanceValue()}
                    // defaultValue={this.makeDistanceOptions()[0]}
                    onChange={option => {
                      const userSelectedDistance = option.value;
                      this.setState({ userSelectedDistance }, () => {
                        this.searchkit.reloadSearch();
                        this.props.loadUserSelectedDistance(
                          userSelectedDistance
                        );
                      });
                    }}
                  />
                </div>
                <div id="latLonDiv" className="hidden">
                  <MenuFilter
                    id="latLon"
                    field="latLon.raw"
                    title=""
                    operator="OR"
                    size={5000}
                    translations={{ All: 'All Locations' }}
                    listComponent={Select}
                  />
                  <MenuFilter
                    id="marketplaceId"
                    field="marketplaceId"
                    title=""
                    operator="OR"
                    size={5000}
                    translations={{ All: 'All Locations' }}
                    listComponent={Select}
                  />
                </div>
              </div>
            );
            break;
          }
          case 7: {
            const hierarchy = makeDynamicCategoriesList(
              this.state.aggregationTree,
              null
            );
            const translations = hierarchy.reduce(
              (obj, cat) => ({
                ...obj,
                [cat.categoryId]: `${cat.label}`,
              }),
              {}
            );

            filterEl = (
              <HierarchicalMenuFilterCustom
                id="categories"
                title=""
                fields={['categories.categoryId']}
                translations={translations}
                hierarchy={hierarchy}
              />
            );
            break;
          }
          case 8: {
            showPanel = false;
            filterEl = (
              <RefinementListFilter
                id={attributeName}
                field={field}
                title={title}
                orderKey={orderKey}
                orderDirection={orderDirection}
                operator={operator}
                translations={translations}
              />
            );
            break;
          }
          default:
            break;
        }
        return <div key={index}>{filterEl}</div>;
      });

      // "hypersearch" and 'condition' hardcoded for now... will correct
      return (
        <div
          key={index}
          style={{
            display: showPanel ? 'inherit' : 'none',
            paddingBottom: panel === 'HyperSearch' ? 10 : 'auto',
          }}
        >
          <Panel
            title={
              ['HyperSearch', 'Category'].includes(panel.title)
                ? ''
                : panel.title
            }
            collapsable={
              [1, 7].includes(_filterTypeId) ? false : panel.collapsable
            }
            defaultCollapsed={panel.defaultCollapsed}
          >
            {filtersArr}
          </Panel>
        </div>
      );
    });
  }

  // NOTE: - here is the list you are always looking for
  renderResults() {
    return (
      <ViewSwitcherHits
        hitsPerPage={getConfig('hitsPerPage') || 36}
        sourceFilter={[
          'aged',
          'announcements',
          'attributes', // ari
          'attributeValues', // ari
          'bidIncrement',
          'bodyStyle', // disabled until data cleaned up
          'categories', // ari
          'city',
          'coordinates',
          'description',
          'distance',
          'script_fields',
          'geo_distance',
          'drive',
          'endDate',
          'engine',
          'epochEndTime',
          'epochStartTime',
          'eventFeatures',
          'eventId',
          'eventName',
          'eventTypeId',
          'exteriorColor',
          'factoryColor',
          'featured',
          'feedPrice',
          'fuelType',
          'hasBidOrOffer',
          'hasCR',
          'hasImage',
          'highBid',
          'highOffer',
          'highBidderAccountId',
          'id',
          'imageCount',
          'images',
          'isMPSellingLive',
          'isPrivateEvent',
          'isReserveUnit',
          'itemCity',
          'itemPostalCode',
          'itemState',
          'latitude',
          'latLon',
          'licenseNumber',
          'lights',
          'listingStatus',
          'listingStatusId',
          'listingTypeId',
          'liveStartTime',
          'longitude',
          'lotNumber',
          'mainImage',
          'make',
          'marketplaceFeatures',
          'marketplaceId',
          'marketplaceName',
          'marketplaceType',
          'mileage',
          'mileageDesc',
          'model',
          'name',
          'notes',
          'options',
          'outrightPrice',
          'preAged',
          'privateEvent',
          'rating',
          'ratingValue',
          'retailBookValue',
          'sellerName',
          'series',
          'startDate',
          'startingBid',
          'state',
          'stockNumber',
          'transmission',
          'vIN',
          'wholesaleBookValue',
          'year',
          'hasSubListings',
          'subItemDetails',
          'bulkLotDescription',
        ]}
        hitComponents={[
          {
            key: 'grid',
            title: 'Grid',
            itemComponent: <GridItem showVehicleValuesButton={this.state.showVehicleValuesButton} />,
            defaultOption: this.state.defaultView === 'grid',
          },
          {
            key: 'list',
            title: 'List',
            itemComponent: <ListItem showVehicleValuesButton={this.state.showVehicleValuesButton} />,
            defaultOption: this.state.defaultView === 'list',
          },
        ]}
      />
    );
  }

  renderNoResults() {
    return (
      <NoHits
        component={props => (
          <NoHitsDisplayCustom
            {...props}
            searchkit={this.searchkit}
            defaultSortOption={this.state.defaultSortOption}
            isSortedByEndingSoonest={this.isSortedByEndingSoonest}
            resetDistanceAndLocation={this.resetDistanceAndLocation}
          />
        )}
      />
    );
  }

  renderSort() {
    const options = enableDynamicAttributes
      ? this.state.dynamicSortOptions
      : this.state.staticSortOptions;

    if (!options) return null;

    return (
      <SortingSelector
        options={options}
        listComponent={props => (
          <SearchKitSelect
            {...props}
            sort={get(this.searchkit, 'state.sort')}
          />
        )}
      />
    );
  }

  renderGridListToggle() {
    return (
      <div style={{ display: this.state.isMobileDevice ? 'none' : 'inline', float: 'right' }}>
        <ViewSwitcherToggle />
      </div>
    );
  }

  renderResultsStats() {
    return (
      <HitsStats
        component={props => (
          <HitStatsCustom {...props} searchkit={this.searchkit} />
        )}
        translations={{
          'hitstats.results_found': '{hitCount} results found',
        }}
      />
    );
  }
  renderSearchPanelHide = () => {
    return (
      <div className="sk-toggle">
        <div
            className={
              !this.state.showSearchPanel
                ? 'sk-toggle-option sk-toggle__item is-active'
                : 'sk-toggle-option sk-toggle__item'
            }
            onClick={() => this.handleSearchPanel(false)}
            style={{ padding: '4px 8px' }}
          >
            <div className="sk-toggle-option__text">
              <i className="fa fa-search-minus" /> Hide Filters
            </div>
          </div>
      </div>
    )
  }
  renderSearchPanelToggle() {
    return (
      <div
        className="sk-toggle"
        style={{ marginLeft: '0', marginRight: '10px' }}
      >
        <div
          className={
            this.state.showSearchPanel
              ? 'sk-toggle-option sk-toggle__item is-active'
              : 'sk-toggle-option sk-toggle__item'
          }
          onClick={() => this.handleSearchPanel(true)}
          style={{ padding: '4px 8px' }}
        >
          <div className="sk-toggle-option__text">
            <i className="fa fa-search" /> Show Filters
          </div>
        </div>
        <div
          className={
            !this.state.showSearchPanel
              ? 'sk-toggle-option sk-toggle__item is-active'
              : 'sk-toggle-option sk-toggle__item'
          }
          onClick={() => this.handleSearchPanel(false)}
          style={{ padding: '4px 8px' }}
        >
          <div className="sk-toggle-option__text">
            <i className="fa fa-search" /> Hide Filters
          </div>
        </div>
      </div>
    );
  }

  renderSaveSearchButton() {
    const searchResultsCount = this.searchkit?.results?.hits?.total?.value;
    const postFilter = this.searchkit?.query?.query?.post_filter;

    return (
      <div style={{ display: 'inline', float: 'left' }}>
        <WishlistButton
          location={this.props.location}
          searchResultsCount={searchResultsCount}
          postFilter={postFilter}
          userSelectedDistance={this.state.userSelectedDistance}
          userSelectedLocation={this.props.userSelectedLocation}
        />
      </div>
    );
  }

  renderSelectedDistanceFilter() {
    // NOTE: - this is a faux filter
    if (
      isEmpty(this.props.userSelectedLocation) ||
      this.state.userSelectedDistance === '100000'
    )
      return null;

    const dist = this.state.userSelectedDistance;
    const loc = this.props.userSelectedLocation.address;
    const units = findDistanceUnits(this.props.marketplaceFeatures);
    const text = `${dist} ${units} from ${loc}`;

    return (
      <div className="sk-selected-filters-option sk-selected-filters__item">
        <div className="sk-selected-filters-option__name">{text}</div>
        <div
          className="sk-selected-filters-option__remove-action"
          onClick={this.resetDistanceAndLocation}
        >
          x
        </div>
      </div>
    );
  }

  renderSelectedFilters() {
    return (
      <div className="sk-selected-filters-container">
        <SelectedFilters
          itemComponent={
            <SelectedFilterCustom
              userSelectedDistance={this.state.userSelectedDistance}
              userSelectedLocation={this.props.userSelectedLocation}
            />
          }
          translations={this.makeSelectedFilterTranslations()}
        />
        {this.renderSelectedDistanceFilter()}
      </div>
    );
  }

  renderPrintButton() {
    return (
      <div
        style={{
          display: 'inline',
          float: 'left',
          marginRight: '10px',
        }}
      >
        <PrintFrame printTarget="#searchContainer" page="search" />
      </div>
    );
  }

  renderClearAllFiltersButton() {
    return (
      <ResetFilters
        component={props => (
          <ResetFiltersDisplayCustom
            {...props}
            userSelectedDistance={this.state.userSelectedDistance}
            resetDistanceAndLocation={this.resetDistanceAndLocation}
            searchkit={this.searchkit}
            sort={get(this.searchkit, 'state.sort')}
          />
        )}
      />
    );
  }

  renderMapSearch() {
    return false && this.state.mapOn ? ( // will put back when the map view gets fixed
      <div
        style={{
          height: `500px`,
          float: 'left',
          width: '100%',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          marginBottom: '10px',
        }}
      >
        {/* <MapSearch
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          markers={this.state.markers}
          onMarkerMouseOver={this.handleMarkerMouseOver}
          onMarkerMouseOut={this.handleMarkerMouseOut}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          mapConfig={mapConfig}
        /> */}
      </div>
    ) : null;
  }

  renderPagination(position) {
    return position === 'top' ? (
      <Pagination showNumbers={true} />
    ) : (
      <div onClick={() => this.loadScrollPosition(0)}>
        <Pagination showNumbers={true} pageScope={3} />
      </div>
    );
  }

  render() {
    if (
      !this.props.userProfile.user ||
      !this.searchkit ||
      (!this.state.dynamicAggregations && !this.state.staticAggregations) ||
      (!this.state.dynamicFilterList && !this.state.staticFilterList)
    ) {
      return null;
    }

    return (
      <SearchkitProvider searchkit={this.searchkit}>
        <Layout size="l">
          <LayoutBody>
            <div className="search-wrapper">
              <div
                className={
                  this.state.showSearchPanel
                    ? 'search-sidebar'
                    : 'search-sidebar-hide'
                }
              >
                <SideBar>
                  {this.state.isMobileDevice 
                    ? this.renderSearchPanelHide()
                    : null
                  }
                  {this.renderSidebarFilters()}
                </SideBar>
              </div>
              <div
                id="searchContainer"
                className={
                  this.state.showSearchPanel
                    ? 'search-content'
                    : 'search-content-hide'
                }
              >
                <LayoutResults>
                  <ActionBar>
                    <ActionBarRow>
                      {this.renderSearchPanelToggle()}
                      {this.renderResultsStats()}
                      {this.renderGridListToggle()}
                      {this.renderSort()}
                    </ActionBarRow>
                    <ActionBarRow>
                      {this.renderSelectedFilters()}
                      {this.renderSaveSearchButton()}
                      {this.renderPrintButton()}
                      {this.renderClearAllFiltersButton()}
                    </ActionBarRow>
                  </ActionBar>
                  {this.renderMapSearch()}
                  {this.renderPagination('top')}
                  {this.renderResults()}
                  {this.renderNoResults()}
                  <InitialLoader />
                  {this.renderPagination('bottom')}
                </LayoutResults>
              </div>
            </div>
          </LayoutBody>
        </Layout>
      </SearchkitProvider>
    );
  }
}

const mapStateToProps = state => {
  const {
    attributes,
    categories,
    geocode,
    listingStatusIds,
    marketplaceFeatures,
    userItems,
    userProfile,
    userSelectedDistance,
    userSelectedLocation,
    unauthorizedEventIds,
    onlineConnected,
    realTimeConnected,
    watchlistObject,
  } = state.entities;
  return {
    attributes,
    categories,
    geocode,
    listingStatusIds,
    marketplaceFeatures,
    userItems,
    userProfile,
    userSelectedDistance,
    userSelectedLocation,
    unauthorizedEventIds,
    onlineConnected,
    realTimeConnected,
    watchlistObject,
  };
};

export default connect(mapStateToProps, {
  loadAggregationsES,
  loadAttributes,
  loadCategories,
  loadMarketplaces,
  loadUserSelectedDistance,
  loadUserSelectedLocation,
})(Search);
