import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getConfig, getFips, formatPhoneNumber } from "../../utils/helpers";
import { confirm } from "../common/confirm/CallConfirm";
import { toastr } from "react-redux-toastr";
import { loadARIAddressValidation, loadUpdateUserProfile } from "../../actions";
import { userFields } from "./userFields";

// #region ProfileInformation
/** Profile --> Profile Information ("/profile")
 * 
 * @param {{
 *  tabProfile: ''|'active', 
 *  tabTitle?: 'Profile', 
 *  loadValidLocations: ()=>void, 
 *  onHasChanged: (val:boolean)=>void
 * }} props 
 * @returns {React.JSX}
 */
export const ProfileInformation = ({tabProfile, tabTitle, onHasChanged}) => {
    // Redux State
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.entities.userProfile);
    const { user } = userProfile;
    
    // Local State
    const [locations, setLocations] = useState(initLocationsData(user));
    const [locationIndex, setLocationIndex] = useState(0);
    const [hasChanged, setHasChanged] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [fields, setFields] = useState(initFieldData(user));
    const [validating, setValidating] = useState(false);

    // Callbacks | Handlers
    const handleHasChanged = (changed) => {
      setHasChanged(changed);
      onHasChanged(changed);
    }

    const handleProfileUpdate = () => {
        // gather all the potentially changed fields from state
        const stateFields = userFields.reduce(
          (obj, field) => ({ ...obj, [field.field]: fields[field.field] }),
          {}
        );
        // check for required fields
        const missing = userFields
          .filter(field => {
            if (field.required) {
              return field.validate
                ? !field.validate(stateFields[field.field])
                : stateFields[field.field] === '';
            } else {
              return false;
            }
          }).map(field => field.label);
    
        if (missing.length) {
          confirm(`Please complete the following: ${missing.join(', ')}`, {
            hideCancel: true,
          });
          return;
        }
    
        // the body that gets sent with the api POST
        const updatedUserProfile = {
          user: { ...user, ...stateFields },
        };
    
        handleUpdateUserProfile(updatedUserProfile);
    };

    const handleFieldsRefresh = (cancelEdit=false, updatedUser) => {
      if (cancelEdit) {
        setEditMode(false);
        handleHasChanged(false);
        if (getConfig('enableAddressValidation')) {
          setLocationIndex(0);
          setLocations(initLocationsData(updatedUser || user));
        }
      }
      setFields(initFieldData(updatedUser || user));
    }

    const handleUpdateUserProfile = (updatedUserProfile) => {
        if (getConfig('enableAddressValidation')) {
          const location = locations[locationIndex] || '';
        
          // check that the user has selected a valid location
          const isPlaceholer = typeof location === 'string';
          if (isPlaceholer) {
            confirm(`Please select a Location from the list`, { hideCancel: true });
            return;
          }
    
          // pull the address components off of the location object that came from the validate-address api
          updatedUserProfile.user.zipcode = location.postalCode;
          updatedUserProfile.user.city = location.city;
          updatedUserProfile.user.state = location.state;
          updatedUserProfile.user.county = location.county;
          updatedUserProfile.user.countyCode = location.fipsCountyCode;

        } else {
          // looks up county FIPS code or else uses '00000'
          if (
            updatedUserProfile.user.county !== userProfile.user.county ||
            updatedUserProfile.user.state !== userProfile.user.state
          ) {
            const { statefp, countyfp } = getFips({
              county: updatedUserProfile.user.county,
              state: updatedUserProfile.user.state,
            });
    
            updatedUserProfile.user.countyCode =
              statefp && countyfp ? statefp + countyfp : '00000';
          }
        }
    
        updatedUserProfile.keyCloakUrl = getConfig('keycloakUrl');
    
        confirm('Confirm changes?').then(
          () => {
            dispatch(loadUpdateUserProfile(getConfig('marketplaceId'), updatedUserProfile))
            .then(({ response }) => {
                if (
                  response.wsStatus === 'Success' &&
                  response.user.marketplaceId > 0
                ) {
                  toastr.success(response.wsStatus, 'Profile updated');
                  handleFieldsRefresh(true, response.user);
                } else {
                  toastr.error('Error', response.wsMessage);
                }
            })
            .catch(error => {
                toastr.error('Error', error.message);
                console.error(error);
            });
          },
          () => null
        );
    };

    /**
     * 
     * @param {React.MouseEventHandler<HTMLInputElement>} event 
     * @param {ProfileField} field 
     * @returns {void}
     */
    const handleInputChange = (event, field) => {
        if (!editMode || !field.edit) {
          return null;
        }
        const originalValue = event.target.value;
        let value = event.target.value;
        if (field.upperCase) value = value.toUpperCase();
        if (field.editValue) value = field.editValue(value); // zipcode, phone
    
        // when zipcode has been fully typed call ari validation api to populate the location dropdown
        if (getConfig('enableAddressValidation') && field.field === 'zipcode') {
          if ( value.length === 5) {
            setFields(prevFieldState => {return {...prevFieldState, zipcode: value}});
            handleHasChanged(true);
            setValidating(true, loadValidLocations(value));
          } else {
            setFields(prevFieldState => {return {...prevFieldState, zipcode: value}});
          }
        }
        else if (![value, originalValue].includes(fields[field.field]))  {
          setFields(prevFieldState => {return {...prevFieldState, [field.field]: value}});
          handleHasChanged(true);
      }
    };

    const loadValidLocations = async (zipcode) => {
        await dispatch(loadARIAddressValidation(zipcode))
          .then(({ response }) => {
            // ignore the response if changes have been cancelled (validating reset to false)
              if (response) {
                setLocations(['Select from the list...', ...response]);
                setValidating(false);
              } else {
                // restore
                toastr.error('Error validating location');
                setLocations(initLocationsData(user));
                setFields(prevState => {return {...prevState, zipcode: user.zipcode}});
                setValidating(false);
              }
            })
            .catch(error => {
              if (validating) {
                // restore
                toastr.error('Error validating location');
                setLocations(prevState => prevState);
                setFields(prevState => {return {...prevState, zipcode}})
                setValidating(false);
              }
              console.error(error);
        });
    }

    return (
      <div
        id="profileInfo"
        className={'profile-tab__information tab-pane ' + tabProfile}
        style={{
          display: tabTitle === 'Profile' ? 'flex' : 'none',
        }}
      >
        <ProfileForm 
            editMode={editMode} 
            fieldData={fields}
            isValidating={validating}
            locations={locations}
            locationIndex={locationIndex}
            onLocationChange={(val) => setLocationIndex(val)}
            onInputChange={handleInputChange} 
        />
        <FormButtons   
            editMode={editMode}
            hasChanged={hasChanged}
            isValidating={validating}
            onCancelChanges={() => handleFieldsRefresh(true)}
            onEditSelect={() => setEditMode(true)}
            onSubmitChanges={handleProfileUpdate}

        />
      </div>
    );
}
// #endregion ProfileInformation
// #region ProfileForm
/**
 * 
 * @param {ProfileFormProps} props
 * @returns 
 */
function ProfileForm ({
    editMode=false, 
    fieldData={}, 
    isValidating=false, 
    locations=[], 
    locationIndex=0,
    onInputChange, 
    onLocationChange,
}) {
    const { user } = useSelector(state => state.entities.userProfile);
    const formFields = userFields.map((field, index) => {
        let fieldValue = fieldData[field.field];
        let isInvalidInput = 
          field.validate 
          ? !field.validate(fieldValue)
          : field.required
          ? !fieldValue
          : false;
        return (
            <div key={index} className="form-group col-md-6 col-sm-6" style={{}}>
                <label className="control-label" htmlFor="name">
                    {field.label}
                </label>

                <input
                    id={field.field}
                    className="form-control input-sm"
                    style={{
                        backgroundColor: '#EFEFEF',
                        border:
                          (editMode && field.edit)
                              ? isInvalidInput
                                ? '1px solid red'
                                : '1px solid blue'
                              : 'initial',
                        cursor: editMode ? 'text' : 'default',
                    }}
                    type="text"
                    value={fieldValue || ''}
                    onChange={event => onInputChange(event, field)}
                    disabled={!editMode}
                    maxLength={field.maxLength}
                />
            </div>
        )}
    );

    if (getConfig('enableAddressValidation')) {
        const hasNewLocations =
            user.zipcode !== fieldData.zipcode &&
            fieldData.zipcode &&
            fieldData.zipcode.length >= 5 &&
            fieldData.editMode &&
            fieldData.locations.length &&
            !isValidating;

        const location = (
          <div key="location" className="form-group col-md-6 col-sm-6">
              <label className="control-label" htmlFor="name">
                  Location
              </label>
              <select
              id="location"
              className={`form-control input-sm ${
                  hasNewLocations ? '' : 'profile-select' // removes arrow until there is a legit list to select from
              }`}
              style={{
                  backgroundColor: '#EFEFEF',
                  border: hasNewLocations ? '1px solid blue' : 'initial',
                  cursor: 'default',
              }}
              value={locationIndex}
              disabled={!editMode || isValidating}
              onChange={event => {
                  onLocationChange(event.target.value);
              }}
              >
              {isValidating
                  ? [
                      <option key="loading" disabled>
                          Loading...
                      </option>,
                  ]
                  : locations.map((location, index) => {
                      const isPlaceholer = typeof location === 'string';
                      const text = isPlaceholer
                      ? location
                      : `${location.city}, ${location.state}, ${location.postalCode}, ${location.county}`;

                      return (
                          <option key={index} value={index} disabled={isPlaceholer}>
                              {text}
                          </option>
                      );
                  })}
              </select>
          </div>
        );

        // not a true user field which is why it's just tacked on to the UI
        formFields.push(location);
    }

    return (<form className='profile-tab__information-form'>{formFields}</form>);
}
// #endregion ProfileForm

// #region FormButtons
/**
 * 
 * @param {ProfileButtonsProps} props 
 * @returns {React.JSX}
 */
function FormButtons({
    editMode=false, 
    hasChanged=false, 
    isValidating=false, 
    onCancelChanges, 
    onEditSelect,
    onSubmitChanges, 
}) {
    if (editMode) {
        return (
            <div>
                <button
                style={{ marginLeft: 5, marginRight: 5, width: 80 }}
                onClick={() => {
                    if (hasChanged) {
                        confirm('Are you sure you want to cancel your changes?')
                        .then(
                            () => onCancelChanges(),
                            () => null
                        );
                    } else {
                        onCancelChanges();
                    }
                }}
                >
                    Cancel
                </button>

                <button
                    disabled={!hasChanged || isValidating}
                    style={{ marginLeft: 5, marginRight: 5, width: 80 }}
                    onClick={() => {
                        onSubmitChanges();
                    }}
                >
                    Save
                </button>
            </div>
        );

    } else {
        return (
            <button
                style={{ marginLeft: 5, marginRight: 5, width: 80 }}
                onClick={() => {
                    onEditSelect(true);
                }}
            >
                Edit
            </button>
        );
    }
}
// #endregion FormButtons

// #region Fields
const initFieldData = (userState) => {
    const stateFields = userFields.reduce((obj, field) => {
        const key = field.field;
        const value = userState[key] || '';
        const label = field.upperCase
            ? value.toUpperCase()
            : userState[field.field];
        
        return { ...obj, [key]: label };
    }, {});
    return stateFields;
}
// #endregion Fields

// #region Locations
const initLocationsData = (userState) => {
  return [{
    postalCode: userState.zipcode,
    state: userState.state,
    city: userState.city,
    county: userState.county,
    fipsCountyCode: userState.countyCode,
}]
}
// #endregion Locations

// #region Types
/**
 * @typedef {object} ProfileFormProps
 * @property {boolean} editMode 
 * @property {object }fieldData
 * @property {boolean} isValidating 
 * @property {object[]} locations
 * @property {number} locationIndex
 * @property {(
 *  event: React.MouseEventHandler<HTMLButtonElement>, 
 *  field: ProfileField
 * )=>void} onInputChange
 * @property {(value:string)=>void} onLocationChange
 * 
 */
/** @type {ProfileFormProps} */

/**
 * @typedef {object} ProfileButtonsProps
 * @property {boolean} editMode
 * @property {boolean} hasChanged
 * @property {boolean} isValidating
 * @property {()=>void} onCancelChanges
 * @property {()=>void} onEditSelect
 * @property {()=>void} onSubmitChanges
 */
/** @type {ProfileButtonsProps} */

/**
 * @typedef {object} ProfileField
 * @property {string} field
 * @property {string} label
 * @property {boolean} required
 * @property {boolean} edit
 * @property {boolean} upperCase
 * @property {boolean|undefined} edit
 * @property {number|undefined} maxLength
 * @property {number|undefined} minLength
 * @property {(value:string)=>string|undefined} validate
 * @property {(value:string)=>string|undefined} editValue
 */
/** @type {ProfileField} */