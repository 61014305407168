import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import { CYAN_01, GREY_05, BLACK_02, RED_01 } from '../../constants/colors';

class ExistingVinModal extends Component {
  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-10">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>

        <div
          className="pl-4 fs-20 font-semibold"
          style={{ color: this.props.disableEdit ? RED_01 : BLACK_02 }}
        >
          Existing VIN
        </div>

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderYMM() {
    const { vin } = this.props;

    if (vin && vin.decodedItems && vin.decodedItems.length) {
      const decodedItem = vin.decodedItems[0];
      return (
        <div
          className="text-base font-semibold mb-6"
          style={{ color: BLACK_02 }}
        >
          {`${decodedItem.year} ${decodedItem.make} ${decodedItem.model}`}
        </div>
      );
    }
  }

  renderDetails() {
    const { disableEdit, item } = this.props;

    const details = [
      { label: 'VIN:', value: item.vin },
      { label: 'Stock Number:', value: item.stockNumber },
      { label: 'Bodystyle:', value: item.bodyStyle },
      { label: 'Mileage:', value: item.odometer },
      { label: 'Transmission:', value: item.transmission },
      { label: 'Engine:', value: item.engine },
      { label: 'Driveline:', value: item.driveline },
      { label: 'Dealer:', value: item.dealerName + ' - ' + item.accountNumber },
      { label: 'Event:', value: item.saleDate },
    ];
    const placeholder = '-';

    return (
      <div>
        {details.map((detail, index) => {
          return (
            <div
              key={index}
              style={{
                ...styles.detailRow,
                color:
                  detail.label === 'Dealer:' && disableEdit ? RED_01 : BLACK_02,
              }}
            >
              <div style={styles.detailItem}>{detail.label}</div>
              <div>{detail.value || placeholder}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderButtons() {
    if (this.props.disableEdit) {
      return null;
    }

    return (
      <div>
        <div className="modal-mt">
          <Button
            btnText="Edit"
            outlined={false}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onNext}
          />
        </div>
        <div className="modal-mt">
          <Button
            btnText="Cancel"
            outlined={true}
            outlinedTextColor={BLACK_02}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onClose}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          shouldCloseOnEsc
          style={styles.customStyles}
          contentLabel={'existingVinModal'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            {this.renderHeader()}
            {this.renderYMM()}
            {this.renderDetails()}
            {this.renderButtons()}
          </div>
        </Modal>
      </div>
    );
  }
}

const styles = {
  customStyles: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      zIndex: 99,
    },
  },
  detailRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 10,
  },
  detailItem: {
    minWidth: '33%',
    marginRight: 5,
    fontWeight: 'bold',
  },
};

ExistingVinModal.propTypes = {
  disableEdit: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  vinInput: PropTypes.string,
};

const mapStateToProps = state => {
  const { item, vin } = state.core;
  return { item, vin };
};

export default connect(mapStateToProps, {})(ExistingVinModal);
