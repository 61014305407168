import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { TransportationModalMode } from './TransporationConstants';
import ContactForm from './ContactForm';
import TransportationQuotes from './TransportationQuotes';

export default function TransportationModal({ mpId, itemData, user, mode, isOpen, onClose, providers, selectedQuote }) {

    const styles = {
        customStyles: {
            overlay: {
                zIndex: 99,
                backgroundColor: 'rgba(0, 0, 0, .6)',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'space-around',
            flexWrap: 'wrap',
            padding: '0 .15rem',
        },
        quoteContainer: {
            width: '100%',
            padding: '1rem .5rem',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center'
        },
        divider: {
            borderColor: '#E5E5E5',
            width: '100%',
            alignSelf: 'center',
            marginTop: 15,
            marginBottom: 10,
        }
    };

    const startingMode = mode || TransportationModalMode.SELECTION;
    const [isModalOpen, setModalOpen] = useState(isOpen);
    const [modalMode, setModalMode] = useState((startingMode));
    const currentQuoteRef = useRef('');

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setModalMode(startingMode);
    }, [startingMode]);

    function handleClose() {
        setModalMode(startingMode);
        setModalOpen(false);
        onClose();
    }

    function renderHeader() {
        return (
            <div style={{ ...styles.header, alignSelf: 'stretch' }}>
                {/* Form Title */}
                <div style={{ fontSize: 24, color: '#535768' }}>
                    <div>
                        <span className="fa fa-truck-moving" />
                        <span style={{ marginLeft: 10 }}>
                            Order Transportation
                        </span>
                    </div>
                </div>
                {/* Close Modal */}
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleClose()}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        );
    }
    const {zipcode} = user;

    return (
        <Modal
            isOpen={isModalOpen}
            className={'marketplace-reusable__modal-content'}
            overlayClassName={'marketplace-reusable__modal-overlay'}
        >
            <div style={styles.container}>
                {renderHeader()}
                {
                    {
                        [TransportationModalMode.SELECTION]:
                            <div style={styles.quoteContainer}>
                                <TransportationQuotes
                                    itemData={itemData}
                                    destinationZip={zipcode}
                                    mpId={mpId}
                                    onSelect={(selection, providerQuote) => {
                                        currentQuoteRef.current = providerQuote;
                                        setModalMode(selection);
                                    }}
                                    providers={providers}

                                />
                            </div>,
                        [TransportationModalMode.ACERTUS]:
                            <ContactForm
                                mpId={mpId}
                                itemData={itemData}
                                user={user}
                                quoteProvider='Acertus'
                                confirmationText='Your contact form was submitted successfully -- a member from the Acertus Team will reach out to you.'
                                agreementText=' You agree to be contacted by ACERTUS and that the above information is correct.'
                                onClose={handleClose}
                                selectedQuote={selectedQuote || currentQuoteRef.current}
                            />,
                        [TransportationModalMode.ROYALTYSHIPS]:
                            <ContactForm
                                mpId={mpId}
                                itemData={itemData}
                                user={user}
                                quoteProvider='RoyaltyShips'
                                confirmationText='Your contact form was submitted successfully -- a member from the Royalty Logistics Team will reach out to you.'
                                agreementText=' You agree to be contacted by Royalty Logistics and that the above information is correct.'
                                onClose={handleClose}
                                selectedQuote={selectedQuote || currentQuoteRef.current}
                            />,
                        [TransportationModalMode.RUNBUGGY]:
                            <ContactForm
                                mpId={mpId}
                                itemData={itemData}
                                user={user}
                                quoteProvider='Runbuggy'
                                confirmationText='Your contact form was submitted successfully -- a member from the Runbuggy Team will reach out to you.'
                                agreementText=' You agree to be contacted by Runbuggy and that the above information is correct.'
                                onClose={handleClose}
                                selectedQuote={selectedQuote || currentQuoteRef.current}
                            />,
                    }[modalMode]
                }

            </div>
        </Modal>
    );
}


