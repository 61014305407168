import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { isEmpty, find } from 'lodash';
import { updateItem, updateVin, updateVariant } from '../../../../actions';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import Loader from '../ui/loader';
import { HEAVY_TRUCK_BODYSTYLES } from '../../constants/common';
import {
  CYAN_01,
  BLACK_02,
  GREY_05,
  BLACK_03,
  WHITE_01,
  GREY_04,
} from '../../constants/colors';

const styles = {
  customStyles: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      minHeight: 300,
      maxHeight: 750,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      zIndex: 99,
    },
  },
  container: {},
  body: {
    maxHeight: 350,
    paddingRight: 10,
    marginRight: -10,
    overflowY: 'auto',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 15,
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
};

class VariantModal extends Component {
  state = {
    selectedVariantIndex: null,
  };

  componentDidUpdate(prevProps) {
    // on close
    if (!this.props.isOpen && prevProps.isOpen) {
      this.setState({ selectedVariantIndex: null });
    }
  }

  inputHandler = e => {
    this.setState({ lot: e.target.value });
  };

  handleVariant = (decodedItem, index) => {
    this.setState({ selectedVariantIndex: index });
    this.props.updateVariant(index);

    const updated = {
      year: decodedItem.year || '',
      make: decodedItem.make || '',
      model: decodedItem.model || '',
      trim:
        decodedItem.trim && decodedItem.trim !== 'Unknown'
          ? decodedItem.trim
          : '',
    };

    if (decodedItem.drives.length <= 2) {
      updated.driveline = decodedItem.drives[0];
    }
    if (decodedItem.engines.length <= 2) {
      updated.engine = decodedItem.engines[0];
    }
    if (decodedItem.transmissions.length <= 2) {
      updated.transmission = decodedItem.transmissions[0];
    }
    if (decodedItem.bodyStyles.length <= 2) {
      const bodyStyle = decodedItem.bodyStyles[0];
      updated.bodyStyle = bodyStyle;
      // if vehicle exists respect the current value
      // if new vehicle decide by the body style
      let isHeavyTruck = decodedItem.heavyTruckOption;
      if (isHeavyTruck === undefined) {
        isHeavyTruck = HEAVY_TRUCK_BODYSTYLES.includes(bodyStyle);
      }

      updated.heavyTruckOption = isHeavyTruck;
      updated.Heavytrk = isHeavyTruck;
    }

    // NOTE: - get-auto-announcement api is called when dealer is first selected.
    // but we can only put the announcements on here when we know the variant
    // pk value used to determine if vehicle is new or existing
    if (this.props.item && !this.props.item.pk) {
      if (!isEmpty(this.props.autoAnnouncements)) {
        // pk value used to determine if vehicle is new or existing
        const announcements = (this.props.autoAnnouncements.announce || '')
          .split(',')
          .map(key => {
            const obj = find(decodedItem.announcements, { key });
            return obj ? obj.value : null;
          })
          .filter(announcement => announcement);

        updated.announcements = announcements;
        updated.light = this.props.autoAnnouncements.lights;
        updated.blockAnnouncements = this.props.autoAnnouncements.annspc;
      }
    }

    this.props.updateItem(updated);
  };

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-10">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>

        <div className="pl-4 fs-20 font-semibold" style={{ color: BLACK_02 }}>
          Trim
        </div>

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderYMM() {
    const { vin } = this.props;

    if (vin && vin.decodedItems && vin.decodedItems.length) {
      const decodedItem = vin.decodedItems[0];
      return (
        <div
          className="text-base font-semibold mb-6"
          style={{ color: BLACK_02 }}
        >
          {`${decodedItem.year} ${decodedItem.make} ${decodedItem.model}`}
        </div>
      );
    }
  }

  renderVariants() {
    const { vin, variant } = this.props;
    const { selectedVariantIndex } = this.state;

    if (vin) {
      return (vin.decodedItems || [])
        .filter(item => item.trim !== '')
        .map((decodedItem, index) => {
          const isCurrentVariant =
            selectedVariantIndex !== null
              ? selectedVariantIndex === index
              : variant.index === index;

          return (
            <div
              key={index}
              className="flex justify-between px-6 border-cyan py-3 font-normal"
              style={{
                height: 50,
                marginBottom: 20,
                color: BLACK_03,
                cursor: 'pointer',
              }}
              onClick={() => this.handleVariant(decodedItem, index)}
            >
              <div>{decodedItem.trim}</div>
              {isCurrentVariant && (
                <div className="flex justify-center items-center p-2">
                  <Icon name="Checkmark" size={17} />
                </div>
              )}
            </div>
          );
        });
    }
  }

  renderFooter() {
    const nextDisabled =
      this.props.isLoading || this.props.variant.index === undefined;

    return (
      <div style={styles.footer}>
        <div style={{ marginBottom: 15 }}>
          <Button
            btnText="Next"
            outlined={nextDisabled}
            outlinedTextColor={WHITE_01}
            outlinedBgColor={GREY_04}
            outlinedBorderColor={GREY_04}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onNext}
            disabled={nextDisabled}
          />
        </div>
        <div>
          <Button
            btnText="Cancel"
            outlined={true}
            outlinedTextColor={BLACK_03}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onClose}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, isLoading } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        style={styles.customStyles}
        contentLabel={'variant'}
        ariaHideApp={false}
      >
        <div className="landing-page-modal-width" style={styles.container}>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              {this.renderHeader()}
              {this.renderYMM()}
              <div style={styles.body}>{this.renderVariants()}</div>
              {this.renderFooter()}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

VariantModal.propTypes = {
  updateItem: PropTypes.func.isRequired,
  updateVin: PropTypes.func.isRequired,
  updateVariant: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const { vin, variant, autoAnnouncements } = state.core;
  return { vin, variant, autoAnnouncements };
};

export default connect(mapStateToProps, {
  updateItem,
  updateVin,
  updateVariant,
})(VariantModal);
