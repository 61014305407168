import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import moment from 'moment-timezone';
import jstz from 'jstz';
import reframe from 'reframe.js';
import { isEmpty, find, get, some } from 'lodash';
import xml2js from 'xml2js';
import { toastr } from 'react-redux-toastr';
import { StickyContainer } from 'react-sticky';
import QRCode from 'qrcode.react';
import axios from 'axios';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import {
  loadAddWatchList,
  loadBidHistory,
  loadBuyerPurchased,
  loadCarfaxLoginCheck,
  loadCarfaxReport,
  loadExtOfferUpdate,
  loadItem,
  loadItemUpdateOffer,
  loadMarketplaces,
  loadParentItem,
  loadRemoveWatchList,
  loadSpinCar,
  loadWatchlistES,
  loadWatchlistObject,
  loadWatchlistUserItems,
  resetBidHistory,
  resetBidMessageHistoryRealTime,
  resetCarfaxCode,
  resetCarfaxReport,
  resetItem,
  resetOffer,
  resetParentItem,
  retailViewOff,
  retailViewOn,
} from '../actions';
import {
  commafy,
  commafyCurrency,
  compose,
  determineCountdownInfo,
  findMileageUnits,
  getAttributeLabelAndValue,
  getBuyNowText,
  getConfig,
  getTemplate,
  isDST,
  launchWindow,
  makeAssetLink,
  mergeBidHistory,
  removeProtocol,
  showBidButton,
  showBuyNowButton,
  showOfferButton,
} from '../utils/helpers';
import withRouter from '../hocs/withRouter';
import { localization } from '../utils/localization';
import {
  bulkLotSliderSettings,
  dataPlaceholder,
  mp3rdPartyIntegratorButtonsMap,
  mpDetailsPageButtonsMap,
  mpDetailsPageFieldsMap,
  mpDetailsPageSectionsMap,
  SST_FIRST_OFFER,
} from '../utils/constants';
import $ from 'jquery';
import AddNoteModal from './common/AddNoteModal';
import Announcements from './vehicleDetails/Announcements';
import AutoniqModal from './vehicleDetails/AutoniqModal';
import BidModal from './BidModal';
import BuyModal from './BuyModal';
import ConditionReport from './ConditionReport';
import ConditionReportConsolidated from './ConditionReportConsolidated.js';
import Lights from './Lights';
import MMRModal from './vehicleDetails/MMRModal';
import OfferModal from './OfferModal';
import { confirm } from '../components/common/confirm/CallConfirm';
import ExtOfferModal from './ExtOfferModal';
import Slider from 'react-slick';
import BulkLotBidModal from './BulkLotBidModal';
import VehicleDetailsVehicleCard from './VehicleDetailsVehicleCard';
import carfaxImg from '../assets/images/carfax.svg';
import autoniqImg from '../assets/images/autoniq.png';
import autocheckImg from '../assets/images/autocheck.png';
import accutradeImg from '../assets/images/accu-trade.png';
import carblyImg from '../assets/images/carbly-logo.png';
import { TransportationQuote, TransportationProviderSelection, TransportationModal } from './transportation';
import { aaRequiredToaster } from './auctionAccess/aaRequiredToaster.js';
import { StratosLoaderData, VehicleValuesButton } from './common/vehicleValues';
import { addStratosLoaderScript, postStratosMessage } from '../utils/widgets/stratosLoader.js';
import { transportationMpFeatures } from './transportation/TransporationConstants.js';
import TransportationQuotes from './transportation/TransportationQuotes.js';

const mpDetailsMobileSectionsFallback = '8,12,10,9,7,2,11,4,3,5,6';
const mpDetailsSectionsFallback = '8,9,7,2,11,4,12,10,3,5,6';
const mpDetailsFieldsFallback = '13,11,6,5,2,3,4,7,1,12,15,16,8';
const mpDetailsButtonsFallback = '2,1,5,7,3,4,8';
const mp3rdPartyIntegratorButtonsFallback = '2,3';
const timezone = jstz.determine().name();
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#fafafa',
    zIndex: '10',
    flex: 1,
    flexWrap: 'wrap',
    margin: 0,
    padding: 15,
  },
  left: {
    flexDirection: 'column',
    flex: 2,
    width: '40%',
    marginRight: 5,
  },
  right: {
    flexDirection: 'column',
    flex: 3,
    width: '60%',
    marginTop: 0,
  },
  section: {
    border: '1px solid #ccc',
    padding: 15,
    marginBottom: 15,
    backgroundColor: '#fff',
    borderRadius: 3,
  },
  header: {
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 15,
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bolder',
    paddingLeft: 15,
    marginBottom: 10,
    width: '100%',
    zIndex: 10,
  },
  YMMT: {
    fontSize: 30,
    paddingTop: 5,
    paddingBottom: 5,
  },
  bodyStyle: {
    fontSize: 20,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 'normal',
  },
  infoContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoLeft: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    marginRight: 15,
  },
  infoRight: {
    marginLeft: 15,
    marginRight: 15,
  },
  labelAndValue: {
    fontSize: 16,
    margin: 3,
  },
  label: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  value: {},
  barcode: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingLeft: 20,
    paddingRight: 15,
  },
  infoButton: {
    color: 'white',
    marginTop: 5,
    marginBottom: 5,
    width: 'auto',
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
  },
  accutradeItem: {
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  accutradeLabel: {
    fontSize: '1rem',
    color: 'black',
    fontWeight: 'bold',
  },
  accutradeValue: {
    fontSize: '1.75rem',
  },
  historyItemImage: {
    height: 40,
    border: '1px solid #ccc',
    borderRadius: 3,
    cursor: 'pointer',
    backgroundColor: '#fff',
    margin: 5,
  },
  offer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 3,
    padding: 10,
    margin: 0,
    marginBottom: 10,
    backgroundColor: '#F5F5F5',
  },
  offerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    marginRight: 5,
  },
  offerButtonGroup: {
    marginLeft: 15,
  },
  offerButton: {
    color: 'white',
    marginTop: 3,
    marginBottom: 3,
    width: 100,
  },
  countdownTimerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 60,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  countdownTimerBlock: {
    display: 'flex',
    alignItems: 'center',
    color: '#fffefe',
    fontSize: 24,
  },
  spinCarContainer: {
    marginBottom: 15,
    backgroundColor: '#fff',
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'hidden',
    width: '100%',
    borderRadius: 0,
    position: 'relative',
    border: 'none',
  },
  spinCarFrame: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: '1px solid #ccc',
    outline: 'none',
    frameBorder: 0,
    frameborder: 0,
    padding: 0,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  row: {
    marginRight: '-15px',
    marginLeft: '-15px',
  },
  bulkLotContainer: {
    display: 'flex',
    justifyContent: 'space-apart',
  },
  bulkLotCarouselContainer: {
    display: 'flex',
    justifyContent: 'space-apart',
    width: '80%',
  },
  bulkLotSlider: {
    position: 'relative',
    width: '100%',
    padding: '1em',
  },
  bulkLotDetailsContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bulkLotDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 5,
    marginLeft: 15,
    marginRight: 15,
  },
  bulkLotDetailItem: {
    marginTop: 5,
    marginBottom: 5,
  },
  transportationInput: {
    backgroundClip: 'padding-box',
    backgroundColor: '#fff',
    border: '1px solid #a9a9a9',
    borderRadius: 4,
    fontSize: 12,
    maxWidth: '100%',
    padding: '3px 6px',
    marginTop: 0,
    verticalAlign: 'middle',
    width: 100,
    display: 'block',
    height: 40,
  },
  transportationQuoteButton: {
    borderRadius: 4,
    width: 'auto',
    color: 'white',
    backgroundColor: 'black',
    height: 40,
  },
  transportationQuoteContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 15,
    paddingLeft: 10,
  },
};

/* TODO: 
  - Convert to functional component 
    - Wrap 3rd party script(s) in hook that loads and removes element
  - Split out multiple render* methods into single, functional components
    - Not only simplifies state, but unmounts component on state change (no lazy artifacts when navigating)
  
   UPDATE:
    2025.03 FIX - Prevent vehicle values (when enabled) button / meta data from loading until item data
                  is completely loaded.
*/
class VehicleDetails extends Component {
  state = {
    autoniqUrl: '',
    countdownInfo: {},
    galleryImageHeight: 480, // dynamic
    galleryWidth: 1, // dynamic
    hideBidButton: false,
    images: '',
    index: 0,
    isMobileDevice: false,
    isSubmittingCounterOffer: false,
    notes: '',
    offerAmount: 0,
    offerId: 0,
    offerStatus: 0,
    offerType: '',
    requiredAmount: 0,
    spinUrl: null,
    watchlistButtonDisabled: false,
    mpDetailsPageSections: [],
    mpDetailsPageFields: [],
    mpDetailsPageButtons: [],
    mp3rdPartyIntegratorButtons: [],
    aipLoaded: false,
    aipError: false,
    deeplink: null,
    hasChange: false,
    bulkLotItems: [],
    /* ui */
    slideIndex: 0,
    view: 'wholesale',
    windowWidth: window.innerWidth,
    /* transportation */
    destinationZip: '',
    enabledTransportProviders: null,
    transportationModalMode: null,
    transportationSelectedQuote: null,
    /* modals */
    isBidModalOpen: false,
    isOfferModalOpen: false,
    isBuyNowModalOpen: false,
    isNotesModalOpen: false,
    isMMRModalOpen: false,
    isAutoniqModalOpen: false,
    isBulkBidModalOpen: false,
    isTransportationModalOpen: false,
    /* vehicle values */
    isVehicleDataLoaded: false,
    isVehicleValuesInit: false,
    /* error */
    isErrorState: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.loadData();
    window.scrollTo(0, 0);
    reframe(document.querySelectorAll("#vdp-section-iframe"));
    if (!this.timer) {
      this.setTimer();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.id !== this.props.params.id) {
      this.loadData();
    }

    if (
      !prevProps.offer.wsStatus &&
      this.props.offer.wsStatus &&
      this.state.isSubmittingCounterOffer
    ) {
      this.setState({ isSubmittingCounterOffer: false }, () => {
        toastr.success('Success', this.props.offer.wsMessage);
        this.loadData();
      });
    }

    // NOTE - can this be moved into this.loadData after loadItem has returned?
    if (getConfig('enableAutoIPacket')) {
      this.loadAutoIPacket();
    }

    this.setGalleryWidth();

    if (
      this.props.bidMessagesRealTime[this.props.params.id] !==
      prevProps.bidMessagesRealTime[this.props.params.id]
    ) {
      this.setState({ hasChange: true }, () => {
        setTimeout(() => {
          this.setState({ hasChange: false });
        }, 750);
      });
    }

    this.checkForReconnect(prevProps);
    this.initDarwinCta();
    // this.initDarwinCtaScript();
  }

  componentWillUnmount() {
    this.props.resetItem();
    this.props.retailViewOff();
    this.props.resetCarfaxReport();
    this.props.resetBidHistory();
    this.props.resetBidMessageHistoryRealTime();
    this.props.resetParentItem();
    clearInterval(this.timer);
    window.removeEventListener('resize', this.handleResize);
    // this.removeDarwinCtaScript();
  }

  componentDidCatch(vdpError) {
    console.error(vdpError?.message || vdpError);
    this.setState({isErrorState: true})
  }

  // HELPERS and HANDLERS
  checkForReconnect(prevProps) {
    if (
      (prevProps.onlineConnected === false &&
        this.props.onlineConnected === true) ||
      (prevProps.realTimeConnected === false &&
        this.props.realTimeConnected === true)
    ) {
      this.loadData();
    }
  }

  setTimer = () => {
    const mpId = getConfig('marketplaceId');
    this.timer = setInterval(() => {
      const itemId = this.props.params.id;
      const itemData = {
        ...this.props.item.item,
        ...this.props.bidMessagesRealTime[itemId],
      };
      const countdownInfo = determineCountdownInfo(itemData);

      if (
        !this.state.countdownInfo ||
        this.state.countdownInfo.time !== countdownInfo.time ||
        this.state.countdownInfo.eventStatus !== countdownInfo.eventStatus
      ) {
        this.setState({ countdownInfo });
      }

      if (moment().seconds() === 10) {
        this.props.loadItem(mpId, itemId);
        this.props.loadBidHistory(mpId, itemId).then(({ response }) => {
          if (response.wsStatus === 'Success') {
            this.props.resetBidMessageHistoryRealTime();
          }
        });
      }
    }, 1000);
  };

  loadData = () => {
    const mpId = getConfig('marketplaceId');
    const features = (this.props.marketplaceFeatures.features || '').split(',');
    const itemId = this.props.params.id;

    this.props
      .loadItem(mpId, itemId)
      .then(({ response }) => {
        if (!response.item.itemId) {
          throw new Error();
        }

        if (this.props.carfaxCode) {
          this.handleCarfaxLoginReturn(); // when returning from carfax
          this.props.resetCarfaxCode();
        }

        if (features.includes('546')) {
          this.loadSpinCar();
        }

        this.loadNotes(response.userNotes);

        if (response.item.subItemDetails.length) {
          // user loaded a bulk lot parent listing
          this.loadBulkLot(response.item);
        } else if (response.item.parentListingId) {
          // user loaded a bulk lot sub-listing
          this.props
            .loadParentItem(mpId, response.item.parentListingId)
            .then(({ response }) => {
              if (response.wsStatus === 'Success') {
                this.loadBulkLot(response.item);
                this.loadNotes(response.userNotes);
              }
            });
        } else {
          this.setState({
            bulkLotItems: [],
          });
        }
      })
      .catch(err => {
        toastr.error('Error', `Item ${itemId} unable to load`);
        console.error(err);
      });

    this.props.loadBidHistory(mpId, itemId).then(({ response }) => {
      if (response.wsStatus === 'Success') {
        this.props.resetBidMessageHistoryRealTime();
      }
    });
    this.props.loadBuyerPurchased(mpId);
    this.props
      .loadMarketplaces(mpId)
      .then(this.loadMarketplaceSettings)
      .catch(error => console.error(error));
    this.props.resetOffer();
    this.loadDetail360();
    this.loadAutoIPacket();
    if (!this.state.isVehicleDataLoaded && getConfig('stratosConfig')) {
      this.setState({isVehicleDataLoaded: true});
    }
  };

  loadBulkLot = parentItem => {
    const dynamicFields = {};
    if (getConfig('enableDynamicAttributes') && parentItem.attributes) {
      parentItem.attributes
        .filter(attribute => attribute.inDetail)
        .forEach(attribute => {
          dynamicFields[attribute.attributeName.toLowerCase()] =
            attribute.valueObject.value;
        });
    }

    const bulkLotItems = [
      {
        listingId: parentItem.itemId,
        cardTitle: [
          parentItem.year || '',
          parentItem.make || '',
          parentItem.model || '',
          parentItem.trim || '',
        ].join(' '),
        location: [parentItem.vehicleCity, parentItem.vehicleState].join(', '),
        imageUrl: parentItem.imageUrls[0],
        mileage: parentItem.odometer,
        seller: parentItem.sellerName,
        color: parentItem.extColor,
        ...parentItem,
        ...dynamicFields,
      },
      ...parentItem.subItemDetails,
    ];

    this.setState({ bulkLotItems });
  };

  /* TODO:  replace with item.eventDescription when available */
  /* HOTFIX 2024.10.4 - try/catch block and validate matched event */
  getEventDescription = ({eventId, eventName}) => {
    try {
      const {eventList=[]} = this.props.events;
      const matchedEvent = eventList.find(evt => (evt.eventId === eventId && evt.eventName === eventName));
      
      if (matchedEvent) {
        const {description} = matchedEvent;
        return description;

      } else {
        return;
      }

    } catch (eventDescParseErr) {
      return;
    }
  }

  loadNotes = (notes = '') => {
    this.setState({ notes });
  };

  loadAutoIPacket() {
    const vin = get(this.props.item.item, 'vin');
    const aipVin = get(window, 'autoipacketSettings.mqv_vin');
    const newAIPVin = aipVin !== vin;
    const element = document.getElementById('aipModules');

    if (getConfig('enableAutoIPacket') && vin && newAIPVin && element) {
      window.autoipacketSettings = {
        mqv_vin: vin,
        mqv_element_id: 'aipModules',
        mqv_api_key: 'b057f14e-09ae-4467-9919-7f55d39ab3d3', // prod
        mqv_modal_enabled: true,
        mqv_styles_enabled: true,
        mqv_on_error: () => {
          element.style.margin = 0;
        },
      };

      const script = document.createElement('script');
      script.src =
        'https://s3.amazonaws.com/js.autoipacket.com/moduleQuickViewES5.min.js';
      script.type = 'text/javascript';
      script.async = true;
      element.appendChild(script);
    }
  }
  
  /**
   * **New 2025.04** Init stratos scan with "message" call
   * - Drives MP-specific call-to-action button in vehicle elements
   * - Initial script init is handled in `TopNav.js`
   */
  initDarwinCta () {
    const stratosConfig = getConfig("stratosConfig");
    const {isVehicleValuesInit, isVehicleDataLoaded} = this.state;
    
    if (!stratosConfig) return;
    if (isVehicleValuesInit) return;
  
    const darwinDataClassName = ".darwinVehicleData"
    const buttonClassName = ".darwinVehicleButton"

    try {
      const existingDataElement = document.querySelector(darwinDataClassName);
      const existingButtonElement = document.querySelector(buttonClassName); 
      if (stratosConfig && existingDataElement && existingButtonElement && isVehicleDataLoaded) {
        this.setState(
          {isVehicleValuesInit: true}, 
          () => postStratosMessage(stratosConfig)
        );
      }
    } catch (stratosInitError) {
      console.error(stratosInitError);
    }
  }
  /**
   * ~**2024.10.11** - Added~ **2025.01** - 
   * 
   * **2025.04.02** - _Update: replaced w/ `initDarwinCta()`_
   * - Appends to `<body>` element
   *   - _Not_ appended if `stratosConfig` is excluded in MP config
   * - Drives MP-specific call-to-action button in vehicle elements
   * 
   */
    initDarwinCtaScript () {
      const stratosConfig = getConfig("stratosConfig");
      const elementId = "StratosLoader"
      const darwinDataClassName = ".darwinVehicleData"
      const buttonClassName = ".darwinVehicleButton"
      try {
        const existingStratosElement = document.getElementById(elementId);
        const existingDataElement = document.querySelector(darwinDataClassName);
        const existingButtonElement = document.querySelector(buttonClassName);

        if (existingStratosElement) {
          return; 

        } else if (existingDataElement && existingButtonElement) {
          addStratosLoaderScript(stratosConfig);
        }
      } catch (stratosLoaderError) {
        console.error(stratosLoaderError);
      }
    }
  
  /**
   * @description Clean up StratosLoader `<script>` tag from DOM
   * @returns {void}
   */
  removeDarwinCtaScript = () => {
    const stratosConfig = getConfig('stratosConfig');
    if (stratosConfig) {
      const elementId = "StratosLoader"
      try {
        const existingStratosElement = document.getElementById(elementId);
        const existingPlaceholderElement = document.getElementById('StratosPlaceHolder')

        /* Remove existing <script> element for StratosLoader */
        if (existingStratosElement) {
          // init() may be called more than once
          document.body.removeChild(existingStratosElement);
        }
        /* Remove existing placeholder element created by Stratos */
        if (existingPlaceholderElement) {
          document.body.removeChild(existingPlaceholderElement);
        }
        /* Remove style and script tag from html.head */
        const existingStyleElements = [...document.head.querySelectorAll('style').values()];
        const existingScriptHeadElements = [...document.head.querySelectorAll('script').values()];
        if (existingStyleElements) {
          existingStyleElements
            .filter(el => el.innerHTML?.match('.StratosLaunchButtonHidden'))
            .forEach(el => document.head.removeChild(el))
        }
        if (existingScriptHeadElements) {
          existingScriptHeadElements
            .filter(el => el.src?.match(/^(https?:\/\/)?Exos\.azureedge/i))
            .forEach(el => document.head.removeChild(el))
        }

      } catch (stratosElementError) {
        console.error(stratosElementError?.message || stratosElementError);
      }
    }
  }

  loadDetail360 = () => {
    if (getConfig('enableDetail360')) {
      fetch(
        'https://carvana.car360app.com/captures-index/993c57d9-6e72-41e5-98cb-b2d2c504cb80/vin/TST1/index.json'
      )
        .then(response => {
          if (response.status !== 403 && response.status !== 404) {
            setTimeout(() => {
              document.getElementById('galleryContainer').innerHTML = '';
              const script = document.createElement('script');
              script.setAttribute('id', 'car360viewer');
              script.setAttribute('data-vin', 'TST1');
              script.setAttribute(
                'data-site-id',
                '2c375b62-e15e-4110-b0d6-beb70b2aba35'
              );
              script.setAttribute(
                'src',
                'https://carvana.car360app.com/viewer/js/car360viewer.js'
              );
              script.setAttribute('imagebar', 'off');
              document.getElementById('galleryContainer').appendChild(script);
            }, 7100);
            return;
          }
        })
        .catch(error => console.error(error));
    }
  };

  loadSpinCar = () => {
    // this only gets called if spincar is enabled (feature 546)
    // it will check if there is a spin gallery for the vin.
    // if there is it will render the spin gallery.
    // if not it will render our normal gallery UNLESS demo is true then a demo gallery with vin 1gnukbe08ar115210 will be rendered

    const vin = get(this.props.item.item, 'vin');
    const useDemoFallback = false;

    this.props.loadSpinCar(vin).then(({ response }) => {
      if (response.status === 'ok') {
        this.setState({
          spinUrl: response.vehicle.url,
        });
      } else if (useDemoFallback) {
        this.setState({
          spinUrl: `https://spins.spincar.com/iasdemo/1gnukbe08ar115210`,
        });
      }
    });
  };

  loadMarketplaceSettings = ({ response }) => {
    const mpId = getConfig('marketplaceId');
    const isMobileDevice = window.matchMedia('(max-width: 650px)').matches;
    if (response.wsStatus === 'Success') {
      // we want the parent mp
      const marketplace = find(
        response.marketplaceList,
        mp => mp.marketplaceId == mpId
      );

      if (marketplace) {
        // determine the SECTIONS to show
        const awgDetailsSections =
          marketplace.mpDetailspageSections ||
          marketplace.mpDetailsPageSections;

        const mpDetailsPageSections = (
          isMobileDevice
            ? mpDetailsMobileSectionsFallback
            : (awgDetailsSections && awgDetailsSections !== '0')
              ? awgDetailsSections
              : mpDetailsSectionsFallback
        )
          .split(',')
          .map(key => {
            const val = mpDetailsPageSectionsMap.get(key.trim());
            if (!val && key !== '0') {
              console.error(
                `mpDetailsPageSections does not contain key ${key}`
              );
            }
            return val;
          });

        const optionsIdx = mpDetailsPageSections.indexOf('Options');
        if (optionsIdx > -1) {
          mpDetailsPageSections.splice(
            optionsIdx + 1,
            0,
            'AdditionalEquipment'
          );
        }

        mpDetailsPageSections.unshift('CountdownTimer'); // every mp gets this

        const eventDetailsIdx = mpDetailsPageSections.indexOf('EventDetails');
        if (eventDetailsIdx > -1 && getConfig('enableAutoIPacket')) {
          mpDetailsPageSections.splice(eventDetailsIdx, 0, 'AutoIPacket');
        }

        // determine the FIELDS in the details section
        const mpDetailsPageFields = (
          marketplace.mpDetailsPageFields &&
          marketplace.mpDetailsPageFields !== '0'
            ? marketplace.mpDetailsPageFields
            : mpDetailsFieldsFallback
        )
          .split(',')
          .map(key => {
            const val = mpDetailsPageFieldsMap.get(key.trim());
            if (!val && key !== '0') {
              console.error(`mpDetailsPageFields does not contain key ${key}`);
            }
            return val;
          })
          .filter(field => field);

        const extColorIdx = mpDetailsPageFields.indexOf('ExtColor');
        if (extColorIdx > -1) {
          mpDetailsPageFields.splice(extColorIdx, 0, 'FactColor');
        }

        const features = (this.props.marketplaceFeatures.features || '').split(
          ','
        );
        // 547 - Display RetailPrice only if:  feedPrice gt outrightPrice
        const retailPriceEnabled = features.includes('547');
        if (retailPriceEnabled) {
          let index = mpDetailsPageFields.indexOf('OutrightPrice');
          if (index < 0) index = mpDetailsPageFields.length;
          mpDetailsPageFields.splice(index + 1, 0, 'RetailPrice');
        }

        mpDetailsPageFields.push('Countdown'); // every customer gets this

        // determine the BUTTONS in the details section
        const mpDetailsPageButtons = (
          marketplace.mpDetailsPageButtons &&
          marketplace.mpDetailsPageButtons !== '0'
            ? marketplace.mpDetailsPageButtons
            : mpDetailsButtonsFallback
        )
          .split(',')
          .map(key => {
            const val = mpDetailsPageButtonsMap.get(key.trim());
            if (!val && key !== '0') {
              console.error(`mpDetailsPageButtons does not contain key ${key}`);
            }
            return val;
          });
        // determine the 3rd party vendors to show
        const mp3rdPartyIntegratorButtons = (
          marketplace.mp3rdPartyIntegratorButtons &&
          marketplace.mp3rdPartyIntegratorButtons !== '0'
            ? marketplace.mp3rdPartyIntegratorButtons
            : mp3rdPartyIntegratorButtonsFallback
        )
          .split(',')
          .map(key => {
            const val = mp3rdPartyIntegratorButtonsMap.get(key.trim());
            if (!val && key !== '0') {
              console.error(
                `mp3rdPartyIntegratorButtons does not contain key ${key}`
              );
            }
            return val;
        });
        // transportation providers
        const enabledTransportProviders = features
          .filter(feat => transportationMpFeatures[feat])
          .map(feat => transportationMpFeatures[feat]);
        this.setState({
          enabledTransportProviders,
          isMobileDevice,
          mpDetailsPageSections,
          mpDetailsPageFields,
          mpDetailsPageButtons,
          mp3rdPartyIntegratorButtons,
        });
      }
    }
  };

  handleView = () => {
    this.setState(
      { view: this.state.view === 'wholesale' ? 'retail' : 'wholesale' },
      () =>
        this.state.view === 'wholesale'
          ? this.props.retailViewOn()
          : this.props.retailViewOff()
    );
  };

  handleAccuTrade = () => {
    const url = 'https://www.accu-trade.com/';
    launchWindow(url);
  };

  handleCarbly = () => {
    const { itemId, vin, odometer, vehicleZip } = this.props.item.item;
    const url = `${getConfig('carblyConfig').baseUrl}&mileage=${odometer}&pvid=${itemId}&vin=${vin}&zip=${vehicleZip}`;
    window.open(url, '_blank');
  }

  handleAutoCheck = () => {
    const { vin } = this.props.item.item;
    const url = `http://www.autocheck.com/vehiclehistory/?vin=${vin}`;
    launchWindow(url);
  };

  handleAutoniq = () => {
    const { odometer, vin } = this.props.item.item;
    const mpId = getConfig('marketplaceId');
    const url =
      `//jwtaq.iasmarketplace.com/token` +
      `?sub=${localStorage.getItem('userId')}` +
      `&mpId=${mpId}`;

    fetch(url, { method: 'GET', mode: 'cors' })
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (response.ok && json.token) {
          const token = json.token;
          const timestamp = json.timestamp;

          const url =
            `//autoniq.com/mobile` +
            `?vin=${vin}` +
            `&mileage=${odometer}` +
            `&jwt=${token}#${timestamp}`;

          launchWindow(url);
        }
      })
      .catch(error => console.error(error));
  };

  handleCarfaxClick = () => {
    const mpId = getConfig('marketplaceId');

    this.props
      .loadCarfaxLoginCheck(mpId)
      .then(({ response }) => {
        if (response) {
          const { isLoggedIn, loginUrl } = response;
          if (isLoggedIn) {
            this.handleCarfaxReport();
          } else {
            confirm(
              'Select Login to proceed to CARFAX and enter your account credentials.',
              {
                okLabel: 'Login',
                title: 'CARFAX',
              }
            ).then(
              () => {
                const carfaxLoginUrl = loginUrl + window.location.origin;
                const carfaxRedirectPath = window.location.pathname;
                localStorage.setItem('carfaxRedirectPath', carfaxRedirectPath);
                window.open(carfaxLoginUrl, '_self');
                return null;
              },
              () => {
                return null;
              }
            );
          }
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'user_logged_in_check'));
  };

  handleCarfaxLoginReturn = () => {
    const apiRoot = getConfig('apiRoot');
    const apiKey = this.props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');
    const { carfaxCode } = this.props;
    const redirectUri = window.location.origin;

    const url =
      `${apiRoot}carfax/v2/login` +
      `?apiKey=${apiKey}` +
      `&mpId=${mpId}` +
      `&code=${carfaxCode}` +
      `&redirect_uri=${redirectUri}` +
      `&deviceId=54321`;

    axios({
      url,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // no response on success
        if (response.status === 204) {
          this.handleCarfaxReport();
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'login'));
  };

  handleCarfaxReport = () => {
    const mpId = getConfig('marketplaceId');
    const { vin } = this.props.item.item;

    this.props
      .loadCarfaxReport(mpId, vin)
      .then(({ response, error }) => {
        if (error) {
          this.handleCarfaxError(null, error);
        } else if (response) {
          window.open(response, '_blank');
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'url'));
  };

  handleCarfaxLogout = () => {
    const apiRoot = getConfig('apiRoot');
    const apiKey = this.props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');

    const url =
      `${apiRoot}carfax/v2/logout` +
      `?apiKey=${apiKey}` +
      `&mpId=${mpId}` +
      `&deviceId=54321`;

    axios({
      url,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 204) {
          window.open(`https://auth.carfax.com/v2/logout`, '_blank');
        } else {
          throw new Error();
        }
      })
      .catch(err => this.handleCarfaxError(err, 'logout'));
  };

  handleCarfaxError = (err, message) => {
    // the errs from awg are not very helpful
    toastr.error(
      `There was a problem with CARFAX \n ${message && '(' + message + ')'}`
    );
    console.error({ err, message });
  };

  handleWatchlistAction = (action, itemData) => {
    // call watchlist-add or watchlist-remove api
    this.setState({ watchlistButtonDisabled: true }, () => {
      const mpId = getConfig('marketplaceId');
      const message =
        action === 'add'
          ? 'Added to your watchlist'
          : 'Removed from your watchlist';

      const watchlistAction =
        action === 'add'
          ? this.props.loadAddWatchList
          : this.props.loadRemoveWatchList;

      watchlistAction(mpId, itemData.itemId)
        .then(({ response }) => {
          if (response.wsStatus === 'Success') {
            this.props.loadWatchlistObject(mpId);
            this.setState({ watchlistButtonDisabled: false }, () =>
              toastr.success('Success', message, { timeOut: 2000 })
            );
          } else {
            toastr.error('Error', response.wsMessage, { timeOut: 2000 });
          }
        })
        .catch(error => {
          toastr.error('Error');
          console.error(error, { timeOut: 2000 });
        });
    });
  };

  handleBack = e => {
    e.preventDefault();
    this.props.navigate(-1);
  };

  handleOfferSellerAccept = (offer, itemId) => {
    const mpId = getConfig('marketplaceId');
    confirm('Are you sure you want to accept this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const { offerId, bidAmount } = offer;
        const statusId = 8; // seller accepted

        this.setState({ isSubmittingCounterOffer: true }, () => {
          loadItemUpdateOffer(mpId, itemId, offerId, statusId, bidAmount);
        });
      },
      () => null
    );
  };

  handleOfferSellerCancel = (offer, itemId) => {
    const mpId = getConfig('marketplaceId');
    confirm('Are you sure you want to withdraw this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const { offerId, bidAmount } = offer;
        const statusId = 13; // seller cancelled

        this.setState({ isSubmittingCounterOffer: true }, () => {
          loadItemUpdateOffer(mpId, itemId, offerId, statusId, bidAmount);
        });
      },
      () => null
    );
  };

  handleOfferSellerDecline = (offer, itemId) => {
    const mpId = getConfig('marketplaceId');
    confirm('Are you sure you want to decline this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const { offerId, bidAmount } = offer;
        const statusId = 7; // seller rejected

        this.setState({ isSubmittingCounterOffer: true }, () => {
          loadItemUpdateOffer(mpId, itemId, offerId, statusId, bidAmount);
        });
      },
      () => null
    );
  };

  handleOfferBuyerAccept = (offer, itemId) => {
    const mpId = getConfig('marketplaceId');
    confirm(
      'Are you sure you want to accept this counter offer from seller?'
    ).then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const { offerId, bidAmount } = offer;
        const statusId = 12; // buyer accepted

        this.setState({ isSubmittingCounterOffer: true }, () => {
          loadItemUpdateOffer(mpId, itemId, offerId, statusId, bidAmount);
        });
      },
      () => null
    );
  };

  handleOfferBuyerDecline = (offer, itemId) => {
    const mpId = getConfig('marketplaceId');
    confirm('Are you sure you want to decline this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const statusId = 11; //buyer rejected
        const { offerId, bidAmount } = offer;

        this.setState({ isSubmittingCounterOffer: true }, () => {
          loadItemUpdateOffer(mpId, itemId, offerId, statusId, bidAmount);
        });
      },
      () => null
    );
  };

  handleOfferBuyerCancel = (offer, itemId) => {
    const mpId = getConfig('marketplaceId');
    confirm('Are you sure you want to cancel this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const { offerId, bidAmount } = offer;
        const statusId = 2; // buyer cancelled

        this.setState({ isSubmittingCounterOffer: true }, () => {
          loadItemUpdateOffer(mpId, itemId, offerId, statusId, bidAmount);
        });
      },
      () => null
    );
  };

  handleReserveItem = itemData => {
    window.alert('RESERVE ITEM (COMING SOON)');
    return null;
  };

  determineModalId(itemId) {
    return `detail${itemId || this.props.params.id}`;
  }

  /** Determines whether or not user needs to register for Auction Access
   *  to take action (buy, bid, offer) on an item.
   * 
   * @description Returns `true` if: 
   *  - `props.item.item.requiresAuctionAccess` is `true` 
   *  - `props.userProfile.user.hasAuctionAccess` is `false`
   * 
   * @returns {boolean}
   */
  isAuctionAccessNeeded = () => {
    try {
      const hasAuctionAccess = get(this.props.userProfile, 'user.hasAuctionAccess', false);
      const requiresAuctionAccess = get(this.props.item, 'item.requiresAuctionAccess');
      return Boolean(requiresAuctionAccess && !hasAuctionAccess);
    } catch (isAuctionAccessNeededError) {
      console.error(isAuctionAccessNeededError?.message || isAuctionAccessNeededError)
      return false;
    }
  }

  isOfferPending = offerStatusId => {
    return [1, 3, 5, 9, 10].includes(offerStatusId);
  };

  isSeller = () => {
    const accountList = get(this.props.userProfile, 'user.accountList');
    const sellerNumber = get(this.props.item, 'item.sellerNumber');
    return some(accountList, { accountNumber: sellerNumber });
  };

  isBidder = bidderId => {
    const accountList = get(this.props.userProfile, 'user.accountList');
    return some(accountList, { userId: bidderId });
  };

  findGrade = itemData => {
    // NOTE: - six possibile sources for cr rating??
    // get them all. return first truthy > 0 in order of preference. assume no conflicts.
    const crGradeValue =
      this.props.crGrades && this.props.crGrades[0]
        ? this.props.crGrades[0].value
        : null;
    const ratingDescriptionsRating =
      itemData.ratingDescriptions && itemData.ratingDescriptions[0]
        ? itemData.ratingDescriptions[0].rating
        : null;
    const grades = [
      itemData.crGrade,
      itemData.rating,
      itemData.ratingValue,
      itemData.conditionRating,
      crGradeValue,
      ratingDescriptionsRating,
    ].filter(grade =>
      !grade || grade === '0' || grade === '0.0' ? false : true
    );

    return grades[0];
  };

  setGalleryWidth = () => {
    if (!this.refs.imageGallery) return null;
    const galleryWidth = this.refs.imageGallery.offsetWidth;
    if (isNaN(galleryWidth)) return null;

    if (this.state.galleryWidth !== galleryWidth) {
      this.setState({
        galleryWidth,
      });
    }
  };

  // MODAL OPENERS


  openBidModal = (e, itemId, isUserNeedsAA=false) => {
    e.preventDefault();
    this.props.loadItem(getConfig('marketplaceId'), this.props.params.id);

    if (this.state.bulkLotItems.length) {
      this.setState({ isBulkBidModalOpen: true });
    } else if (isUserNeedsAA) {
      this.renderAuctionAccessRequired();
    } else {
      this.setState({ isBidModalOpen: true });
    }
  };

  openBuyModal = (e, itemId, isUserNeedsAA=false) => {
    e.preventDefault();
    this.props.loadItem(getConfig('marketplaceId'), this.props.params.id);
    if (isUserNeedsAA) {
      this.renderAuctionAccessRequired();
    } else {
      this.setState({ isBuyNowModalOpen: true });
    }
  };

  openOfferModal = (e, itemId, isUserNeedsAA=false) => {
    e.preventDefault();
    this.props.loadItem(getConfig('marketplaceId'), this.props.params.id);
    if (isUserNeedsAA) {
      this.renderAuctionAccessRequired();
    } else {
      this.setState({ offerType: 'Offer', isOfferModalOpen: true });
    }
  };

  openRaiseOfferModal = (offer, itemId) => {
    this.setState({
      offerType: 'Raise Offer',
      offerId: offer.offerId,
      offerAmount: offer.bidAmount,
      isOfferModalOpen: true,
    });
  };

  openCounterOfferModal = (offer, itemId, role) => {
    this.setState({
      offerId: offer.offerId,
      offerAmount: offer.bidAmount,
      requiredAmount: offer.requiredAmount,
      offerType:
        this.isSeller() && role == 'Seller'
          ? 'Seller Counter'
          : 'Buyer Counter',
      isOfferModalOpen: true,
    });
  };

  openAddNoteModal = (e, itemId) => {
    e.preventDefault();
    this.setState({ isNotesModalOpen: true });
  };

  openMMRModal = e => {
    e.preventDefault();
    this.setState({ isMMRModalOpen: true });
  };

  openAutoniqModal = e => {
    e.preventDefault();
    this.setState({ isAutoniqModalOpen: true });
  };

  // SECTIONS
  next = () => {
    this.refs.slider.slickNext();
  };

  previous = () => {
    this.refs.slider.slickPrev();
  };

  handleResize = () => {
    const isMobileDevice = window.matchMedia('(max-width: 650px)').matches;
    this.setState({ windowWidth: window.innerWidth, isMobileDevice });
  };

  determineBulkItemsToShow = windowWidth => {
    // coordinate this with bulkLotSliderSettings
    if (windowWidth < 700) {
      return 1;
    } else if (windowWidth < 1100) {
      return 1;
    } else if (windowWidth < 1350) {
      return 3;
    } else if (windowWidth < 1750) {
      return 4;
    } else if (windowWidth < 2000) {
      return 5;
    } else if (windowWidth < 3000) {
      return 6;
    }
  };

  renderAuctionAccessRequired = () => {
   aaRequiredToaster();
  }

  renderDarwinData = (itemData) => {
    const stratosConfig = getConfig('stratosConfig');
    const accountList = get(this.props.userProfile, 'user.accountList');
    
    if (stratosConfig && itemData && this.state.isVehicleDataLoaded) {
      return (
        <StratosLoaderData itemData={itemData} accountList={accountList} />
      )
    } else {
      return null;
    }
  }

  renderBulkVehicleCards() {
    const { windowWidth, bulkLotItems, slideIndex } = this.state;
    const bulkItemsCount = bulkLotItems.length;
    const parentItem = bulkLotItems[0];
    const bulkItemsToShow = this.determineBulkItemsToShow(windowWidth);
    const isSliderEnabled = bulkItemsCount > bulkItemsToShow;

    return (
      <div className='vdp_bulklot_container' >
        <div
          style={{
            ...styles.bulkLotCarouselContainer,
            // marginLeft: isSliderEnabled && 20,
            paddingLeft: isSliderEnabled && 10,
            paddingRight: isSliderEnabled && 10,
          }}
          className="bulk-carousel-container vdp_bulklot__carousel-container"
        >
          {/* padding 1em added because margin: 1em removed from class vehicle-card */}
          <div style={styles.bulkLotSlider}>
            <Slider
              ref="slider"
              {...bulkLotSliderSettings}
              afterChange={current => this.setState({ slideIndex: current })}
            >
              {bulkLotItems.map(item => {
                return (
                  <div
                    key={item.listingId}
                    className="detail-page-bulk-carousel"
                  >
                    <VehicleDetailsVehicleCard vehicleData={item} />
                  </div>
                );
              })}
            </Slider>

            {/* {isSliderEnabled && (
              <div>
                <div
                  className="slick-arrow slick-prev"
                  style={{ display: 'block' }}
                  onClick={this.previous}
                />
                <div
                  className="slick-arrow slick-next"
                  style={{ display: 'block', right: 1 }}
                  onClick={
                    slideIndex < bulkItemsCount - bulkItemsToShow && this.next
                  }
                />
              </div>
            )} */}
          </div>
        </div>

        <div className='vdp_bulklot__details-container'>
          <div style={styles.bulkLotDetails}>
            <div style={styles.bulkLotDetailItem}>
              <span style={{ fontWeight: '700' }}>Bulk Lot: </span>
              {bulkLotItems.length} Items
            </div>

            {parentItem.buyNow > 0 && (
              <div style={styles.bulkLotDetailItem}>
                <span style={{ fontWeight: '700' }}>Buy Now: </span>
                <span>{commafyCurrency(parentItem.buyNow) || 'N/A'}</span>
              </div>
            )}

            {parentItem.bulkLotDescription && (
              <div
                style={{
                  ...styles.bulkLotDetailItem,
                  marginBottom: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  width: 'auto',
                  textAlign: 'justify',
                }}
              >
                <span>{parentItem.bulkLotDescription}</span>
              </div>
            )}
          </div>

          {this.renderVehicleDetailsButtons(parentItem)}
        </div>
      </div>
    );
  }

  renderSectionsLeft(itemData) {
    const features = (this.props.marketplaceFeatures.features || '').split(',');
    const {isMobileDevice, mpDetailsPageSections: sections} = this.state;
    if (
      (features.includes('600') || features.includes('601') || features.includes('604')) &&
      // At this time Transportation is a marketplace feature not a mpDetailsPageSections
      !sections.includes('Transportation')
    ) {
      const vehicleDetailsGalleryIdx = sections.indexOf('Gallery');
      const vehicleDetailsOffersIdx = sections.indexOf('Offers');
      const vehicleDetailsSectionIdx = sections.indexOf('VehicleDetails')
      // Ensure 'Transportation' is after 'Offers' when 'Details' follows 'Gallery' (i.e. mobile view)
      if (vehicleDetailsGalleryIdx > -1) {
        // After Gallery
        let transportIndexPosition = vehicleDetailsGalleryIdx + 1;
        // Move up 1 if 'Details' is after Gallery
        if (vehicleDetailsGalleryIdx + 1 === vehicleDetailsSectionIdx) transportIndexPosition++;
        // Move up 1 if 'Offers' is after 'Details'
        if (vehicleDetailsSectionIdx + 1 === vehicleDetailsOffersIdx) transportIndexPosition++;
        
        sections.splice(transportIndexPosition, 0, 'Transportation');
      }
    }

    const renderedDetailsPageSections = this.state.mpDetailsPageSections.map(
      (category, index) => {
        switch (category) {
          case 'Announcements':
            return this.renderAnnouncements(itemData, index);
          case 'Booksheet':
            return this.renderBooksheetSection(itemData, index);
          case 'EventDetails':
            return this.renderEventDetailsSection(itemData, index);
          case 'Gallery':
            return this.renderImageGallerySection(itemData, index);
          case 'History':
            return this.renderHistorySection(itemData, index);
          case 'Options':
            return this.renderOptionsSection(itemData, index);
          case 'AdditionalEquipment':
            return this.renderAdditionalEquipmentSection(itemData, index);
          case 'AutoIPacket':
            return this.renderAutoIPacketSection(itemData, index);
          case 'Transportation':
            return this.renderTransportationSection(itemData, index);
          case 'VehicleDetails':
            return (isMobileDevice)
              ? this.renderVehicleDetailsSection(itemData, index)
              : null;
          case 'Offers':
            return isMobileDevice
              ? this.renderOffersSection(itemData, index)
              : null;
          default:
            break;
        }
        return null;
      }
    );

    return renderedDetailsPageSections;
  }

  renderSectionsRight(itemData) {
    const features = (this.props.marketplaceFeatures.features || '').split(',');
    // const sections = this.state.mpDetailsPageSections;
    const {isMobileDevice, mpDetailsPageSections: sections} = this.state;
    const renderedDetailsPageSections = sections.map((category, index) => {
      switch (category) {
        case 'AccuTrade':
          return this.renderAccutradeSection(itemData, index);
        case 'BidHistory':
          return this.renderBidHistorySection(itemData, index);
        case 'Condition': {
          const useConsolidatedConditionReport = features.includes('551');
          return useConsolidatedConditionReport
            ? this.renderConsolidatedConditionReport(itemData, index)
            : this.renderConditionSection(itemData, index);
        }
        case 'CountdownTimer':
          return this.renderCountdownTimer(index);
        case 'Diagnostic':
          return this.renderDiagnosticSection(itemData, index);
        case 'Offers':
          return isMobileDevice
            ? null
            : this.renderOffersSection(itemData, index);
        case 'VehicleDetails':
          return isMobileDevice 
            ? null
            :  this.renderVehicleDetailsSection(itemData, index);

        default:
          break;
      }
      return null;
    });

    return renderedDetailsPageSections;
  }

  renderImageGallerySection(itemData, key) {
    const { spinUrl } = this.state;

    if (spinUrl) {
      return this.renderSpinCar(key);
    }

    if (itemData.imageFullUrls.length) {
      return (
        <div
          ref={el => (this.imageGallery = el)}
          key={key}
          style={{
            ...styles.section,
            paddingTop: 0,
            paddingBottom: 0,
            overflow: 'hidden',
          }}
        >
          <ImageGallery
            ref={el => (this._imageGallery = el)}
            items={itemData.imageFullUrls.map((image, index) => ({
              original: image ? image.replace(/^http:\/\//i, 'https://') : '',
              thumbnail: itemData.imageUrls[index]
                ? itemData.imageUrls[index].replace(/^http:\/\//i, 'https://')
                : '',
            }))}
            onErrorImageURL={makeAssetLink('placeholder.png')}
            showThumbnails={true}
            showPlayButton={true}
            showNav={true}
            showFullscreenButton={true}
            slideInterval={2500}
            lazyLoad
          />
        </div>
      );
    } else {
      return (
        <img
          key={key}
          alt=""
          src={makeAssetLink('placeholder.png')}
          style={{ width: '100%' }}
        />
      );
    }
  }

  renderVehicleDetailsSection(itemData, key) {
    const isBulkLot = this.state.bulkLotItems.length > 0;
    const YMMT = [
      itemData.year || '',
      itemData.make || '',
      itemData.model || '',
      itemData.trim || '',
    ].join(' ');
    const bodyStyle = itemData.bodyType || '';

    return (
      <div id="vehicleDetails" key={key} width={795}>
        <div className='vdp_section vdp_section-vehicledetails-wrapper'>
          {/* HEADER */}
          <div className='vdp_section-header vdp_section-infoheader'>
            <div>
              <div className='vdp_section-header__ymmt'>{YMMT}</div>
              <div style={styles.bodyStyle}>{bodyStyle}</div>
              {this.renderHighOffer(itemData)}
              {this.renderNextBid(itemData)}
            </div>

            <div className="barcode" style={styles.barcode}>
              <div style={{ paddingRight: 20 }}>
                <Lights lights={itemData.lights} />
              </div>
              <div>{this.renderBarcode(itemData)}</div>
            </div>
          </div>

          <div className='vdp_section-infocontent-container'>
            {this.renderVehicleDetailsFields(itemData, key)}
            {!isBulkLot && this.renderVehicleDetailsButtons(itemData, key)}
          </div>
        </div>
      </div>
    );
  }

  renderTransportationSection(itemData, key) {
    const {enabledTransportProviders} = this.state;
    
    // Find matched purchase
    const vehicleData = { ...itemData };
    const matchedPurchasedItems = this.checkItemInPurchasedData(vehicleData.itemId, true);
    if (matchedPurchasedItems.length) {
      vehicleData.buyerNumber = matchedPurchasedItems[0].buyerNumber;
    }

    return (
        <div
          key={key}
          style={{
            ...styles.section,
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            paddingBottom: 30,
          }}
        >
        {/* HEADER */}
        <div style={{ ...styles.header, paddingBottom: 10 }}>
          <span className="fa fa-truck" style={styles.icon} />
          <span>Transportation</span>
        </div>
            <TransportationQuotes
              styles={styles}
              destinationZip={this.props.userProfile.user?.zipcode || ''}
              mpId={getConfig('marketplaceId')}
              itemData={vehicleData}
              providers={enabledTransportProviders}
              onSelect={((selection, selectionQuote) => {
                this.setState({
                  transportationModalMode: selection,
                  transportationSelectedQuote: selectionQuote,
                }, () => {
                  this.setState({
                    isTransportationModalOpen: true,
                  })
                })
              })}
            />

        </div>
    );
  }

  renderHistorySection(itemData, key) {
    // mp3rdPartyIntegratorButtons

    const list = this.state.mp3rdPartyIntegratorButtons
      .map((item, index, arr) => {
        let content = null;
        switch (item) {
          case 'Autoniq': {
            content = (
              <img
                alt="autoniq"
                src={autoniqImg}
                style={{
                  ...styles.historyItemImage,
                  border: 'none',
                }}
                onClick={this.handleAutoniq}
              />
            );
            break;
          }

          case 'AutoCheck': {
            content = (
              <img
                alt="autocheck"
                src={autocheckImg}
                style={{
                  ...styles.historyItemImage,
                  padding: 5,
                }}
                onClick={this.handleAutoCheck}
              />
            );
            break;
          }

          case 'CARFAX': {
            content = (
              <img
                alt="carfax"
                src={carfaxImg}
                style={{
                  ...styles.historyItemImage,
                  border: 'none',
                }}
                onClick={this.handleCarfaxClick}
              />
            );
            break;
          }

          case 'Accu-Trade': {
            content = (
              <img
                alt="accu-trade"
                src={accutradeImg}
                style={{
                  ...styles.historyItemImage,
                  padding: 5,
                }}
                onClick={this.handleAccuTrade}
              />
            );
            break;
          }

          case 'Carbly': {
            content = (
              <img
                alt="Carbly"
                src={carblyImg}
                style={{
                  ...styles.historyItemImage,
                  padding: 5,
                }}
                onClick={this.handleCarbly}
              />
            );
            break;
          }

          default:
            break;
        }

        return content ? <div key={index}>{content}</div> : null;
      })
      .filter(item => item);

    if (list.length) {
      return (
        <div
          className='vdp_section vdp_section-history_container'
          key={key}
        >
          {list}
        </div>
      );
    }
  }

  renderAutoIPacketSection(itemData, key) {
    return <div id="aipModules" key={key} style={{ marginBottom: 15 }} />;
  }

  renderEventDetailsSection(itemData, key) {
    return (
      <div
        key={key}
        style={{ ...styles.section, display: 'flex', flexDirection: 'column', whiteSpace: 'pre-line' }}
      >
        <div style={styles.header}>
          <span className="fa fa-calendar" style={styles.icon} />
          <span>Event Details</span>
        </div>

        <div style={{ margin: 0, paddingLeft: 10 }}>
          {this.renderSellerName(itemData)}
          {this.renderLocationInfo(itemData)}
          {this.renderEventName(itemData)}
          {this.renderFacilitatorInfo(itemData)}
          {this.renderEventDescription(itemData)}
        </div>
      </div>
    );
  }

  renderAnnouncements(itemData, key) {
    const description = get(itemData, 'description') || '';
    const announcements = get(itemData, 'announcements') || '';
    const blockAnnouncements = get(this.props.item, 'cr.crData.ba') || '';

    if (!description && !announcements && !blockAnnouncements) {
      return null;
    }

    return (
      <div
        key={key}
        style={{ ...styles.section, display: 'flex', flexDirection: 'column' }}
      >
        <div style={styles.header}>
          <span className="fa fa-bullhorn" style={styles.icon} />
          <span>Announcements</span>
        </div>

        <div style={{ margin: 0, paddingLeft: 10, paddingRight: 10 }}>
          <Announcements
            description={description}
            announcements={announcements}
            blockAnnouncements={blockAnnouncements}
          />
        </div>
      </div>
    );
  }

  renderBooksheetSection(itemData, key) {
    let showBooksheet;
    const { marketplaces } = this.props;
    let { marketplaceList = [] } = marketplaces;

    const mpId = getConfig('marketplaceId');
    const mp = find(marketplaceList, { marketplaceId: Number(mpId) });
    const mpFeatures = get(mp, 'marketplaceFeaturesArray', []);
    showBooksheet = mpFeatures.includes('517');

    if (!showBooksheet && mpId != itemData.marketplaceId) {
      const itemMp = find(marketplaceList, {
        marketplaceId: itemData.marketplaceId,
      });
      const itemMpFeatures = get(itemMp, 'marketplaceFeaturesArray', []);
      showBooksheet = itemMpFeatures.includes('517');
    }

    if (!showBooksheet) return null;

    const url =
      getConfig('booksheetUrl') +
      `?apiKey=${this.props.cookies.get('apiKey')}` +
      `&mpId=${mpId}` +
      `&itemId=${itemData.itemId}`;

    return (
      <div key={key} style={styles.section}>
        <div style={styles.header}>
          <span className="fa fa-book" style={styles.icon} />
          <span>Booksheet</span>
        </div>

        <div className="row">
          <div className="col-md-12">
            <iframe
              id="vdp-section-iframe"
              title="booksheet"
              className="cr-iframe"
              src={removeProtocol(url)}
              allowFullScreen
              style={{
                border: 'none',
                width: '98%',
                margin: 10,
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderOptionsSection(itemData, key) {
    let { options: items } = itemData;
    if (!items || items === 'N/A') return null;

    const text = 'Options';
    const icon = 'fas fa-list-ul';
    const splitBy = items.includes(',') ? ',' : '|';
    const stripped = items.replace(/,\s*$/, '');
    const arr = stripped
      .split(splitBy)
      .map(item => item.trim())
      .filter(Boolean);
    const half = Math.ceil(arr.length / 2);
    const list1 = arr.slice(0, half);
    const list2 = arr.slice(half);

    const renderList = items => (
      <ul >
        {items.map((item, index) => (
          <li
            key={index + item}
            style={{ listStylePosition: 'outside', wordBreak: 'break-word' }}
          >
            {item}
          </li>
        ))}
      </ul>
    );
 
    return (
      <div
        id='vdp-options'
        className='vdp_section'
        key={key}
      >
        <div className='vdp_section-header' >
          <span className={icon} style={styles.icon} />
          <span>{text}</span>
        </div>

        <div className='vdp_section-options_container'>
          {renderList(list1)}
          {renderList(list2)}
        </div>
      </div>
    );
  }

  renderAdditionalEquipmentSection(itemData, key) {
    let { additionalEquipment: items } = itemData;
    if (!items || items === 'N/A') return null;

    const text = 'Additional Equipment';
    const icon = 'fas fa-plus';
    const splitBy = items.includes(',') ? ',' : '|';
    const stripped = items.replace(/,\s*$/, '');
    const arr = stripped
      .split(splitBy)
      .map(item => item.trim())
      .filter(Boolean);
    const half = Math.ceil(arr.length / 2);
    const list1 = arr.slice(0, half);
    const list2 = arr.slice(half);

    const renderList = items => (
      <ul style={{ flexBasis: '50%', paddingLeft: 24, paddingRight: 10 }}>
        {items.map((item, index) => (
          <li
            key={index + item}
            style={{ listStylePosition: 'outside', wordBreak: 'break-word' }}
          >
            {item}
          </li>
        ))}
      </ul>
    );

    return (
      <div
        key={key}
        style={{ ...styles.section, display: 'flex', flexDirection: 'column' }}
      >
        <div style={styles.header}>
          <span className={icon} style={styles.icon} />
          <span>{text}</span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {renderList(list1)}
          {renderList(list2)}
        </div>
      </div>
    );
  }

  renderCountdownTimer(key) {
    if (this.state.countdownInfo.countdownType !== 'loud') {
      return null;
    }

    return (
      <div key={key} style={{ ...styles.section, padding: 0, border: 'none' }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div
            style={{
              ...styles.countdownTimerContainer,
              backgroundColor: getTemplate(
                this.props.template,
                'countdownTimer.backgroundColor'
              ),
            }}
          >
            <div
              className={
                this.state.countdownInfo.eventStatus === 'isInProgress24'
                  ? 'countdown-fill'
                  : ''
              }
            />
            <div style={styles.countdownTimerBlock}>
              {`${this.state.countdownInfo.label} ${this.state.countdownInfo.time}`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAccutradeSection(itemData, key) {
    const { accuTradePriceICO, accuTradePriceAuction, accuTradePriceRetail } =
      itemData;

    if (!accuTradePriceAuction && !accuTradePriceICO && !accuTradePriceRetail) {
      return null;
    }

    const accutradeInfo = [
      { 'Accu-Trade Instant Offer': accuTradePriceICO },
      { 'Target Auction': accuTradePriceAuction },
      { 'Target Retail': accuTradePriceRetail },
    ]
      .filter(item => {
        const value = Object.values(item)[0];
        return value;
      })
      .map((item, idx, arr) => {
        let [[label, value]] = Object.entries(item);
        value = commafyCurrency(value);

        return (
          <div
            key={label}
            style={{
              ...styles.accutradeItem,
              borderRight:
                idx === arr.length - 1 ? null : '1.5px solid LightGray',
            }}
          >
            <div style={styles.accutradeLabel}>{`${label}`}</div>
            <div style={styles.accutradeValue}>{value}</div>
          </div>
        );
      });

    return (
      <div
        key={key}
        style={{
          ...styles.section,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={styles.header}>
          <img
            alt=""
            src="/assets/images/accu-trade-logo-small.png"
            style={{
              ...styles.icon,
              width: 28,
              marginBottom: 5,
            }}
          />
          <span>Accu-Trade</span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {accutradeInfo}
        </div>
      </div>
    );
  }

  renderThirdPartyCrLink (extCrLink='') {
    if (extCrLink) {
      return (
        <div style={{width: '100%', padding: 10}}>
          <a href={extCrLink} rel='noreferrer' target='_blank' style={{margin: '0 auto'}}>
            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {"View 3rd Party CR"}
              <i className="fa fa-external-link-alt" style={{margin: '0 8px'}}/>
            </span>
          </a>
        </div>
      )
    }
  }

  renderConsolidatedConditionReport(itemData, key) {
    const link = `${window.location.origin}/condition?vehicleid=${itemData.itemId}`;
    const crType = this.props.item?.cr?.crData?.type;
    const extCrLink = (crType === '5')
      ? this.findCRLinkAndContent()?.link 
      : '';
    return (
      <div 
        className='vdp_section'
        key={key} 
        // style={{ ...styles.section }}
      >
        {extCrLink ? this.renderThirdPartyCrLink(extCrLink) : null}
        <div
          className='vdp_section-header'
          style={{ cursor: link ? 'pointer' : 'initial' }}
          // style={{ ...styles.header, cursor: link ? 'pointer' : 'initial' }}
          onClick={() => {
            if (link) window.open(link, '_blank');
          }}
        >
          <span className="fa fa-file" style={styles.icon} />
          <span>{extCrLink ? "Vehicle Information" : "Condition Report"}</span>
        </div>
        <ConditionReportConsolidated itemData={itemData} link={link} />
      </div>
    );
  }

  renderConditionSection(itemData, key) {
    let { content, link, crType } = this.findCRLinkAndContent();
    const grade = this.findGrade(itemData);

    if (!content) {
      /* 3rd party CR */
      if (crType === '5') {
        return (
          <div 
            className='vdp_section'
            key={key} 
          >
            <div
              className='vdp_section-header'
            >
              <span className="fa fa-file" style={styles.icon} />
              <span>Condition Report</span>
            </div>
            {this.renderThirdPartyCrLink(link)}
          </div>
        )
      
      } else {
        return null;
      }
    }

    return (
      <div
        key={key}
        ref={div => (this.viewCRHeader = div)}
        style={{ ...styles.section, padding: 0 }}
      >
        <div>
          <div
            style={{
              ...styles.header,
              cursor: link ? 'pointer' : 'initial',
            }}
            onClick={() => {
              if (link) window.open(link, '_blank');
            }}
          >
            <span className="fa fa-file" style={styles.icon} />
            <span>Condition Report</span>
            {grade && (
              <span style={{ marginRight: '15px', float: 'right' }}>
                Grade {grade}
              </span>
            )}
          </div>

          <div className="row">
            <div className="col-md-12">{content}</div>
          </div>
        </div>
      </div>
    );
  }

  renderDiagnosticSection(itemData, key) {
    if (!itemData.diagnosticLink) {
      return null;
    }

    return (
      <div key={key} style={{ ...styles.section, padding: 0 }}>
        <div
          style={{
            ...styles.header,
            cursor: 'pointer',
          }}
          onClick={() => window.open(itemData.diagnosticLink, '_blank')}
        >
          <span className="fa fa-file" style={styles.icon} />
          <span>Diagnostic</span>
        </div>
        <iframe
          title="diagnostic"
          id="vdp-section-iframe"
          src={itemData.diagnosticLink}
          style={{ width: '100%', minHeight: '800px', border: 'none' }}
        />
      </div>
    );
  }

  // vehicle detail fields
  renderVehicleDetailsFields(itemData, key) {
    let renderedFields = [];

    if (getConfig('enableDynamicAttributes') && itemData.attributes) {
      renderedFields = (itemData.attributes || [])
        .filter(attribute => attribute.inDetail)
        .map((attribute, index) => {
          return this.renderAttributeFields(attribute, index, dataPlaceholder);
        });
    } else {
      renderedFields = this.state.mpDetailsPageFields.map((field, index) => {
        // NOTE: - if a field value is empty the placeholder will be rendered
        // or pass in false to hide field altogether
        switch (field) {
          case 'CRGrade':
            return this.renderConditionGradeField(itemData, index, false);
          case 'DriveTrain':
            return this.renderDrivetrainField(itemData, index, dataPlaceholder);
          case 'Engine':
            return this.renderEngineField(itemData, index, dataPlaceholder);
          case 'FactColor':
            return this.renderFactoryColor(itemData, index, false);
          case 'ExtColor':
            return this.renderExtColorField(itemData, index, dataPlaceholder);
          case 'IntColor':
            return this.renderIntColorField(itemData, index, dataPlaceholder);
          case 'InteriorType':
            return this.renderIntTypeField(itemData, index, dataPlaceholder);
          case 'LicenseNumber':
            return this.renderLicenseNumberField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'LotNumber':
            return this.renderLotNumberField(itemData, index, dataPlaceholder);
          case 'Mileage':
            return this.renderMileageField(itemData, index, dataPlaceholder);
          case 'OutrightPrice':
            return this.renderOutrightPriceField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'RetailPrice':
            return this.renderRetailPriceField(itemData, index, false);
          case 'SleeperBunks':
            return this.renderSleeperBunksField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'SleeperSize':
            return this.renderSleeperSizeField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'SleeperType':
            return this.renderSleeperTypeField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'StockNumber':
            return this.renderStockNumberField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'Transmission':
            return this.renderTransmissionField(
              itemData,
              index,
              dataPlaceholder
            );
          case 'VIN':
            return this.renderVINField(itemData, index, dataPlaceholder);
          case 'Warranty':
            return this.renderWarrantyField(itemData, index, dataPlaceholder);
          case 'Countdown':
            return this.renderSubtleCountdownTimerField(itemData, index);
          case 'SerialNumber':
            return this.renderSerialNumberField(
              itemData,
              index,
              dataPlaceholder
            );
          default:
            break;
        }
        return null;
      });
    }

    return <div style={styles.infoLeft}>{renderedFields}</div>;
  }

  renderNextBid(itemData) {
    // Only display if:
    //  - positive-value 'nextValidBidAmount'
    //  - list type is a proxy auction or live auction
    if (!showBidButton(itemData)) return null;
    if (!itemData?.nextValidBidAmount || itemData?.nextValidBidAmount === '0') return null;
    if ([1, 3].includes(itemData?.listingTypeId)) {
      return (
        <div style={{ margin: 3, fontSize: 20, fontStyle: 'italic' }}>
          <span style={{ ...styles.label, color: '#787878' }}>
            Next Bid:
          </span>
          <span
            style={{
              ...styles.value,
              fontWeight: 'normal',
              color: getTemplate(this.props.template, 'misc.orange'),
            }}
          >
            {commafyCurrency(itemData.nextValidBidAmount)}
          </span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderHighOffer(itemData) {
    if (!itemData.highOffer || itemData.highOffer === '0') return null;

    return (
      <div style={{ margin: 3, fontSize: 20, fontStyle: 'italic' }}>
        <span style={{ ...styles.label, color: '#787878' }}>
          Current High Offer:
        </span>
        <span
          style={{
            ...styles.value,
            fontWeight: 'normal',
            color: getTemplate(this.props.template, 'misc.orange'),
          }}
        >
          {commafyCurrency(itemData.highOffer)}
        </span>
      </div>
    );
  }

  renderAttributeFields(attribute, key, placeholder) {
    const { label, value } = getAttributeLabelAndValue(attribute);

    return (
      <div key={key + attribute.attributeName} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value || placeholder}</span>
      </div>
    );
  }

  renderVINField(itemData, key, placeholder) {
    const label = 'VIN:';
    const value = itemData.vin || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderStockNumberField(itemData, key, placeholder) {
    const label =
      getConfig('localization') === 'en-uk' ? 'REG:' : 'Stock Number:';
    const value = itemData.stockNumber || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderLotNumberField(itemData, key, placeholder) {
    const label = 'Lot Number:';
    const value = itemData.lotNumber || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderLicenseNumberField(itemData, key, placeholder) {
    const label = 'CAP:';
    const value = itemData.licenseNumber || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderFactoryColor(itemData, key, placeholder) {
    const label = `Factory ${
      localization[getConfig('localization')].color || 'Color'
    }:`;
    const value = itemData.factoryColor || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderExtColorField(itemData, key, placeholder) {
    const label = `Exterior ${
      localization[getConfig('localization')].color || 'Color'
    }:`;
    const value = itemData.extColor || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderIntColorField(itemData, key, placeholder) {
    const label = `Interior ${
      localization[getConfig('localization')].color || 'Color'
    }:`;
    const value = itemData.intColor || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderIntTypeField(itemData, key, placeholder) {
    const label = 'Interior Type:';
    const value = itemData.intType || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderMileageField(itemData, key, placeholder) {
    const label = 'Odometer:';
    const value = `${commafy(itemData.odometer)}` || placeholder;
    const mileageUnits = findMileageUnits(itemData);
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{`${value} ${mileageUnits}`}</span>
      </div>
    );
  }

  renderEngineField(itemData, key, placeholder) {
    const label = 'Engine:';
    const value = itemData.engine || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderTransmissionField(itemData, key, placeholder) {
    const label = 'Transmission:';
    const value = itemData.transmission || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderDrivetrainField(itemData, key, placeholder) {
    const label = 'Drivetrain:';
    const value = itemData.driveType || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderConditionGradeField(itemData, key, placeholder) {
    const label = 'Condition Grade:';
    const value = this.findGrade(itemData) || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderOutrightPriceField(itemData, key, placeholder) {
    // const label = 'Buy Now:';
    const label = getBuyNowText(itemData);
    const value = Number(itemData.outrightPrice)
      ? commafyCurrency(itemData.outrightPrice)
      : placeholder;

    if (!value || !showBuyNowButton(itemData) || this.state.view === 'retail') {
      return null;
    }

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderRetailPriceField(itemData, key, placeholder) {
    const { feedPrice, statusId, isMPSellingLive, eventFeatures, outrightPrice } = itemData;
    const label = `Retail:`;
    const value = Number(feedPrice) ? commafyCurrency(feedPrice) : placeholder;
    const isViewOnlyEvent = String(eventFeatures).split(',').includes('17');
    
    if (!value || statusId !== 1 || isMPSellingLive || isViewOnlyEvent) {
      return null;
    }

    // 547 - Only show retail price if:  FeedPrice > OutrightPrice
    const features = (this.props.marketplaceFeatures.features || '').split(',')
    const isRetailPriceEnabled = features.includes('547');
    if (isRetailPriceEnabled) {
      const outrightPriceValue = (outrightPrice === undefined) ? 0 : Number(outrightPrice);
      const feedPriceValue = (feedPrice === undefined) ? 0 : Number(feedPrice);
      if (feedPriceValue <= outrightPriceValue) {
        return null;
      }
    }

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderSleeperSizeField(itemData, key, placeholder) {
    const label = `Sleeper Size:`;
    const value = itemData.cF03 || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderSleeperTypeField(itemData, key, placeholder) {
    const label = `Sleeper Type:`;
    const value = itemData.cF01 || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderSleeperBunksField(itemData, key, placeholder) {
    const label = `Sleeper Bunks:`;
    const value = itemData.cF02 || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderWarrantyField(itemData, key, placeholder) {
    const label = `Warranty:`;
    const value = itemData.warranty || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderReserveItemField(itemData, key, placeholder) {
    const label = `RESERVE ITEM:`;
    const value = 'COMING SOON' || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  renderSubtleCountdownTimerField(itemData, key) {
    if (this.state.countdownInfo.countdownType !== 'subtle') {
      return null;
    }

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{this.state.countdownInfo.label}</span>
        <span style={styles.value}>{this.state.countdownInfo.time}</span>
      </div>
    );
  }

  renderSerialNumberField(itemData, key, placeholder) {
    const label = 'Serial Number:';
    const value = itemData.vin || placeholder;
    if (value === false) return null;

    return (
      <div key={key} style={styles.labelAndValue}>
        <span style={styles.label}>{label}</span>
        <span style={styles.value}>{value}</span>
      </div>
    );
  }

  // vehicle detail buttons
  renderVehicleDetailsButtons(itemData, key) {
    const {isMobileDevice} = this.state;
    const renderedDetailsPageButtons = this.state.mpDetailsPageButtons.map(
      (button, index) => {
        switch (button) {
          case 'Bid':
            return this.renderBidButton(itemData, index);
          case 'Buy':
            return this.renderBuyNowButton(itemData, index);
          case 'Darwin':
            return this.renderDarwinButton(index);
          // MMR - 2024.10.14 -- deprecated; replaced with 'Darwin'
          case 'MMR':
            return this.renderMMRButton(itemData, index);
          case 'Notes':
            return this.renderNotesButton(itemData, index);
          case 'Offer':
            return this.renderOfferButton(itemData, index);
          case 'ReserveItem':
            return this.renderReserveItemButton(itemData, index);
          case 'Retail':
            return this.renderRetailButton(itemData, index);
          case 'Watchlist':
            return this.renderWatchlistButton(itemData, index);
          default:
            break;
        }
        return null;
      }
    );
    
    return (
      <div style={styles.infoRight}>
        <ButtonGroup size="medium" vertical={!isMobileDevice} className='vdp_section-vehicledetails-buttons'>
          {renderedDetailsPageButtons}
        </ButtonGroup>
      </div>
    );
  }

  renderBidButton(itemData, key) {
    if (!showBidButton(itemData)) {
      return null;
    }
    const userNeedsAuctionAccess = this.isAuctionAccessNeeded();
    return (
      <Button
        key={key}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsBidButton.backgroundColor'
          ),
        }}
        disabled={!this.props.userProfile.user}
        onClick={e => this.openBidModal(e, itemData.itemId, userNeedsAuctionAccess)}
      >
        Place Bid
      </Button>
    );
  }

  renderOfferButton(itemData, key) {
    if (!showOfferButton(itemData)) {
      return null;
    }

    const userNeedsAuctionAccess = this.isAuctionAccessNeeded();

    return (
      <Button
        key={key}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsOfferButton.backgroundColor'
          ),
        }}
        disabled={!this.props.userProfile.user}
        onClick={e => this.openOfferModal(e, itemData.itemId, userNeedsAuctionAccess)}
      >
        Place Offer
      </Button>
    );
  }

  renderBuyNowButton(itemData, key) {
    if (!showBuyNowButton(itemData)) {
      return null;
    }
    const label = getBuyNowText(itemData);
    const userNeedsAuctionAccess = this.isAuctionAccessNeeded();
    return (
      <Button
        key={key}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsBuyButton.backgroundColor'
          ),
        }}
        disabled={!this.props.userProfile.user}
        onClick={e => this.openBuyModal(e, itemData.itemI, userNeedsAuctionAccess)}
      >
        {label}
      </Button>
    );
  }
  /** Currently labeled 'MMR' in MPSettings.  _"Vehicle Values"_ button for Darwin Bookout integration.
   * 
   * @param {number|string} key
   * @returns 
   */
  renderDarwinButton = (key) => {
    const stratosConfig = getConfig('stratosConfig');
    if (stratosConfig && this.state.isVehicleDataLoaded) {
      return (<VehicleValuesButton key={key} />);

    } else {
      return null;
    }
  }
  
  renderWatchlistButton(itemData, key) {
    const isInWatchlist = !!this.props.watchlistObject[itemData.itemId];
    const action = isInWatchlist ? 'remove' : 'add';
    const watchlistColor = getTemplate(
      this.props.template,
      'detailsWatchlistButton.backgroundColor'
    );

    return (
      <Button
        key={key}
        style={{
          ...styles.infoButton,
          backgroundColor: isInWatchlist ? watchlistColor : 'transparent',
          color: isInWatchlist ? '#fff' : watchlistColor,
          border: `1px solid ${watchlistColor}`,
        }}
        disabled={this.state.watchlistButtonDisabled}
        onClick={() => this.handleWatchlistAction(action, itemData)}
      >
        <Icon
          name={isInWatchlist ? `check` : `add`}
          style={{ color: isInWatchlist ? 'white' : watchlistColor }}
        />
        <span>Watchlist</span>
      </Button>
    );
  }

  // MMR - 2024.10.14 -- deprecated; replaced with 'Darwin'
  renderMMRButton(itemData, key) {
    if (getConfig('localization') !== 'en-us') {
      return null;
    }

    return (
      <Button
        key={key}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsMMRButton.backgroundColor'
          ),
        }}
        onClick={this.openMMRModal}
      >
        MMR
      </Button>
    );
  }

  renderNotesButton(itemData, key) {
    return (
      <Button
        key={key}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsNotesButton.backgroundColor'
          ),
        }}
        onClick={e => this.openAddNoteModal(e, itemData.itemId)}
      >
        {this.state.notes ? 'Edit Note' : 'Add Note'}
      </Button>
    );
  }

  renderRetailButton(itemData, key) {
    return (
      <Button
        key={key}
        onClick={this.handleView}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsRetailButton.backgroundColor'
          ),
          color: getTemplate(this.props.template, 'detailsRetailButton.color'),
        }}
      >
        {this.state.view === 'wholesale' ? 'Retail' : 'Leave View'}
      </Button>
    );
  }

  renderReserveItemButton(itemData, key) {
    return (
      <Button
        key={key}
        onClick={() => this.handleReserveItem(itemData)}
        style={{
          ...styles.infoButton,
          backgroundColor: getTemplate(
            this.props.template,
            'detailsReserveItemButton.backgroundColor'
          ),
          color: getTemplate(
            this.props.template,
            'detailsReserveItemButton.color'
          ),
        }}
      >
        Reserve Item
      </Button>
    );
  }

  // TODO - 3.2024 - this can be it's own component
  renderSpinCar(key) {
    // const spinScriptSrc = `//integrator.swipetospin.com`; // not sure which to use
    const spinScriptSrc = `//integrator.swipetospin.com/v2.js`; // not sure which to use

    return (
      <div
        ref="imageGallery"
        id="spin-container"
        key={key}
        style={{
          ...styles.spinCarContainer,
          height: this.state.galleryWidth * 0.75, // make entire gallery 4:3
        }}
      >
        <script src={spinScriptSrc} />
        <div className="sts-spin">
          <iframe
            title="spincar"
            id="vdp-section-iframe"
            src={this.state.spinUrl}
            style={styles.spinCarFrame}
            referrerPolicy="no-referrer"
          />
        </div>
      </div>
    );
  }

  findCRLinkAndContent() {
    // preferences: link > line items
    // links: crLink > parsedConditionReport.CR._
    // line items: parsedConditionReport.CR.DM > crDetails
    // crType: '5' (3rd party link) or undefined
    const { cr } = this.props.item;

    const obj = {
      content: '',
      link: '',
      crType: '',
    };

    if (cr.crLink) {
      obj.link = cr.crLink;
      obj.content = (
        <iframe
          id="vdp-section-iframe"
          title="crLink"
          src={removeProtocol(cr.crLink)}
          style={{ border: 'none' }}
          className="cr-iframe"
          allowFullScreen
        />
      );
    } else if (cr.conditionReport) {
      let parsedConditionReport = '';
      const parser = new xml2js.Parser();
      parser.parseString(cr.conditionReport, (error, result) => {
        if (!error) parsedConditionReport = result;
      });

      if (cr.crData.type == '3') {
        obj.link = null;
        obj.content = <ConditionReport cr={parsedConditionReport.CR.DM} />;
      } else if (cr.crData.type == '4' && parsedConditionReport.CR._) {
        obj.link = parsedConditionReport.CR._;
        obj.content = (
          <iframe
            id="vdp-section-iframe"
            title="cr4"
            src={removeProtocol(parsedConditionReport.CR._)}
            style={{ width: '100%', minHeight: '800px', border: 'none' }} // why the different style
          />
        );
      } else if (cr.crData.type === '5'){
        obj.link = parsedConditionReport.CR._;
        obj.crType = '5';
        
      } else {
        console.warn(`Unknown CR type: ${cr.crData.type}`);
      }
    } else if (cr.crDetails && cr.crDetails.length) {
      obj.link = null;
      obj.content = <ConditionReport details={cr.crDetails} />;
    }

    return obj;
  }

  // other renders

  checkItemInPurchasedData(itemId, returnMatch=false) {
    const purchasedItems = this.props.buyerPurchased.data
      ? this.props.buyerPurchased.data
      : [];
    if (purchasedItems.length === 0) return false;
    const matchedItems = purchasedItems.filter((item, index) => {
      return item.itemId === itemId;
    });
    if (returnMatch) {
      return matchedItems;
    } else {
      return matchedItems.length > 0 ? true : false;
    }
  }

  renderSellerName(itemData) {
    const {sellerName} = itemData;
    if (getConfig('hideSellerInfoVdp') === true) {
      return null;
    }
    if (sellerName && this.state.view === 'wholesale') {
      return (
        <div style={styles.labelAndValue}>
          <span style={styles.label}>{`Seller: `}</span>
          <span style={styles.value}>{sellerName}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderLocationInfo(itemData) {
    if (!itemData) return null;
    const {
      vehicleAddress,
      vehicleCity,
      vehicleState,
      vehicleZip,
      sellerAddress1='',
      sellerAddress2='',
      sellerCity='',
      sellerState,
      sellerZip,
      marketplaceState,
      marketplaceZip,
    } = itemData;
    
    const hasItemAddress = !!(vehicleState && vehicleZip);
    const hasSellerAddress = !!(sellerState && sellerZip);
    const hasMarketplaceAddress = !!(marketplaceState && marketplaceZip);
    
    if (!(hasItemAddress || hasSellerAddress || hasMarketplaceAddress)) {
      return null;
    }

    let fullAddress = '';
    
    // Build address from vehicle data
    if (hasItemAddress) {
      fullAddress += vehicleAddress ? `${vehicleAddress}, ` : '';
      fullAddress += vehicleCity ? `${vehicleCity}, ` : '';
      fullAddress += `${vehicleState} ${vehicleZip}`
    }
    // Build address from item seller info
    else if (hasSellerAddress) {
      const streetAddr = sellerAddress1 + sellerAddress2 ? ' ' : '' + sellerAddress2;
      fullAddress += [streetAddr, sellerCity].filter(val=>val).join(', ');
      fullAddress += `${sellerState} ${sellerZip}`
    }
    // Build address from item marketplace info
    else if (hasMarketplaceAddress) {
      fullAddress += `${marketplaceState} ${marketplaceZip}`;
    }

    if (this.state.view === 'wholesale') {
      return (
        <div style={styles.labelAndValue}>
          <span style={styles.label}>{`Location: `}</span>
          <span style={styles.value}>{fullAddress}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderEventDescription(itemData) {
    const eventDesc = this.getEventDescription(itemData);
    const label = 'Event Description';

    if (eventDesc) {
      return (
        <div style={{...styles.labelAndValue, paddingTop: 8}}>
          <span style={styles.label}>{`${label}: `}</span>
          <span style={styles.value}>{eventDesc}</span>
        </div>
      );
    }
  }

  renderEventName(itemData) {
    const { eventName } = itemData;
    const { view } = this.state;
    const label = 'Event';
    const value = eventName;

    if (eventName && view === 'wholesale') {
      return (
        <div style={styles.labelAndValue}>
          <span style={styles.label}>{`${label}: `}</span>
          <span style={styles.value}>{value}</span>
        </div>
      );
    }
  }

  renderFacilitatorInfo(itemData) {
    if (!itemData || getConfig('hideFacilitatorVdp') === true) {
      return null;
    }
    const { facilitator, marketplaceName } = itemData;
    const label = 'Facilitator';
    const value = facilitator || marketplaceName;
  
    if (value) {
      return (
        <div style={styles.labelAndValue}>
          <span style={styles.label}>{`${label}: `}</span>
          <span style={styles.value}>{value}</span>
        </div>
      );
    }
  }

  renderHighBid(itemData, bidData) {
    if (
      !itemData.highBid ||
      (this.state.countdownInfo.countdownType === 'subtle' &&
        this.state.countdownInfo.eventStatus === 'isBefore')
    ) {
      return null;
    }

    const highBidderAccount = bidData.filter(
      obj => obj.dealerId === itemData.highBidderAccountId
    )[0];
    const bidderName = get(highBidderAccount, 'bidderName');
    const accountName = get(highBidderAccount, 'accountName');
    const nameAndAccount =
      bidderName && accountName ? `- ${bidderName} - ${accountName}` : '';
    const highBid = commafyCurrency(itemData.highBid);

    return (
      <div style={{ display: 'flex', fontSize: 18, marginBottom: 20 }}>
        <div style={{}}>High Bid: </div>
        <div
          style={{
            marginLeft: 10,
            color: this.state.hasChange ? '#27ae60' : 'inherit',
          }}
        >
          {highBid}
        </div>
        {!getConfig('hideBidderInBidHistory') && (
          <div style={{ marginLeft: 10 }}>{nameAndAccount}</div>
        )}
      </div>
    );
  }

  renderBidHistoryTable(mergedBidHistory) {
    const accountList = get(this.props.userProfile, 'user.accountList') || [];
    const hideBidder = getConfig('hideBidderInBidHistory');
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 16,
    };
    const rowStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      paddingBottom: 5,
      whiteSpace: 'pre',
    };
    const colStyle = {
      paddingRight: 10,
      minWidth: '12%',
      whiteSpace: 'pre',
    };

    const rows = [...mergedBidHistory].map((bid, index, arr) => {
      const bidAmount = commafyCurrency(bid.bidAmount);
      const bidAmountText = ' '.repeat(10 - bidAmount.length) + bidAmount;
      const bidType = bid.bidType;
      const prevBid = arr[index - 1];
      // TODO:  (BW: 03-2024) - Replace deprecated 'moment-timezone.js' calls with 'Intl' namespace
      const bidDate = bid.dateCreated;
      const adj = isDST() ? '07:00' : '08:00';
      const bidDateText = moment
        .tz(`${bidDate}-${adj}`, timezone)
        .format('LLL z');

      const yourAccount = find(
        accountList,
        acct => Number(acct.userId) === Number(bid.bidderId)
      );
      const bidderInfo = hideBidder
        ? yourAccount
          ? 'Your Bid'
          : ``
        : `${
            yourAccount
              ? 'You'
              : bid.bidderName ||
                `${yourAccount.mappedUserFirstName} ${yourAccount.mappedUserLastName}`
          } (${bid.accountName || yourAccount.accountName} - ${
            bid.bidderCity
          } ${bid.bidderState})`;

      return (
        <div
          key={bid.offerId}
          style={{
            ...rowStyle,
            backgroundColor: index % 2 === 0 ? '#F5F5F5' : 'inherit',
          }}
        >
          <div style={{ ...colStyle }}>{bidAmountText}</div>
          <div style={{ ...colStyle }}>{bid.offerStatus}</div>
          <div style={{ ...colStyle }}>{bidType}</div>
          <div style={{ ...colStyle, minWidth: '24%' }}>{bidDateText}</div>
          <div style={{ ...colStyle }}>{bidderInfo}</div>
        </div>
      );
    });

    rows.unshift(
      <div key="header" style={{ ...rowStyle, fontWeight: 'bold' }}>
        <div style={{ ...colStyle }}>Amount</div>
        <div style={{ ...colStyle }}>Status</div>
        <div style={{ ...colStyle }}>Type</div>
        <div style={{ ...colStyle, minWidth: '24%' }}>Date</div>
        <div style={{ ...colStyle }}>{hideBidder ? '' : 'Bidder'}</div>
      </div>
    );

    return <div style={containerStyle}>{rows}</div>;
  }

  renderErrorState() {
    return (
      <h3 style={{
        width: '100%',
        textAlign: 'center',
      }}
      >
        {"An Error has occurred while loading the vehicle information.  Please try again or contact Support."}
      </h3>
    )
  }

  renderBidHistorySection(itemData, key) {
    const bidHistory = this.props.bidHistory.data || [];
    const realtimeBidHistoryData = this.props.bidMessageHistoryRealTime || [];
    const mergedBidHistory = mergeBidHistory(
      itemData.itemId,
      bidHistory,
      realtimeBidHistoryData
    );

    if (!mergedBidHistory.length || this.state.view === 'retail') {
      return null;
    }

    return (
      <div key={key} style={styles.section}>
        <div style={styles.header}>
          <span className="fa fa-history" style={styles.icon} />
          <span>Bid History</span>
        </div>

        <div style={{ padding: 20, paddingTop: 10, minWidth: '50%' }}>
          {this.renderHighBid(itemData, mergedBidHistory)}
          {this.renderBidHistoryTable(mergedBidHistory)}
        </div>
      </div>
    );
  }

  renderStatusAlert(itemData) {
    const { eventFeatures='', itemId, purchaseDate, status, salePrice } = itemData;
    const formattedPrice = commafyCurrency(salePrice);
    const adj = isDST() ? '07:00' : '08:00';
    const formattedTime = moment
      .tz(`${purchaseDate}-${adj}`, timezone)
      .format('LLL z');

    let text = '';
    let alertType = '';

    switch (status) {
      case 'Sold': {
        const isMatchedEventId = this.checkItemInPurchasedData(itemId);
        text = isMatchedEventId
          ? `You purchased this vehicle for ${formattedPrice} on ${formattedTime}`
          : `This vehicle was sold for ${formattedPrice} on ${formattedTime}`;
        alertType = 'alert-danger';
        break;
      }
      case 'If Sale': {
        const isUpstreamIfEvent = eventFeatures.split(',').includes('53');
        text = isUpstreamIfEvent
          ? 'This vehicle has been reserved, pending grounding.'
          : 'This vehicle is a pending If-Sale.';
        alertType = 'alert-warning';
        break;
      }
      case 'Ready For Release': {
        text = 'This vehicle has been unlisted by the seller.';
        alertType = 'alert-warning';
        break;
      }
      case 'No Sale': {
        text = 'This vehicle is a No-Sale.';
        alertType = 'alert-warning';
        break;
      }
      case 'Cancelled':
      case 'Out-of-Stock / Retailed':
      case 'Sale or Return': {
        text = 'This vehicle is no longer available.';
        alertType = 'alert-warning';
        break;
      }
      default:
        break;
    }

    return (
      text && (
        <div
          className={`alert ${alertType}`}
          style={{ marginTop: '10px' }}
        >
          <span>{text}</span>
        </div>
      )
    );
  }

  renderSellingNowMessage() {
    return (
      <div
        className="alert alert-warning"
        style={{ marginRight: '-15px', marginTop: '10px' }}
      >
        <span>
          In the “Sell Live” event the first bid is a live bid from{' '}
          {getConfig('name')}. Instantly after a bid beyond the first bid is
          placed the vehicle cascades into a 30 minute auction. If a bid comes
          in during the last 10 minutes of the auction closing there will be one
          30 minute extension. Put your best bid in and the Auction will close
          at the end of the extended 30 minutes. Alerts will be sent to all
          bidders of their status.
        </span>
      </div>
    );
  }

  renderBarcode(itemData) {
    const hideQRCode = (this.props.marketplaceFeatures.features || '')
      .split(',')
      .includes('534');

    if (hideQRCode) return null;

    return (
      <div>
        <QRCode value={itemData.vin} renderAs="svg" size={60} />
        <div style={{ fontSize: 12, textAlign: 'center' }}>Scan VIN</div>
      </div>
    );
  }

  renderOffersSection(itemData, key) {
    if (!this.props.bidHistory.data) return null;
    if (itemData?.statusId !== 1) return null;

    const sellerOffers = this.props.bidHistory.data.filter(bid => {
      return (
        (bid.bidType === 'Offer' || bid.bidType === SST_FIRST_OFFER) &&
        this.isOfferPending(bid.offerStatusId) &&
        bid.bidAmount > 0
      );
    });

    const buyerOffers = this.props.bidHistory.data.filter(bid => {
      const isBidder = this.isBidder(bid.bidderId);

      return (
        bid.bidType === 'Offer' &&
        bid.bidAmount > 0 &&
        this.isOfferPending(bid.offerStatusId) &&
        isBidder
      );
    });

    const isSeller = this.isSeller();
    const hasSellerOffers = !!sellerOffers.length;
    const hasBuyerOffers = !!buyerOffers.length;

    if (hasBuyerOffers || (hasSellerOffers && isSeller))
      return (
        <div
          key={key}
          style={{
            ...styles.section,
            padding: 20,
          }}
        >
          <div style={styles.header}>
            <span className="fa fa-briefcase" style={styles.icon} />
            <span>Offers</span>
          </div>

          {hasSellerOffers &&
            this.isSeller() &&
            this.renderSellerOffers(itemData, sellerOffers)}

          {hasBuyerOffers && this.renderBuyerOffers(itemData, buyerOffers)}
        </div>
      );
  }

  renderSellerOffers(itemData, sellerOffers) {
    return sellerOffers.map(offer => {
      const {
        accountName,
        bidAmount,
        bidderName,
        offerStatus,
        requiredAmount,
      } = offer;

      return (
        <div
          key={offer.offerId}
          style={{ ...styles.offer, alignItems: 'center' }}
        >
          <div style={styles.offerLeft}>
            <div style={styles.labelAndValue}>
              <span style={styles.label}>{`Offer Received: `}</span>
              <span style={styles.value}>{commafyCurrency(bidAmount)}</span>
            </div>

            {!!requiredAmount && (
              <div style={styles.labelAndValue}>
                <span style={styles.label}>{`You Want: `}</span>
                <span style={styles.value}>
                  {commafyCurrency(requiredAmount)}
                </span>
              </div>
            )}

            <div style={styles.labelAndValue}>
              <span style={styles.label}>{`From: `}</span>
              <span
                style={styles.value}
              >{`${bidderName} at ${accountName}`}</span>
            </div>

            <div style={styles.labelAndValue}>
              <span style={styles.label}>{`Status: `}</span>
              <span style={styles.value}>
                {offerStatus.replace('Seller', 'You')}
              </span>
            </div>
          </div>

          {itemData.offerType === 6
            ? this.renderViewExtOffersButton(offer.offerId)
            : this.renderSellerOfferButtons(offer, itemData.itemId)}
        </div>
      );
    });
  }

  renderBuyerOffers(itemData, buyerOffers) {
    return buyerOffers.map(offer => {
      const {
        accountName,
        bidAmount,
        bidderName,
        offerStatus,
        requiredAmount,
      } = offer;

      return (
        <div key={offer.offerId} style={styles.offer}>
          <div style={styles.offerLeft}>
            <div style={styles.labelAndValue}>
              <span style={styles.label}>{`Offer Made: `}</span>
              <span style={styles.value}>{commafyCurrency(bidAmount)}</span>
            </div>

            {!!requiredAmount && (
              <div style={styles.labelAndValue}>
                <span style={styles.label}>{`Seller Wants: `}</span>
                <span style={styles.value}>
                  {commafyCurrency(requiredAmount)}
                </span>
              </div>
            )}

            <div style={styles.labelAndValue}>
              <span style={styles.label}>{`From: `}</span>
              <span
                style={styles.value}
              >{`${bidderName} at ${accountName}`}</span>
            </div>

            <div style={styles.labelAndValue}>
              <span style={styles.label}>{`Status: `}</span>
              <span style={styles.value}>
                {offerStatus.replace('Buyer', 'You')}
              </span>
            </div>
          </div>

          {this.renderBuyerOfferButtons(offer, itemData.itemId)}
        </div>
      );
    });
  }

  renderSellerOfferButtons(offer, itemId) {
    const { offerStatus } = offer;

    const showSellerAcceptButton = ['Active', 'Buyer Countered'].includes(
      offerStatus
    );

    const showSellerCounterButton = ['Active', 'Buyer Countered'].includes(
      offerStatus
    );

    const showSellerDeclineButton = ['Active', 'Buyer Countered'].includes(
      offerStatus
    );

    const showSellerCancelButton = ['Seller Countered'].includes(offerStatus);

    return (
      <div style={styles.offerButtonGroup}>
        <ButtonGroup vertical>
          {showSellerAcceptButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.green'),
              }}
              onClick={() => this.handleOfferSellerAccept(offer, itemId)}
            >
              Accept
            </Button>
          )}

          {showSellerCounterButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(
                  this.props.template,
                  'misc.orange'
                ),
              }}
              onClick={() =>
                this.openCounterOfferModal(offer, itemId, 'Seller')
              }
            >
              Counter
            </Button>
          )}

          {showSellerDeclineButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.red'),
              }}
              onClick={() => this.handleOfferSellerDecline(offer, itemId)}
            >
              Decline
            </Button>
          )}

          {showSellerCancelButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.red'),
              }}
              onClick={() => this.handleOfferSellerCancel(offer, itemId)}
            >
              Withdraw
            </Button>
          )}
        </ButtonGroup>
      </div>
    );
  }

  renderBuyerOfferButtons(offer, itemId) {
    const { offerStatus } = offer;

    const showBuyerAcceptButton = ['Seller Countered'].includes(offerStatus);
    const showBuyerCounterButton = ['Seller Countered'].includes(offerStatus);
    const showBuyerDeclineButton = ['Seller Countered'].includes(offerStatus);
    const showBuyerRaiseButton = ['Active'].includes(offerStatus);
    const showBuyerCancelButton = ['Active', 'Buyer Countered'].includes(
      offerStatus
    );

    return (
      <div style={styles.offerButtonGroup}>
        <ButtonGroup vertical>
          {showBuyerAcceptButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.green'),
              }}
              onClick={() => this.handleOfferBuyerAccept(offer, itemId)}
            >
              Accept
            </Button>
          )}

          {showBuyerCounterButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(
                  this.props.template,
                  'misc.orange'
                ),
              }}
              onClick={() => this.openCounterOfferModal(offer, itemId, 'Buyer')}
            >
              Counter
            </Button>
          )}

          {showBuyerDeclineButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.red'),
              }}
              onClick={() => this.handleOfferBuyerDecline(offer, itemId)}
            >
              Decline
            </Button>
          )}

          {showBuyerRaiseButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.green'),
              }}
              onClick={() => this.openRaiseOfferModal(offer, itemId)}
            >
              Raise
            </Button>
          )}

          {showBuyerCancelButton && (
            <Button
              style={{
                ...styles.offerButton,
                fontSize: 14,
                backgroundColor: getTemplate(this.props.template, 'misc.red'),
              }}
              onClick={() => this.handleOfferBuyerCancel(offer, itemId)}
            >
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </div>
    );
  }

  renderViewExtOffersButton(offerId) {
    return (
      <div>
        <Button
          style={{
            ...styles.offerButton,
            fontSize: 12,
            backgroundColor: getTemplate(this.props.template, 'misc.green'),
          }}
          onClick={() => this.getDeepLink(offerId)}
        >
          View Offer
        </Button>
      </div>
    );
  }

  getDeepLink = offerId => {
    const url =
      `${getConfig('apiRoot')}ext-offer-deeplink` +
      `?apiKey=${this.props.cookies.get('apiKey')}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&offerId=${offerId}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        if (result.deepLink.deepLinkUri) {
          this.setState(
            { offerId: offerId, deeplink: result.deepLink.deepLinkUri },
            () => {
              $('#extOfferModal').modal('show');
            }
          );
        } else {
          toastr.error('Error', result.wsMessage);
        }
      });
  };

  handleExtOffersModalCancel = () => {
    const mpId = getConfig('marketplaceId');
    const { offerId } = this.state;

    this.props.loadExtOfferUpdate(mpId, offerId);
    this.setState({ offerId: 0, deeplink: null }, () => {
      $('#extOfferModal').modal('hide');
    });
  };

  renderUnauthorized() {
    return (
      <div className="st-container shadow padding background-white padding">
        <p>You are not authorized to view this item.</p>
      </div>
    );
  }
  // TODO - 3.2024 - Refactor:  
  //                 Most of these modals subscribe to redux state and
  //                 can be manged via Context/Provider, triggered with action/reducers
  renderModals(itemData) {
    const { bidMessagesRealTime, navigate, userProfile } = this.props;
    const {
      autoniqUrl,
      bulkLotItems,
      deeplink,
      enabledTransportProviders,
      notes,
      offerAmount,
      offerId,
      offerType,
      requiredAmount,
      isTransportationModalOpen,
      transportationModalMode,
      transportationSelectedQuote,
      isBidModalOpen,
      isOfferModalOpen,
      isBuyNowModalOpen,
      isNotesModalOpen,
      isMMRModalOpen,
      isAutoniqModalOpen,
      isBulkBidModalOpen,
    } = this.state;

    const isBulkLot = !!bulkLotItems.length;

    const parentItem =
      itemData.parentListingId > 0 && bulkLotItems[0]
        ? { ...bulkLotItems[0], ...bidMessagesRealTime[bulkLotItems[0].itemId] }
        : itemData;

    const modalId = this.determineModalId(parentItem.itemId);
    return (
      <>
        <TransportationModal
          itemData={parentItem}
          mpId={getConfig('marketplaceId')}
          mode={transportationModalMode}
          user={userProfile.user}
          isOpen={isTransportationModalOpen}
          onClose={() => this.setState({ isTransportationModalOpen: false })}
          providers={enabledTransportProviders}
          selectedQuote={transportationSelectedQuote}
          
        />
        <BidModal
          modalId={modalId}
          item={parentItem}
          loadData={this.loadData}
          navigate={navigate}
          isOpen={isBidModalOpen}
          onClose={() => this.setState({ isBidModalOpen: false })}
        />
        <OfferModal
          modalId={modalId}
          item={parentItem}
          loadData={this.loadData}
          offerAmount={offerAmount}
          offerId={offerId}
          offerType={offerType}
          requiredAmount={requiredAmount}
          navigate={navigate}
          isOpen={isOfferModalOpen}
          onClose={() => this.setState({ isOfferModalOpen: false })}
        />
        <BuyModal
          modalId={modalId}
          item={parentItem}
          loadData={this.loadData}
          navigate={navigate}
          isOpen={isBuyNowModalOpen}
          onClose={() => this.setState({ isBuyNowModalOpen: false })}
        />

        <AddNoteModal
          modalId={modalId}
          item={parentItem}
          notes={notes}
          loadData={this.loadData}
          navigate={navigate}
          isOpen={isNotesModalOpen}
          onClose={() => this.setState({ isNotesModalOpen: false })}
        />
        <ExtOfferModal
          deeplink={deeplink}
          onModalCancel={this.handleExtOffersModalCancel}
          navigate={navigate}
        />
        <MMRModal
          item={itemData}
          user={userProfile.user}
          navigate={navigate}
          isOpen={isMMRModalOpen}
          onClose={() => this.setState({ isMMRModalOpen: false })}
        />
        <AutoniqModal
          url={autoniqUrl}
          navigate={navigate}
          isOpen={isAutoniqModalOpen}
          onClose={() => this.setState({ isAutoniqModalOpen: false })}
        />
        {isBulkLot && (
          <BulkLotBidModal
            modalId={modalId}
            item={parentItem}
            loadData={this.loadData}
            bulkLotItems={bulkLotItems}
            navigate={navigate}
            isOpen={isBulkBidModalOpen}
            onClose={() => this.setState({ isBulkBidModalOpen: false })}
          />
        )}
      </>
    );
  }

  render() {
    const { bidMessagesRealTime, item, unauthorizedEventIds } = this.props;
    const { bulkLotItems } = this.state;

    if (this.state.isErrorState) {
      return (this.renderErrorState());
    }

    if (
      !item.item ||
      item.wsStatus === 'Error' ||
      (item.item.parentListingId > 0 && !bulkLotItems[0]) // is a bulk-lot sub item and we don't have the parent info yet
    ) {
      return null;
    }

    const itemData = { ...item.item, ...bidMessagesRealTime[item.item.itemId] };

    if (unauthorizedEventIds.includes(itemData.eventId)) {
      return this.renderUnauthorized();
    }

    const isBulkLot = !!bulkLotItems.length;

    return (
      <DocumentTitle title={`Vehicle Details`}>
        <div>
          {this.renderModals(itemData)}
          <StickyContainer>{this.renderStatusAlert(itemData)}</StickyContainer>
          {isBulkLot && this.renderBulkVehicleCards()}
          <div style={styles.container}>
            <div className='vdp_column-left'>{this.renderSectionsLeft(itemData)}</div>
            <div className='vdp_column-right'>{this.renderSectionsRight(itemData)}</div>
            {this.renderDarwinData(itemData)}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

VehicleDetails.propTypes = {
  transportationQuote: PropTypes.shape({
    rate: PropTypes.number,
    inoperableFee: PropTypes.number,
    itemId: PropTypes.number,
    originZip: PropTypes.string,
    destinationZip: PropTypes.string,
    isInoperable: PropTypes.bool,
    isInclosed: PropTypes.bool,
  }),
};

const mapStateToProps = state => {
  const {
    bidHistory,
    bidMessageHistoryRealTime,
    bidMessagesRealTime,
    buyerPurchased,
    carfaxCode,
    events,
    item,
    marketplaceFeatures,
    marketplaces,
    offer,
    onlineConnected,
    realTimeConnected,
    retailView,
    template,
    unauthorizedEventIds,
    userProfile,
    watchlistObject,
  } = state.entities;
  return {
    bidHistory,
    bidMessageHistoryRealTime,
    bidMessagesRealTime,
    buyerPurchased,
    carfaxCode,
    events,
    item,
    marketplaceFeatures,
    marketplaces,
    offer,
    onlineConnected,
    realTimeConnected,
    retailView,
    template,
    unauthorizedEventIds,
    userProfile,
    watchlistObject,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loadAddWatchList,
    loadBidHistory,
    loadBuyerPurchased,
    loadCarfaxLoginCheck,
    loadCarfaxReport,
    loadExtOfferUpdate,
    loadItem,
    loadItemUpdateOffer,
    loadMarketplaces,
    loadParentItem,
    loadRemoveWatchList,
    loadSpinCar,
    loadWatchlistES,
    loadWatchlistObject,
    loadWatchlistUserItems,
    resetBidHistory,
    resetBidMessageHistoryRealTime,
    resetCarfaxCode,
    resetCarfaxReport,
    resetItem,
    resetOffer,
    resetParentItem,
    retailViewOff,
    retailViewOn,
  })
)(VehicleDetails);
