import React from "react";

/**
 * @typedef {Object} StratosLoaderDataProps
 * @property {ItemData  &{[key:string]: any}} itemData
 * @property {{darwinDealerId?: string, [key:string]: any}[]} accountList
 */
/** @type {StratosLoaderDataProps} */

/** Invisible `div` containing meta data for Darwin Bookout.
 * Requires the vehicle's VIN, image, and mileage - from corresponding AWG or ES value.
 * @param {StratosLoaderDataProps} props
 * @returns {React.JSX.Element|null}
 */
const StratosLoaderData = ({itemData, accountList=[]}) => {
    if (itemData && 
        (itemData.vin || itemData.vIN) && 
        (itemData.mileage || itemData.odometer) &&
        (itemData.imageFullUrls || itemData.mainImage)
    ) {
      const {darwinDealerId, multiDealerIds} = findDealerIds(accountList);
      const {vin, vIN, odometer, mileage, imageFullUrls, mainImage=''} = itemData;
      const vehicleImage = imageFullUrls?.length ? imageFullUrls[0] : mainImage;
      return (
        <div 
          style={{width: 0}}
          className="darwinVehicleData"
          data-vin={vin || vIN}
          data-mileage={odometer || mileage}
          data-img={vehicleImage}
          data-darwindealerid={darwinDealerId || ''}
          data-multidealer={multiDealerIds}
          data-price={getPrice(itemData)}
        />
      )
    } else {
      return null;
    }
}

/* ---- Local Functions ---- */
/** Finds initial (assumed primary) darwin dealer ID + remaining ID's from given `userprofile.accountList`
 * 
 * @param {{darwinDealerId?: string, [key:string]: any}} accountList 
 * @returns {{darwinDealerId: string, multiDealerIds: string}}
 */
function findDealerIds (accountList=[]) {
  try {
    const darwinDealerId = accountList ? accountList.find(acct => acct.darwinDealerId)?.darwinDealerId : '';
    const multiDealerIds = accountList.filter(acct => acct?.darwinDealerId && acct.darwinDealerId !== darwinDealerId).map(a=>a.darwinDealerId).join(',');
    return {darwinDealerId,multiDealerIds};
  } catch (parseDealerError) {
    console.error(' Error finding darwin dealer ID(s) ', parseDealerError?.message || parseDealerError);
    return {darwinDealerId: '', multiDealerIds: ''};
  }
}

/** Return relevant vehicle price.
 * 
 * @param {ItemData &ItemPriceData} itemData 
 */
function getPrice (itemData) {
  const {outrightPrice, highBid, highOffer, nextValidBidAmount, startingBid} = itemData;
  return [
    outrightPrice, nextValidBidAmount, highBid, highOffer, startingBid,
  ].find(val => val) || '';
}

/* ---- Local Types ---- */

/* * * * ItemData * * * */
/**
 * @typedef {Object} ItemData
 * @property {string|undefined} vin - VIN of vehicle (_AWG_)
 * @property {string|undefined} vIN - VIN of vehicle (_Elastic Search_)
 * @property {number|undefined} odometer Odometer reading (_AWG_)
 * @property {number|undefined} mileage Odometer reading (_Elastic Search_)
 * @property {string[] | undefined} imageFullUrls array of image URL strings (AWG_)
 * @property {string|undefined} mainImage image url string (_Elastic Search)
 */
/** @type {ItemData} */

/* * * * ItemPriceData * * * */
/**
 * @typedef {Object} ItemPriceData
 * @property {number} bidIncrement
 * @property {number} buyNow
 * @property {number} feedPrice
 * @property {number} highBid
 * @property {number} highOffer
 * @property {number} nextValidBidAmount
 * @property {number} outrightPrice
 * @property {number} startingBid
 */
/** @type {ItemPriceData} */

export {StratosLoaderData};