import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadLots, updateItem } from '../../../../actions';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import {
  CYAN_01,
  BLACK_02,
  GREY_05,
  BLACK_03,
  WHITE_01,
  GREY_04,
} from '../../constants/colors';
import InputField from '../ui/inputField';
import Loader from '../ui/loader';

class SearchLotsModal extends Component {
  state = {
    searchText: '',
    selectedLot: '',
  };

  handleInputChange = e => {
    this.setState({ searchText: e.target.value });
  };

  handleSearch = () => {
    const { searchText } = this.state;
    const { vin, item } = this.props;

    if (searchText) {
      this.props
        .loadLots(item.vin, item.saleId, item.accountNumber, searchText)
        .then(({ response }) => {
          if (response) {
            this.props.updateItem({
              lotNumber: response.reservedLotNumber,
              stockNumber: response.stockNumber,
            });

            if (vin.decodedItems.length) {
              if (item.turnRerun) {
                this.props.onClose();
                this.props.navigate('/core/review');
              } else {
                this.props.onNext();
              }
            }
          }
        });
    }
  };

  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-10">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>

        <div className="pl-4 fs-20 font-medium" style={{ color: BLACK_02 }}>
          Search Lots
        </div>

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div>
        <div className="modal-mt">
          <Button
            btnText="Search"
            outlined={this.state.searchText ? false : true}
            outlinedTextColor={WHITE_01}
            outlinedBgColor={GREY_04}
            outlinedBorderColor={GREY_04}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.handleSearch}
          />
        </div>
        <div className="modal-mt">
          <Button
            btnText="Cancel"
            outlined={true}
            outlinedTextColor={BLACK_03}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onClose}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          style={styles.customStyles}
          shouldCloseOnEsc
          contentLabel={'searchLot'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width" style={{ minHeight: 260 }}>
            {this.props.loadingBar ? (
              <Loader />
            ) : (
              <div>
                {this.renderHeader()}
                <div className="">
                  <label
                    className="justify-start ml-6 fs-16 text-base font-medium"
                    style={{ color: BLACK_03 }}
                  >
                    Enter Custom Lot Here
                  </label>
                  <div className="mx-2">
                    <InputField
                      onChangeHandler={this.handleInputChange}
                      placeholder="Search"
                    />
                  </div>
                </div>
                {this.renderButtons()}
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const styles = {
  customStyles: {
    content: {
      display: 'block',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      zIndex: 99,
    },
  },
};

SearchLotsModal.propTypes = {
  loadLots: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { loadingBar } = state;
  const { lot, data, item, vin } = state.core;
  return { lot, data, item, vin, loadingBar };
};

export default connect(mapStateToProps, { loadLots, updateItem })(
  SearchLotsModal
);
