import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider, withCookies } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { ToastContainer } from 'react-toastify';
import config from '../config/index.js';
import Router from '../router';
import { CYAN_01 } from '../components/core/constants/colors.js';
import Icon from '../components/core/components/ui/icons';
import Modal from 'react-modal';

// TODO - choose one toast library:
// marketplace is using react-redux-toastr
// core is using react-toastify

const Root = props => {
  const [isMpConfig, setIsMpConfig] = useState(false);
  const {history, mpConfig} = props;

  /* Mount 
    - set config in local storage and (dev only) cookie 
      -> Local Development: index.dev.js 'devEndpoint' 
      -> Production | QA:   Config API
  */
  useEffect(() => {
    /* Set storage values */
    const setMarketplaceConfig = (conf) => {
      if (conf && conf.marketplaceId !== '0' && typeof conf === 'object') {
        localStorage.setItem('config', JSON.stringify(conf));
        localStorage.setItem('marketplaceId', conf.marketplaceId);
        props.cookies.set(
          'config', 
          conf, 
          {
            path: '/', 
            maxAge: 31556952000
          });
      } else {
        window.history.replaceState(null, '', '/default');
      }
      setIsMpConfig(true);
    }
    
    // const conf = props.cookies.get('config');
  
    /* Get config if not populated in props.mpConfig */
      setMarketplaceConfig(mpConfig);

  }, [props.cookies, mpConfig]);
 
  if (!isMpConfig) {
    return null;
  }
  Modal.setAppElement('#app-container');

  return (
    <div>
      <ToastContainer
        limit={1}
        hideProgressBar
        draggable={false}
        position="top-center"
        autoClose={5000}
        closeOnClick
        newestOnTop
        style={{ width: 600 }}
        closeButton={
          <div
            style={{
              border: 'solid 1px #DFDFE6',
              borderRadius: '50%',
              height: 22,
              width: 22,
            }}
            className="flex items-center"
          >
            <Icon name="Cross" color={CYAN_01} />
          </div>
        }
      />
      <CookiesProvider>
        <BrowserRouter history={history} {...props}>
          <ReduxToastr
            timeOut={2000}
            newestOnTop={true}
            position="top-right"
          />
          <Router history={history} cookies={props.cookies} />
        </BrowserRouter>
      </CookiesProvider>
    </div>
  );
};

Root.propTypes = {
  allCookies: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
};

export default withCookies(Root);
