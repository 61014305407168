// used by withTable hoc and whatever table component wants to do their own thing

const styles = {
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: 2,
    margin: 0,
  },
  button: {
    backgroundColor: 'gray',
    border: 'none',
    borderRadius: 2,
    boxShadow: 'none',
    color: 'white',
    margin: 2,
    padding: 2,
    width: '100%',
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'normal',
  },
};

export default styles;
