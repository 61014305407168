export const TransportationModalMode = {
    SELECTION: 0,
    ACERTUS: 1,
    RUNBUGGY: 2,
    ROYALTYSHIPS: 3
}

export const transportationMpFeatures = {
    '600': 'Acertus',
    '601': 'Runbuggy',
    '604': 'RoyaltyShips',
}

export const PROVIDERS = [
    'Acertus',
    'Runbuggy',
    'RoyaltyShips',
]