import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { find, get } from 'lodash';
import InventoryNav from '../components/InventoryNav';
import SellerArbs from './sellerActivity/SellerArbs';
import SellerBids from './sellerActivity/SellerBids';
import SellerCarOffers from './sellerActivity/SellerCarOffers';
import SellerIfs from './sellerActivity/SellerIfs';
import SellerListed from './sellerActivity/SellerListed';
import SellerOffers from './sellerActivity/SellerOffers';
import SellerSold from './sellerActivity/SellerSold';
import SellerTitles from './sellerActivity/SellerTitles';
import SellerUnlisted from './sellerActivity/SellerUnlisted';
import HyperlaunchListed from './sellerActivity/HyperlaunchUnlisted';
import HyperlaunchUnlisted from './sellerActivity/HyperlaunchUnlisted';
import { getConfig, getConfigs } from '../utils/helpers';

class Inventory extends Component {
  state = {
    tab: null,
    tabs: [],
  };

  componentDidMount() {
    this.loadTabs();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.marketplaceFeatures.features !==
      prevProps.marketplaceFeatures.features
    ) {
      this.loadTabs();
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadTab();
    }
  }

  loadTabs() {
    // TODO: - activity tabs should be an AWG config
    const { marketplaceFeatures, userProfile, location } = this.props;
    const mpFeatures = (get(marketplaceFeatures, 'features') || '').split(','); // called by TopNav.js, no need to call it here
    const userFeatures = get(userProfile, 'user.userFeatures') || [];
    const isCarOffersEnabled =
      mpFeatures.includes('544') &&
      userFeatures.includes('544') &&
      userFeatures.includes('545');
    const [
      disableSellerActivity,
      enableOffersTab,
      enableIfSales,
      enableArbitration,
      enableSellerTitles,
    ] = getConfigs(
      'disableSellerActivity',
      'enableOffersTab',
      'enableIfSales',
      'enableArbitration',
      'enableSellerTitles'
    );

    const tabs = [];

    if (!disableSellerActivity) {
      tabs.push({
        name: 'Listed',
        link: '/listed',
        section: 'listed',
      });

      tabs.push({
        name: 'Unlisted',
        link: '/unlisted',
        section: 'unlisted',
      });

      tabs.push({
        name: 'Bids',
        link: '/bids',
        section: 'bids',
      });

      if (enableOffersTab) {
        tabs.push({
          name: 'Offers',
          link: '/offers',
          section: 'offers',
        });
      }

      if (enableIfSales) {
        tabs.push({
          name: "If’s / Reserved",
          link: '/ifs',
          section: 'ifs',
        });
      }

      tabs.push({
        name: 'Sold',
        link: '/sold',
        section: 'sold',
      });

      if (enableArbitration) {
        tabs.push({
          name: 'Arbitration',
          link: '/arbs',
          section: 'arbs',
        });
      }

      if (enableSellerTitles) {
        tabs.push({
          name: 'Titles',
          link: '/titles',
          section: 'titles',
        });
      }

      if (isCarOffersEnabled) {
        tabs.push({
          name: 'SST First Offer',
          link: '/carOffers',
          section: 'carOffers',
        });
      }
    }

    const tab =
      find(tabs, {
        section: (location.pathname || '').split('/').reverse()[0],
      }) || tabs[0];

    this.setState({ tabs, tab });
  }

  loadTab() {
    const tab = find(this.state.tabs, {
      section: (this.props.location.pathname || '').split('/').reverse()[0],
    });

    if (tab) this.setState({ tab });
  }

  handleTabSelect = (e, tab) => {
    if (e) e.preventDefault();
    this.setState({ tab });
    this.props.navigate('/inventory/' + tab.section);
  };

  renderSection(section) {
    switch (section) {
      case 'arbs':
        return <SellerArbs />;
      case 'bids':
        return <SellerBids />;
      case 'ifs':
        return <SellerIfs />;
      case 'listed':
        return <SellerListed />;
      case 'offers':
        return <SellerOffers />;
      case 'sold':
        return <SellerSold />;
      case 'titles':
        return <SellerTitles />;
      case 'unlisted':
        return <SellerUnlisted />;
      case 'Hyperlaunch Listed':
        return <HyperlaunchListed />;
      case 'Hyperlaunch Unlisted':
        return <HyperlaunchUnlisted />;
      case 'carOffers':
        return <SellerCarOffers />;
      default:
        return null;
    }
  }

  render() {
    const section = this.state.tab ? this.state.tab.section : 'listed';
    const title = getConfig('name')
      ? getConfig('name') + ' | ' + section
      : section;

    return (
      <DocumentTitle title={title}>
        <div>
          <InventoryNav
            tabs={this.state.tabs}
            tab={this.state.tab}
            handleTabSelect={this.handleTabSelect}
          />
          <div className="container-fluid">{this.renderSection(section)}</div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => {
  const { marketplaceFeatures, userProfile } = state.entities;
  return { marketplaceFeatures, userProfile };
};

export default connect(mapStateToProps, {})(Inventory);
