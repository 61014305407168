import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Dotdotdot from 'react-dotdotdot';
import LazyLoad from 'react-lazyload';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { find, get } from 'lodash';
import $ from 'jquery';
import {
  commafy,
  commafyCurrency,
  compose,
  findDistanceUnits,
  findMileageUnits,
  getAttributeLabelAndValue,
  getBuyNowText,
  getConfig,
  getTemplate,
  isDST,
  makeAssetLink,
  makeBulkLotBoxShadow,
  showBidButton,
  showBuyNowButton,
  showOfferButton,
  stripHtml,
} from '../utils/helpers';
import withRouter from '../hocs/withRouter';
import { localization } from '../utils/localization';
import CountdownTimerVC from './CountdownTimerVC';
import BuyModal from '../components/BuyModal';
import BidModal from '../components/BidModal';
import OfferModal from '../components/OfferModal';
import Commafy from '../components/Commafy';
import {
  loadAddWatchList,
  loadBidHistory,
  loadElasticSearchDistance,
  loadItem,
  loadRemoveWatchList,
  loadTransportationTypes,
  loadWatchlistES,
  loadWatchlistUserItems,
  resetOffer,
  resetMaxCardHeight,
  resetBidHistory,
  loadWatchlistObject,
} from '../actions';
import BulkLotBidModal from './BulkLotBidModal';
import BulkLotItemsModal from './BulkLotItemsModal';
import '../assets/css/vehicle-card.css';
import { aaRequiredToaster } from './auctionAccess';
import { StratosLoaderData, VehicleValuesButton } from './common/vehicleValues';

window.$ = window.jQuery = $;
require('bootstrap');

const styles = {
  cardWrapper: {
    // Below moved to vehicle-card.css
    // margin: 8,
    // display: 'block',
    // float: 'left',
    // width: 250,
    border: '1px solid #ccc',
    borderRadius: 6,
    backgroundColor: '#fff',
    fontSize: 14,
  },
  cardContainer: {
    height: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  imageAndContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    height: '100%',
  },
  image: {
    position: 'relative',
    overflow: 'hidden',
    objectFit: 'contain',
    width: '100%',
    height: 185 + 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: '#000',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
  },
  rowContainer: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  rowLabel: {
    fontWeight: 'bold',
    marginRight: 4,
    color: '#787878',
  },
  rowValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 8,
    paddingTop: 0,
  },
  buttons: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: 0,
    marginTop: 5,
  },
  button: {
    border: 'none',
    borderRadius: 3,
    outline: 'none',
    padding: '4px 5px',
    margin: 2,
    fontSize: 11,
    fontWeight: 500,
    cursor: 'pointer',
    minWidth: '18%',
    color: '#fff',
  },
};

const bulkLotBoxShadow = makeBulkLotBoxShadow();
const DISABLE_DISTANCE_IN_DEV = false;

class Vehicle extends Component {
  constructor(props) {
    super(props);
    const parentMarketplace = find(
      props.marketplaces.marketplaceList,
      mp => mp.marketplaceId == getConfig('marketplaceId')
    );
    const mpSearchColumns = get(parentMarketplace, 'mpSearchColumns', '')
      .split(',')
      .filter(col => col);

    this.state = {
      hideBidButton: false,
      index: 0,
      isOpen: false,
      isSubmittingOffer: false,
      buyNowText: getBuyNowText(props.vehicleData),
      watchlistButtonDisabled: false,
      devDistance: undefined,
      googleMapsDistance: undefined,
      transportationDistance: undefined,
      esGeoDistance: undefined,
      vehicleDataDistance: undefined,
      distance: undefined,
      distanceMethod: undefined,
      countdownInfo: {},
      images: (props.vehicleData.images || '').split(','),
      dynamicData:
        getConfig('enableDynamicAttributes') &&
        get(props.vehicleData, 'attributes', []).some(
          attribute => attribute.inDetail
        ),
      bulkLotItems: [],
      hideSeller: mpSearchColumns.length && !mpSearchColumns.includes('5'), // don't have an mp feature atm
      isBidModalOpen: false,
      isOfferModalOpen: false,
      isBuyNowModalOpen: false,
      isBulkLotItemsModalOpen: false,
      isBulkBidModalOpen: false,
      isHolman: getConfig('marketplaceId') === '540',
    };
  }

  componentDidMount() {
    this.loadDistance();
    this.setBulkLot(this.props.vehicleData);
  }

  componentDidUpdate(prevProps, prevState) {
    this.loadDistance(prevProps);
  }

  setBulkLot = item => {
    if (item.subItemDetails.length) {
      const subItems = [...item.subItemDetails];
      subItems.unshift({
        listingId: item.id,
        cardTitle: [
          item.year || '',
          item.make || '',
          item.model || '',
          item.series || '',
        ].join(' '),
        location: item.itemCity + ', ' + item.itemState,
        vin: item.vin || item.vIN,
      });

      this.setState({
        bulkLotItems: subItems,
      });
    }
  };

  loadGoogleMapDistance(prevProps = {}) {
    // this method is the new default method for getting distance because
    // it is replicates how the transportation-estimate api calculates it's shipping distance
    // called individually per vehicle whenever the "userSelectedLocation" changes

    if (
      this.props.userSelectedLocation.address !==
        get(prevProps.userSelectedLocation, 'address') ||
      this.state.googleMapsDistance === undefined
    ) {
      this.setState(
        {
          googleMapsDistance: null,
          distance: null,
        },
        () => {
          const origins =
            this.props.vehicleData.itemPostalCode ||
            `${this.props.vehicleData.itemCity}+${this.props.vehicleData.itemState}`;

          const destinations =
            this.props.userSelectedLocation.zipcode ||
            (this.props.userSelectedLocation.address || '')
              .replace(/[*,]/g, '')
              .trim()
              .replace(/ /g, '+');

          if (!origins || !destinations) return null;

          const googleMapsDistanceService =
            new window.google.maps.DistanceMatrixService();
          googleMapsDistanceService.getDistanceMatrix(
            {
              origins: [origins],
              destinations: [destinations],
              travelMode: 'DRIVING',
              unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            },
            (response, status) => {
              if (response && status === 'OK') {
                const googleMapsDistance =
                  get(response, 'rows[0].elements[0].distance.value', '') *
                    0.00062137 || null;

                this.setState({
                  googleMapsDistance,
                  distance: googleMapsDistance,
                  distanceMethod: 'googleMapsDistance',
                });
              }
            }
          );
        }
      );
    }
  }

  loadTransportationDistance(prevProps = {}) {
    // this method gets the distance from the transportation-estimate api directly
    // we're not using this in lieu of the google maps distance
    if (
      this.props.userSelectedLocation.accountId !==
        get(prevProps.userSelectedLocation, 'accountId') ||
      this.state.transportationDistance === undefined
    ) {
      this.setState(
        {
          transportationDistance: null,
          distance: null,
        },
        () => {
          if (!this.props.userSelectedLocation.accountId) return null;

          this.props
            .loadTransportationTypes(
              getConfig('marketplaceId'),
              this.props.vehicleData.id,
              this.props.userSelectedLocation.accountId
            )
            .then(({ response }) => {
              if (response.wsStatus === 'Success') {
                const transportationDistance =
                  get(
                    find(response.items, item => item.distance),
                    'distance'
                  ) || null;

                this.setState({
                  transportationDistance,
                  distance: transportationDistance,
                  distanceMethod: 'transportationDistance',
                });
              }
            });
        }
      );
    }
  }

  loadESGeoDistance(prevProps = {}) {
    if (!this.props.userSelectedLocation.location) {
      return null;
    }

    if (
      this.props.userSelectedLocation.address !==
        get(prevProps.userSelectedLocation, 'address') ||
      this.state.esGeoDistance === undefined
    ) {
      this.setState(
        {
          esGeoDistance: null,
          distance: null,
        },
        () => {
          if (
            !this.props.vehicleData.id ||
            !this.props.userSelectedLocation.location.lat ||
            !this.props.userSelectedLocation.location.lng ||
            !findDistanceUnits(this.props.marketplaceFeatures)
          ) {
            return null;
          }

          this.props
            .loadElasticSearchDistance(
              this.props.vehicleData.id,
              this.props.userSelectedLocation.location.lat,
              this.props.userSelectedLocation.location.lng,
              findDistanceUnits(this.props.marketplaceFeatures)
            )
            .then(({ response }) => {
              const esGeoDistance =
                get(response, 'hits.hits[0].fields.distance[0]') || null;

              this.setState({
                esGeoDistance,
                distance: esGeoDistance,
                distanceMethod: 'esGeoDistance',
              });
            });
        }
      );
    }
  }

  loadVehicleDataDistance(prevProps = {}) {
    // this method uses the distance that is calculated by elasticsearch on the Search page
    // with Searchkit.  We use it when (1) when we are on the Search page and (2) when the user
    // selects a location that is NOT from the account list.  It's less expensive than the
    // google maps and we don't need it to exactly match shipping distance

    const vehicleDataDistance = get(this.props.vehicleData, 'distance', [])[0];
    const prevDistance = get(prevProps.vehicleData, 'distance', [])[0];

    if (
      vehicleDataDistance !== prevDistance ||
      this.state.vehicleDataDistance === undefined
    ) {
      this.setState({
        vehicleDataDistance: vehicleDataDistance || null,
        distance: vehicleDataDistance || null,
        distanceMethod: 'vehicleDataDistance',
      });
    }
  }

  loadDevDistance(prevProps) {
    if (this.state.devDistance) return;

    const devDistance = 999999;

    this.setState({
      devDistance,
      distance: devDistance,
      distanceMethod: 'devDistance',
    });
  }

  loadDistance(prevProps = {}) {
    if (DISABLE_DISTANCE_IN_DEV && process.env['MY_NODE'] === 'development') {
      // to avoid google charges we can limit calls during development
      this.loadDevDistance(prevProps);
    } else if (this.props.category !== 'search') {
      this.loadESGeoDistance(prevProps);
    } else if (!this.props.userSelectedLocation.accountId) {
      this.loadVehicleDataDistance(prevProps);
    } else {
      this.loadGoogleMapDistance(prevProps);
    }
  }

   /** Determines whether or not user needs to register for Auction Access
   *  to take action (buy, bid, offer) on an item.
   * 
   * @description Returns `true` if: 
   *  - `props.item.item.requiresAuctionAccess` is `true` 
   *  - `props.userProfile.user.hasAuctionAccess` is `false`
   * 
   * @returns {boolean}
   */
   isAuctionAccessNeeded = () => {
    try {
      const hasAuctionAccess = get(this.props.userProfile, 'user.hasAuctionAccess', false);
      const requiresAuctionAccess = get(this.props.vehicleData, 'requiresAuctionAccess');
      return Boolean(requiresAuctionAccess && !hasAuctionAccess);
    } catch (isAuctionAccessNeededError) {
      console.error(isAuctionAccessNeededError?.message || isAuctionAccessNeededError)
      return false;
    }
  }

  handleCardClick = e => {
    e.preventDefault();

    // persist scroll position on Search page
    if (this.props.category === 'search') {
      const searchScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (searchScroll) sessionStorage.setItem('searchScroll', searchScroll);
    }

    // gives the VDP the list
    if (this.props.navItems) {
      sessionStorage.setItem('navItems', this.props.navItems);
    }

    const path = `/item/${this.props.vehicleData.id}`;
    this.props.navigate(path);
  };

  handleImageClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  moveNext = () => {
    this.setState({ index: (this.state.index + 1) % this.state.images.length });
  };

  movePrev = () => {
    this.setState({
      index:
        (this.state.index + this.state.images.length - 1) %
        this.state.images.length,
    });
  };

  updateHideBidButton = () => {
    this.setState({ hideBidButton: true });
  };

  determineModalId = itemId => {
    // NOTE - modals don't work very well when the id contains white space in the modal id
    return `${(this.props.category || '').replace(' ', '')}${
      itemId || this.props.vehicleData.id
    }`;
  };

  handleModal = e => {
    e.preventDefault();
    e.stopPropagation();
    const aaIsRequired = this.isAuctionAccessNeeded();
    const type = e.currentTarget.getAttribute('data-type');
    const itemId = this.props.vehicleData.id;
    const modalId = this.determineModalId(itemId);
    const mpId = getConfig('marketplaceId');

    // user does not have AA + item is AA-only
    if (aaIsRequired && ['bid','offer','buy'].includes(type)) {
      aaRequiredToaster();
      return;
    }

    switch (type) {
      case 'bid': {
        this.props.loadItem(mpId, itemId);
        this.setState({ isBidModalOpen: true });
        break;
      }
      case 'offer': {
        this.props.loadItem(mpId, itemId);
        this.props.resetBidHistory();
        this.props.loadBidHistory(mpId, itemId)
        .then(bidHistoryRes => {
          this.setState({ isOfferModalOpen: true });
        }).catch(err => console.error(err));
        break;
      }
      case 'buy': {
        this.props.loadItem(mpId, itemId);
        this.setState({ isBuyNowModalOpen: true });
        break;
      }
      case 'bulkBid': {
        this.props.loadItem(mpId, itemId);
        this.setState({ isBulkBidModalOpen: true });
        break;
      }
      case 'bulkList': {
        this.props.loadItem(mpId, itemId);
        this.setState({ isBulkLotItemsModalOpen: true });
        break;
      }
      default:
        return null;
    }
  };

  handleWatchlistAction = action => {
    // call watchlist-add or watchlist-remove api
    this.setState({ watchlistButtonDisabled: true }, () => {
      const mpId = getConfig('marketplaceId');
      const message =
        action === 'add'
          ? 'Added to your watchlist'
          : 'Removed from your watchlist';

      const watchlistAction =
        action === 'add'
          ? this.props.loadAddWatchList
          : this.props.loadRemoveWatchList;

      watchlistAction(mpId, this.props.vehicleData.id)
        .then(({ response }) => {
          if (response.wsStatus === 'Success') {
            this.props.loadWatchlistObject(mpId);
            this.setState({ watchlistButtonDisabled: false }, () =>
              toastr.success('Success', message, { timeOut: 2000 })
            );
          } else {
            toastr.error('Error', response.wsMessage, { timeOut: 2000 });
          }
        })
        .catch(error => {
          toastr.error('Error');
          console.error(error, { timeOut: 2000 });
        });
    });
  };

  renderDate(date) {
    return (
      <div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <Dotdotdot clamp={1}>
              <span>Date Created: {date[0].dateCreated}</span>
            </Dotdotdot>
          </div>
        </div>
      </div>
    );
  }

  showListedPrice() {
    if (
      this.props.vehicleData.retailBookValue
      // && getConfig('localization') == 'en-uk'
    ) {
      return (
        <div>
          <strong>Listed Price: {'\u00A0'}</strong>
          {localization[getConfig('localization')].currency}
          <Commafy number={this.props.vehicleData.retailBookValue.toFixed(2)} />
        </div>
      );
    }
    return null;
  }

  showCAPClean() {
    if (
      this.props.vehicleData.wholesaleBookValue
      // && localization[getConfig('localization')] == 'en-uk'
    ) {
      return (
        <div>
          <strong>Cap Clean: {'\u00A0'}</strong>
          {localization[getConfig('localization')].currency}
          <Commafy
            number={this.props.vehicleData.wholesaleBookValue.toFixed(2)}
          />
        </div>
      );
    }
    return null;
  }

  showCAPID() {
    if (
      this.props.vehicleData.licenseNumber
      // && getConfig('localization') == 'en-uk'
    ) {
      return (
        <div>
          <strong>Cap ID: </strong>
          {this.props.vehicleData.licenseNumber}
        </div>
      );
    }
    return null;
  }

  renderBulkLotBadge() {
    // top-left
    const { hasSubListings, subItemDetails } = this.props.vehicleData;
    const isBulkLot = hasSubListings && subItemDetails.length > 0;
    const hasLotNumberBadge = this.renderLotNumberBadge();

    if (isBulkLot) {
      return (
        <div
          className="bulklot-badge"
          style={{ top: hasLotNumberBadge ? 30 : 5 }}
        >
          <span>Bulk Lot</span>
        </div>
      );
    }
  }

  renderLotNumberBadge() {
    // top-left
    const value = this.props.vehicleData.lotNumber;
    if (value) {
      const text = value;
      return (
        <span
          className="lot-number badge"
          style={{
            backgroundColor: getTemplate(this.props.template, 'misc.medGray'),
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderRetailPriceBadge() {
    // bottom-right (this is an either-or with Buy Now price)
    const features = (this.props.marketplaceFeatures.features || '').split(',');
    const retailPriceEnabled = features.includes('547');
    const {
      feedPrice,
      outrightPrice,
      listingStatusId,
      isMPSellingLive,
      eventFeatures,
    } = this.props.vehicleData;
    const isViewOnlyEvent = String(eventFeatures).split(',').includes('17');

    if (
      retailPriceEnabled &&
      feedPrice &&
      (!outrightPrice || outrightPrice === '0') && // keep this logic to save space
      listingStatusId === 1 &&
      !isMPSellingLive &&
      !isViewOnlyEvent
    ) {
      const text = `Retail: ${commafyCurrency(feedPrice)}`;
      return (
        <span
          className="vehicle-card-price badge"
          style={{
            backgroundColor: getTemplate(
              this.props.template,
              'itemCardBuyBadge.backgroundColor'
            ),
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderBuyNowBadge() {
    // bottom-right (this is an either-or with Retail price)
    const {outrightPrice: value} = this.props.vehicleData;
    if (showBuyNowButton(this.props.vehicleData) && value) {
      const {buyNowText} = this.state;
      const text = `${buyNowText}: ${commafyCurrency(value)}`;
      return (
        <span
          className="vehicle-card-price badge"
          style={{
            backgroundColor: getTemplate(
              this.props.template,
              'itemCardBuyBadge.backgroundColor'
            ),
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderHighBidderBadge() {
    // bottom-right (above buy now)
    const { vehicleData, userProfile } = this.props;

    if (showBidButton(vehicleData)) {
      const { bidIncrement, highBid, highBidderAccountId, startingBid } =
        vehicleData;

      const accounts = (
        userProfile.user ? userProfile.user.accountList || [] : []
      ).map(account => Number(account.accountId));
      const isBidder = accounts.includes(Number(highBidderAccountId));

      const nextBid = highBid
        ? Math.max(
            Number(highBid || 0) + Number(bidIncrement || 0),
            Number(startingBid || 0)
          )
        : Number(startingBid || 0);

      const text = isBidder
        ? // && Number(highBid) > nextBid // put in 6/21/23, taken out 8/8/23, not sure why put in
          `Your Bid: ${commafyCurrency(Number(highBid))}`
        : nextBid
        ? `Next Bid: ${commafyCurrency(nextBid)}`
        : null;

      const style = {
        bottom:
          this.renderBuyNowBadge() || this.renderRetailPriceBadge() ? 25 : 5,
        backgroundColor: getTemplate(
          this.props.template,
          'itemCardBidBadge.backgroundColor'
        ),
      };

      if (getConfig('bidButtonAltColor') && String(text).startsWith('Next')) {
        style.backgroundColor = getConfig('bidButtonAltColor');
      }

      if (text) {
        return (
          <span className="vehicle-card-bid badge" style={style}>
            {text}
          </span>
        );
      }
    }
  }

  renderCRBadge() {
    // bottom-left
    const value = this.props.vehicleData.rating;
    if (this.props.vehicleData.hasCR && value) {
      const text = `CR ${value}`;
      return (
        <span
          className="cr-button badge"
          style={{
            backgroundColor: getTemplate(this.props.template, 'misc.medGray'),
          }}
        >
          {text}
        </span>
      );
    }
  }

  render360Badge() {
    // bottom-left (above cr)
    const has360 =
      this.props.spinCars[(this.props.vehicleData.vIN || '').toUpperCase()];
    if (has360) {
      const text = ` 360°`;
      return (
        <span
          className="view-360 badge"
          style={{
            backgroundColor: getTemplate(this.props.template, 'misc.darkBlue'),
            bottom: this.renderCRBadge() ? 30 : 5,
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderImageBlock() {
    const { vehicleData } = this.props;
    const { images, index } = this.state;

    let mainImage = vehicleData.mainImage
      ? vehicleData.mainImage
      : images.length
      ? images[0]
      : '';

    // NOTE - for image UI testing
    // mainImage = [
    //   '', // bad
    //   '//greenlight-documents.s3.amazonaws.com/vehicle-images/20795322/20795322_93538_10219_7476.jpg',
    //   '//inventory-vc.assets-cdk.com/0/6/1/30255375160.jpg', // good
    //   '//inventory-vc.assets-cdk.com/7/4/7/30252714747.jpg', // good
    //   '//cdn.integratedauctionsolutions.com/202207/f7258fa916c6434b.JPG', // good
    //   '..\\images\\img_ina.gif', // bad
    //   '//qa.vr.ariautodirect.com/uvCrImages/IMSSystem/EZGBY0FACJ3301679/EZGBY0FACJ3301679_132884.jpg', // ugly
    // ][0];

    return (
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          objectFit: 'contain',
          width: '100%',
          height: 185,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottom: '1px solid #eee', // divider for white images
        }}
        // onClick={this.handleImageClick}
      >
        {this.renderStatusRibbon()}
        {this.renderBulkLotBadge()}
        {this.renderLotNumberBadge()}
        {this.renderCRBadge()}
        {this.renderBuyNowBadge()}
        {this.renderRetailPriceBadge()}
        {this.renderHighBidderBadge()}
        {this.render360Badge()}
        <LazyLoad height={185}>
          <img
            data-qa="poster"
            src={mainImage}
            onError={e => {
              e.currentTarget.onerror = null;
              e.target.src = makeAssetLink('placeholder.png');
            }}
            role="presentation"
            alt="main"
            style={styles.image}
          />
        </LazyLoad>
      </div>
    );
  }

  renderCardHeader(showItemLocation) {
    const { vehicleData } = this.props;

    const YMMT = [
      vehicleData.year || '',
      vehicleData.make || '',
      vehicleData.model || '',
      vehicleData.series || '',
    ].join(' ');

    const locationText =
      vehicleData.itemCity && vehicleData.itemState
        ? `${`${vehicleData.itemCity}, ${vehicleData.itemState}`}`
        : '';
    const isBulkLot =
      vehicleData.hasSubListings && vehicleData.subItemDetails.length > 0;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 74,
          color: '#6492bd',
        }}
      >
        <Dotdotdot clamp={1}>
          {isBulkLot ? (
            <div>
              <span style={styles.rowLabel}>Bulk Lot:</span>
              <span
                data-type="bulkList"
                style={{ textDecoration: 'underline' }}
                onClick={this.handleModal}
              >
                {vehicleData.subItemDetails.length + 1} Items
              </span>
            </div>
          ) : (
            <span>{YMMT}</span>
          )}
        </Dotdotdot>
        <Dotdotdot clamp={1}>
          <span style={{ fontSize: 12 }}>
            {!isBulkLot && vehicleData.bodyStyle}
          </span>
        </Dotdotdot>
        {showItemLocation && (
          <Dotdotdot clamp={1}>
            <span style={{ width: '100%', marginRight: 6, color: '#777' }}>
              {locationText}
            </span>
          </Dotdotdot>
        )}
      </div>
    );
  }

  renderHighOffer() {
    const { vehicleData } = this.props;

    if (!vehicleData.highOffer || vehicleData.highOffer === '0') return null;

    return (
      <div style={styles.rowContainer}>
        <div style={styles.rowLabel}>Current High Offer:</div>
        <div style={styles.rowValue}>
          {commafyCurrency(vehicleData.highOffer)}
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div style={styles.buttons}>
        {this.renderWatchlistButton()}
        {this.renderBidButton()}
        {this.renderOfferButton()}
        {this.renderBuyNowButton()}
        {this.renderDarwinButton()}
      </div>
    );
  }

  renderWatchlistButton() {
    const watchlistAddedDate =
      this.props.watchlistObject[this.props.vehicleData.id];
    const isInWatchlist = !!watchlistAddedDate;
    let formattedDate = '';

    if (isInWatchlist) {
      const timezone = jstz.determine().name();
      const adj = isDST() ? '07:00' : '08:00';
      formattedDate = moment
        .tz(`${watchlistAddedDate}-${adj}`, timezone)
        .format('ddd MM/DD/YY hh:mm a');
    }

    const dataAction = isInWatchlist ? 'remove' : 'add';
    const icon = isInWatchlist ? 'fa fa-check' : 'fa fa-plus';
    const title = isInWatchlist ? `Added ${formattedDate}` : 'Add to Watchlist';
    const { watchlistButtonDisabled } = this.state;
    const backgroundColor = getTemplate(
      this.props.template,
      'itemCardWatchlistButton.backgroundColor'
    );

    return (
      <button
        style={{
          ...styles.button,
          backgroundColor: isInWatchlist ? backgroundColor : 'transparent',
          color: isInWatchlist ? '#fff' : backgroundColor,
          border: isInWatchlist ? 'none' : `1px solid ${backgroundColor}`,
          opacity: watchlistButtonDisabled ? 0.5 : 1,
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          padding: '4px 6px',
        }}
        data-action={dataAction}
        title={title}
        onClick={
          watchlistButtonDisabled
            ? null
            : () => this.handleWatchlistAction(dataAction)
        }
      >
        <div className={icon} style={{ marginRight: 2, fontSize: '0.9em' }} />
        <div>Watchlist</div>
      </button>
    );
  }

  renderBidButton() {
    if (!showBidButton(this.props.vehicleData)) {
      return false;
    }
    const { hasSubListings, subItemDetails } = this.props.vehicleData;
    const isBulkLot = hasSubListings && subItemDetails.length > 0;

    return (
      <button
        data-type={isBulkLot ? 'bulkBid' : 'bid'}
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardBidButton.backgroundColor'
          ),
        }}
        onClick={this.handleModal}
      >
        Bid
      </button>
    );
  }

  renderOfferButton() {
    if (!showOfferButton(this.props.vehicleData)) {
      return null;
    }

    return (
      <button
        data-type="offer"
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardOfferButton.backgroundColor'
          ),
        }}
        onClick={this.handleModal}
      >
        <span>Offer</span>
      </button>
    );
  }

  renderBuyNowButton() {
    if (!showBuyNowButton(this.props.vehicleData)) {
      return null;
    }
    const {buyNowText} = this.state;
    return (
      <button
        data-type="buy"
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardBuyButton.backgroundColor'
          ),
        }}
        onClick={this.handleModal}
      >
        {buyNowText}
      </button>
    );
  }

  renderDarwinButton = () => {
    const {showVehicleValuesButton} = this.props;
    if (showVehicleValuesButton) {
      return (<VehicleValuesButton styles={styles.button} />);

    } else {
      return null;
    }
  }

  renderVehicleValuesData () {
    const {vehicleData, userProfile} = this.props;
    const accountList = userProfile?.user?.accountList || [];
    if (this.props.showVehicleValuesButton) {
      return (
        <StratosLoaderData itemData={vehicleData} accountList={accountList} />
      )
    } else {
      return null;
    }
  }

  renderStaticFields() {
    const { vehicleData } = this.props;
    const { hideSeller } = this.state;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.rowContainer}>
          <div style={styles.rowLabel}>VIN:</div>
          <div style={styles.rowValue}>
            {vehicleData.vIN || vehicleData.vin || 'N/A'}
          </div>
        </div>
        {this.renderCardBreak()}

        <div style={styles.rowContainer}>
          <div style={styles.rowLabel}>Odometer:</div>
          <div style={styles.rowValue}>
            {vehicleData.mileage || vehicleData.mileage === 0
              ? `${commafy(vehicleData.mileage)} ${findMileageUnits(
                  vehicleData
                )}`
              : 'N/A'}
          </div>
        </div>
        {this.renderCardBreak()}

        <div style={styles.rowContainer}>
          <div style={styles.rowLabel}>Color:</div>
          <div style={styles.rowValue}>
            {' '}
            {vehicleData.exteriorColor || 'N/A'}
          </div>
        </div>
        {this.renderCardBreak()}

        <div style={styles.rowContainer}>
          <div style={styles.rowLabel}>Engine:</div>
          <div style={styles.rowValue}>{vehicleData.engine || 'N/A'}</div>
        </div>
        {!hideSeller && this.renderCardBreak()}

        {!hideSeller && (
          <div style={styles.rowContainer}>
            <div style={styles.rowLabel}>Seller:</div>
            <div style={styles.rowValue}>{vehicleData.sellerName || 'N/A'}</div>
          </div>
        )}

        {this.renderCardBreak()}
        <div style={styles.rowContainer}>
          <div style={styles.rowLabel}>Photo count:</div>
          <div style={styles.rowValue}>
            {' '}
            {vehicleData.imageCount || (<p style={{fontStyle: 'italic', fontSize: 'inherit', margin: 0}}>{"Photos pending"}</p>)}
          </div>
        </div>
      </div>
    );
  }

  renderDynamicFields() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {[
          (this.props.vehicleData.attributes || [])
            .filter(attribute => attribute.inDetail)
            .map((attribute, index) => {
              const { label, value } = getAttributeLabelAndValue(attribute);
              return (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginRight: 6,
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginRight: 4 }}>
                    {label}
                  </div>
                  <div>{value}</div>
                </div>
              );
            }),
        ]}
      </div>
    );
  }

  renderDistance() {
    const { city, state, zipcode, address } = this.props.userSelectedLocation;
    const { latitude, longitude } = this.props.vehicleData;
    const { distance } = this.state;

    if (!distance) {
      return null;
    }

    const distanceUnits = findDistanceUnits(this.props.marketplaceFeatures);
    // const distanceIsTooFar =
    //   (distanceUnits === 'mi' && distance > 3300) ||
    //   (distanceUnits === 'km' && distance > 5311);

    const distanceRounded =
      distance < 100 ? distance.toFixed(1) : Math.round(distance);

    let distanceText =
      latitude || longitude
        ? `${commafy(distanceRounded)} ${distanceUnits}`
        : 'N/A';

    let displayAddress = ``;
    if (city && state) {
      displayAddress = `${city}, ${state}`;
    } else if (zipcode) {
      displayAddress = zipcode;
    } else if (address) {
      displayAddress = address
        .replace(/, USA/g, '')
        .replace(/USA/g, '')
        .replace(/[\d*]/g, '');
    }

    if (displayAddress) distanceText += ` from ${displayAddress}`;
    if (DISABLE_DISTANCE_IN_DEV && process.env['MY_NODE'] === 'development') {
      distanceText = 'DEV MODE';
    }

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ fontWeight: 'bold', marginRight: 4 }}>Distance:</div>
        <div>{distanceText}</div>
      </div>
    );
  }

  renderAnnouncements() {
    const { vehicleData } = this.props;

    const text = stripHtml(
      [vehicleData.description, vehicleData.announcements]
        .filter(a => a)
        .join('; ')
    );

    if (text) {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }} title={text}>
          <div style={styles.rowLabel}>Announcements:</div>
          <Dotdotdot clamp={2}>
            <div>{text}</div>
          </Dotdotdot>
        </div>
      );
    }
  }

  renderCountdownBlock() {
    return (
      <CountdownTimerVC
        vehicleData={this.props.vehicleData}
        onCountdownChange={() => this.setState({})}
      />
    );
  }

  renderWatchlistAddedDate() {
    const watchlistAddedDate =
      this.props.watchlistObject[this.props.vehicleData.id];
    if (watchlistAddedDate) {
      const timezone = jstz.determine().name();
      const adj = isDST() ? '07:00' : '08:00';
      const formattedDate = moment
        .tz(`${watchlistAddedDate}-${adj}`, timezone)
        .format('llll');

      return (
        <div>
          <strong>Added to Watchlist:</strong>
          <br />
          <span>{formattedDate}</span>
        </div>
      );
    }
  }

  renderStatusRibbon() {
    const { listingStatusId, listingStatus2Id, listingStatus } =
      this.props.vehicleData;
    let text = '';

    switch (listingStatus) {
      case 'Sold':
        text = 'SOLD';
        break;
      case 'If Sale':
        text = 'If Sale';
        break;
      case 'Cancelled':
        text = 'Removed';
        break;
      case 'Sale or Return':
        text = 'Not Available';
        break;
      case 'Ready For Release':
        text = 'Unlisted';
        break;
      case 'No Sale':
        text = 'No Sale';
        break;
      default:
        break;
    }

    if (
      (listingStatusId === 4 || listingStatusId === 7) &&
      listingStatus2Id === 103
    ) {
      text = 'Not Available';
    }

    if (!text) return null;

    return (
      <div className="ribbon">
        <span>{text}</span>
      </div>
    );
  }

  renderModals(isBulkLot) {
    const modalId = this.determineModalId();
    return (
      <div>
        <BidModal
          modalId={modalId}
          item={this.props.vehicleData}
          loadData={this.props.loadData}
          navigate={this.props.navigate}
          isOpen={this.state.isBidModalOpen}
          onClose={() => this.setState({ isBidModalOpen: false })}
        />
        <OfferModal
          modalId={modalId}
          item={this.props.vehicleData}
          loadData={this.props.loadData}
          offerAmount={0}
          offerId={0}
          offerType="Offer"
          requiredAmount={0}
          navigate={this.props.navigate}
          isOpen={this.state.isOfferModalOpen}
          onClose={() => this.setState({ isOfferModalOpen: false })}
        />
        <BuyModal
          modalId={modalId}
          item={this.props.vehicleData}
          loadData={this.props.loadData}
          navigate={this.props.navigate}
          isOpen={this.state.isBuyNowModalOpen}
          onClose={() => this.setState({ isBuyNowModalOpen: false })}
        />
        {isBulkLot && (
          <BulkLotBidModal
            modalId={modalId}
            item={this.props.vehicleData}
            loadData={this.props.loadData}
            bulkLotItems={this.state.bulkLotItems}
            navigate={this.props.navigate}
            isOpen={this.state.isBulkBidModalOpen}
            onClose={() => this.setState({ isBulkBidModalOpen: false })}
          />
        )}
        {isBulkLot && (
          <BulkLotItemsModal
            modalId={modalId}
            bulkLotItems={this.state.bulkLotItems}
            isOpen={this.state.isBulkLotItemsModalOpen}
            onClose={() => this.setState({ isBulkLotItemsModalOpen: false })}
            navigate={this.props.navigate}
          />
        )}
      </div>
    );
  }

  renderCardBreak() {
    return (
      <hr style={{ color: '#eee', margin: 0, marginTop: 4, marginBottom: 4 }} />
    );
  }

  renderBulkLotFields() {
    const vehicleData = this.props.vehicleData;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.rowValue}>
          {vehicleData.bulkLotDescription || 'N/A'}
        </div>
        <div style={styles.rowLabel}>Bidding on Multiple Items</div>
      </div>
    );
  }

  renderDynamicItem() {
    const { vehicleData, userProfile, key } = this.props;
    const { countdownInfo } = this.state;

    if (!vehicleData || !userProfile.user) return null;

    return (
      <div
        key={key}
        ref={el => (this.vehicleCardRef = el)}
        className="vehicle-card"
        style={styles.cardWrapper}
      >
        {this.renderModals()}
        <div
          style={{
            ...styles.cardContainer,
            height: 580, // dynamic cards
          }}
        >
          <div
            style={styles.imageAndContentContainer}
            onClick={this.handleCardClick}
          >
            {this.renderImageBlock()}
            <div style={styles.contentContainer}>
              <div>
                {this.renderCardHeader(true)}
                {this.renderCardBreak()}
                {this.renderDynamicFields()}
                {this.renderCardBreak()}
                {this.renderAnnouncements()}
                {!!this.renderAnnouncements() && this.renderCardBreak()}
                {this.renderDistance()}
                {!!this.renderDistance() && this.renderCardBreak()}
              </div>
            </div>
          </div>
          <div style={styles.footer}>
            {this.renderCountdownBlock()}
            {countdownInfo.countdownType && this.renderCardBreak()}
            {this.renderButtons()}
          </div>
        </div>
      </div>
    );
  }

  renderStaticItem() {
    const { vehicleData, userProfile, key } = this.props;
    const { countdownInfo, isHolman } = this.state;

    if (!vehicleData || !userProfile.user) return null;

    return (
      <div
        key={key}
        ref={el => (this.vehicleCardRef = el)}
        className="vehicle-card"
        style={styles.cardWrapper}
      >
        {this.renderModals()}
        <div
          style={{
            ...styles.cardContainer, // static
            height: isHolman ? 580 : 550,
          }}
        >
          <div
            style={styles.imageAndContentContainer}
            onClick={this.handleCardClick}
          >
            {this.renderImageBlock()}
            <div style={styles.contentContainer}>
              <div>
                {this.renderCardHeader(false)}
                {this.renderCardBreak()}

                {this.renderStaticFields()}
                {this.renderCardBreak()}

                {this.renderDistance()}
                {!!this.renderDistance() && this.renderCardBreak()}

                {this.renderHighOffer()}
                {!!this.renderHighOffer() && this.renderCardBreak()}
              </div>
            </div>
          </div>
          <div style={styles.footer}>
            {this.renderCountdownBlock()}
            {countdownInfo.countdownType && this.renderCardBreak()}
            {this.renderButtons()}
            {this.renderVehicleValuesData()}
          </div>
        </div>
      </div>
    );
  }

  renderBulkLotItem() {
    const { vehicleData, userProfile, key } = this.props;
    const { countdownInfo } = this.state;

    if (!vehicleData || !userProfile.user) return null;

    return (
      <div
        key={key}
        ref={el => (this.vehicleCardRef = el)}
        className="vehicle-card"
        style={{
          ...styles.cardWrapper,
          boxShadow: bulkLotBoxShadow,
        }}
      >
        {this.renderModals(true)}
        <div
          style={{
            ...styles.cardContainer,
            height: 580, // bulk lot
          }}
        >
          <div
            style={styles.imageAndContentContainer}
            onClick={this.handleCardClick}
          >
            {this.renderImageBlock()}
            <div style={styles.contentContainer}>
              <div>
                {this.renderCardHeader(true)}
                {this.renderCardBreak()}
                {this.renderBulkLotFields()}
              </div>
            </div>
          </div>
          <div style={styles.footer}>
            {this.renderCountdownBlock()}
            {countdownInfo.countdownType && this.renderCardBreak()}
            {this.renderButtons()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { hasSubListings, subItemDetails } = this.props.vehicleData;
    const isBulkLot = hasSubListings && subItemDetails.length > 0;
    const isDynamic = this.state.dynamicData;

    return isBulkLot
      ? this.renderBulkLotItem()
      : isDynamic
      ? this.renderDynamicItem()
      : this.renderStaticItem();
  }
}

Vehicle.propTypes = {
  homePage: PropTypes.bool,
  loadData: PropTypes.func,
  vehicleData: PropTypes.object.isRequired,
  navItems: PropTypes.string,
  showVehicleValuesButton: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const {
    geocode,
    item,
    marketplaceFeatures,
    marketplaces,
    maxCardHeight,
    offer,
    resetOffer,
    sellingNow,
    spinCars,
    template,
    unauthorizedEventIds,
    userProfile,
    userSelectedLocation,
    watchlistObject,
    watchlistUserItems,
  } = state.entities;
  return {
    geocode,
    item,
    marketplaceFeatures,
    marketplaces,
    maxCardHeight,
    offer,
    resetOffer,
    sellingNow,
    spinCars,
    template,
    unauthorizedEventIds,
    userProfile,
    userSelectedLocation,
    watchlistObject,
    watchlistUserItems,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loadAddWatchList,
    loadBidHistory,
    loadElasticSearchDistance,
    loadItem,
    loadRemoveWatchList,
    loadTransportationTypes,
    loadWatchlistES,
    loadWatchlistUserItems,
    resetOffer,
    resetMaxCardHeight,
    resetBidHistory,
    loadWatchlistObject,
  })
)(Vehicle);
