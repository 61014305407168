const prodConfigs = require('./index.prod');
const {
  makeApiRoot,
  makeIASConnectRoot,
  makeCoreRoot,
  makeSearchRoot,
  makeKeycloakUrl,
  makeSimulcastUrl,
  makeBookSheetUrl,
  makeSIAConnectRoot,
  makeAnylineLicense,
  makeHyperlaneUrl,
  makeDarwinStratosConfig,
} = require('./configHelpers');

const baseConfig = {
}

const config = {
  'dealernet-qa.iasmarketplace.com': {
    ...prodConfigs['dealernet.iasmarketplace.com'],
    ...baseConfig,
    bucket: 'qa.siamarketplace.com',
    apiRoot: makeApiRoot('awg-qa'),
    searchRoot: makeSearchRoot('ias-us-dealernet', '-qa'),
    iasConnectRoot: makeIASConnectRoot('signin-qa-dealernet'),
    booksheetUrl: makeBookSheetUrl('dealernet-mp'),
    liveSimulcastUrl: makeSimulcastUrl('dealernet-mp'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    anylineLicenseKey: makeAnylineLicense('dealernet-qa.iasmarketplace.com'),
    gaId: null,
  },
  'equipment-qa.holman.com': {
    ...prodConfigs['equipment.holman.com'],
    ...baseConfig,
    // iasConnectRoot: 'https://signin-equipment-qa.holman.com', // custom
    iasConnectRoot: makeIASConnectRoot('signin-qa-holman'),
    apiRoot: makeApiRoot('equipment-qa'),
    searchRoot: makeSearchRoot('ias-us-holman', '-qa'),
    liveSimulcastUrl: makeSimulcastUrl('equipment-qa-mp'),
    booksheetUrl: makeBookSheetUrl('equipment-qa-mp'),
    keycloakUrl: makeKeycloakUrl('ARI-Fleet-MP-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    realTimeUrl:
      'https://equipment-qa.autoremarketers.com/web/realtime/notificationHub',
    gaId: null,
  },
  'ice-qa.iasmarketplace.com': {
    ...prodConfigs['ice.iasmarketplace.com'],
    ...baseConfig,
    bucket: 'qa.siamarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-qa-ice'),
    apiRoot: makeApiRoot('awg-qa'),
    searchRoot: makeSearchRoot('ias-us-ice', '-qa'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    // liveSimulcastUrl: makeSimulcastUrl('awg-qa'),
    // hyperlaneUrl: makeHyperlaneUrl('awg-qa'),
    anylineLicenseKey: makeAnylineLicense('ice-qa.iasmarketplace.com'),
    gaId: null,
  },
  'lorensen-qa.siamarketplace.com': {
    ...prodConfigs['lorensen.siamarketplace.com'],
    ...baseConfig,
    bucket: 'qa.siamarketplace.com',
    iasConnectRoot: makeIASConnectRoot('signin-qa-lorensen'),
    apiRoot: makeApiRoot('awg-qa'),
    searchRoot: makeSearchRoot('ias-us-lorensen', '-qa'),
    liveSimulcastUrl: makeSimulcastUrl('lorensen-qa'),
    hyperlaneUrl: makeHyperlaneUrl('lorensen-qa'),
    keycloakUrl: makeKeycloakUrl('IAS-Marketplace-QA', '-qa'),
    coreRoot: makeCoreRoot('awg-qa'),
    anylineLicenseKey: makeAnylineLicense('lorensen-qa.siamarketplace.com'),
    gaId: null,
    marketplaceId: '564',
    stratosConfig: makeDarwinStratosConfig('564'),
    enableBuyerSellerArbitration: true,
    enableBuyerTitles: false,
    enableSellerTitles: true,
    enableStonlyWidget: true,
  },

  default: {
    marketplaceId: '0',
  },
};

module.exports = config;
