
const fieldPatterns = {
    urlRoots: {
        regex: /^https?:\/\/.+\/$/i,
        fields: ['apiRoot', 'searchRoot', 'keycloakUrl', 'coreRoot']
    },
    fullUrls: {
        regex: /^https?:\/\/.+[^/]$/i,
        fields: ['termsLink', 'hyperlaneUrl', 'liveSimulcastUrl', 'booksheetUrl', 'iasConnectRoot']
    },
    requiredFields: [
        'apiRoot', 
        'bucket', 
        'iasConnectRoot', 
        'keycloakUrl', 
        'name', 
        'searchRoot', 
        'shortName'
    ]
}

/**
 * @description Check for common input mistakes; Validate expected patterns, 
 *              required fields, log errors and warning so they can be caught during setup.
 * @param {{[key:string]: any}} mpConfig Config object returned from `/api/configuration`
 * @returns {{[key:string]: any}|null}
 */
export function prepareMpConfig (mpConfig) {
    const marketplaceConfig = {};
    const errorMessages = [];
    try {
        if (!mpConfig || !Object.keys(mpConfig).length) {
            return mpConfig;
        }
        const {urlRoots, fullUrls, requiredFields} = fieldPatterns;
        Object.entries(mpConfig).forEach(([configField, configValue]) => {
            // config is empty or null --> throw error if required field
            if (configValue === "" | configValue === null) {
                if (requiredFields.includes(configField)) {
                    throw new Error(`* Error:  MP Configuration > required field: '${configField}' is empty or null *`)
                }
                marketplaceConfig[configField] = configValue;
            }
            // check if valid url pattern & does NOT end in slash --> log warning but still assign
            else if (fullUrls.fields.includes(configField)) {
                marketplaceConfig[configField] = configValue;
                if (!fullUrls.regex.test(configValue)) {
                    errorMessages.push(`Property:  '${configField}'  - -  Does not follow valid url format: 'http(s)://xxx`);
                }

            }
            // check if root url ends with '/' --> append '/' to value if not
            else if (urlRoots.fields.includes(configField)) {
                if (!urlRoots.regex.test(configValue)) {
                    errorMessages.push(`Property:  '${configField}'  - -  Does not follow valid url format: 'http(s)://xxx/path/`);
                    marketplaceConfig[configField] = /.+\/$/.test(configValue)
                        ? configValue
                        : `${configValue}/`;
                } else {
                    marketplaceConfig[configField] = configValue;
                }
            }
            // no specific checks
            else {
                marketplaceConfig[configField] = configValue;
            }
        });
        if (errorMessages.length) console.warn(...errorMessages);
        // IAS Connect - uncomment to point to local Connect instance
        // if (process.env.NODE_ENV === 'development') {
        //     marketplaceConfig.iasConnectRoot = 'http://localhost:5000';
        // }
        return marketplaceConfig;

    } catch (mpConfigError) {
        if (errorMessages.length) console.warn(...errorMessages);
        console.error(mpConfigError);
        return null;
    }
}