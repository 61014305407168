import { formatPhoneNumber, getConfig } from "../../utils/helpers";

const userFields = [
    {
      field: 'firstName',
      label: 'First Name',
      required: true,
      edit: true,
    },
    {
      field: 'lastName',
      label: 'Last Name',
      required: true,
      edit: true,
    },
    {
      field: 'address1',
      label: 'Address Line 1',
      required: true,
      edit: true,
      upperCase: true,
    },
    {
      field: 'address2',
      label: 'Address Line 2',
      required: false,
      edit: true,
      upperCase: true,
    },
    {
      field: 'city',
      label: 'City',
      required: true,
      edit: true,
      upperCase: true,
      hide: getConfig('enableAddressValidation'),
    },
    {
      field: 'state',
      label: 'State',
      required: true,
      edit: true,
      upperCase: true,
      hide: getConfig('enableAddressValidation'),
    },
    {
      field: 'county',
      label: 'County',
      required: true,
      edit: true,
      upperCase: true,
      hide: getConfig('enableAddressValidation'),
    },
    {
      field: 'zipcode',
      label: 'ZIP Code',
      required: true,
      edit: true,
      maxLength: 5,
      minLength: 5,
      validate: (value) => /^\d{5}$/.test(value),
      editValue: (value) => value.replace(/[^\d-]/g, '').slice(0, 5), // 5 numeric digits or hyphen?
    },
    {
      field: 'country',
      label: 'Country',
      required: true,
      edit: !getConfig('enableAddressValidation'),
      upperCase: true,
    },
    {
      field: 'phone',
      label: 'Phone',
      required: false,
      edit: true,
      editValue: (value) => formatPhoneNumber(value),
      validate: (value='') => {
        const digitsLen = value.replace(/\D/g, '').length;
        return [0,10,11].includes(digitsLen);
      }
    },
    {
      field: 'aaRepId',
      label: 'Auction ACCESS Rep #',
      required: false,
      edit: true,
      hide: getConfig('hideAACredsUpdate'),
    },
    {
      field: 'aaGovId',
      label: 'Last 4 of Government ID',
      required: false,
      edit: true,
      hide: getConfig('hideAACredsUpdate'),
    },
  ].filter(field => !field.hide);
  
  export {userFields};