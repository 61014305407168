import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import { CYAN_01, BLACK_02, GREY_05, BLACK_03 } from '../../constants/colors';

class VinVerificationModal extends Component {
  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-9">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>

        <div className="fs-20 font-semibold" style={{ color: BLACK_02 }}>
          VIN Verification
        </div>

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="flex justify-between modal-mt">
        <div className="w-1/2" style={{ width: '50%' }}>
          <Button
            btnText="Cancel and Go Back"
            outlined={true}
            outlinedTextColor={BLACK_03}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onBack}
          />
        </div>
        <div className="ml-2" style={{ width: '50%' }}>
          <Button
            btnText="Verify as Correct"
            outlined={false}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onNext}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          shouldCloseOnEsc
          style={styles.customStyles}
          contentLabel={'vinVerification'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            {this.renderHeader()}
            <div
              className="fs-14 text-base font-medium mb-8"
              style={{ color: BLACK_03 }}
            >
              The vin entered appears to be invalid or non-standard. Please
              double check to verify if the above vin is correct or cancel to go
              back.
            </div>
            <div
              className="fs-14 text-base font-semibold flex justify-center"
              style={{ color: BLACK_03 }}
            >
              {this.props.vinInput}
            </div>
            {this.renderButtons()}
          </div>
        </Modal>
      </div>
    );
  }
}

const styles = {
  customStyles: {
    content: {
      display: 'block',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      zIndex: 99,
    },
  },
};

VinVerificationModal.propTypes = {
  vinInput: PropTypes.string.isRequired,
};

export default connect(null, {})(VinVerificationModal);
