import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { titleCase } from '../../utils/helpers';
import { dataPlaceholder } from '../../utils/constants';
import { inventoryConstants } from '../../models';

let styles = {};

class TableDropdownFilter extends Component {
  getDataForDropdown = id => {
    const labels = (this.props.data || [])
      .map((item, index) => {
        return {
          key: index,
          id,
          label: this.getLabelForDropdown(item, id),
          // value: item[id],
          value: this.getValueForDropdown(item, id),
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .sort((a, b) => {
        if (a.label === 'Coming Soon') return 1;
        if (b.label === 'Coming Soon') return -1;
        return null;
      })
      .sort((a, b) => {
        if (/true/i.test(a.label)) return -1;
        if (/false/i.test(b.label)) return 1;
        return null;
      });

    return uniqBy(labels, 'label');
  };
  getValueForDropdown (item, id) {
    let property = id;
    switch (id) {
      case 'newOrUsed':
        property = 'inventoryType';
        return item[property] 
          ? item[property].substring(0,1)
          : dataPlaceholder;
      case 'retailOrWholesale':
        property = 'saleType';
        return item[property] 
          ? item[property].substring(0,1)
          : dataPlaceholder;
      default:
        // break;
        return item[property];
    }
  }
  
  getLabelForDropdown (item, id) {
    let property;
    switch(id) {
      case 'make':
        return titleCase(item[id]);
      case 'seller':
        return item.sellerName || item.sName;
      case 'extColor':
        return item.exteriorColor || item[id];
      case 'locked' && [true,false].includes(item[id]): 
        return (item[id] === true) ? 'Locked' : 'Unlocked';
      case 'newOrUsed':
        const {NEW, USED} = inventoryConstants.inventoryType;
        property = 'inventoryType'
        if (item[property] === 'N' || item[property] === NEW) {
          return `N - ${NEW}`;
        } else if (item[property] === 'U' || item[property] === USED) {
          return `U - ${USED}`
        } else {
          return item[property];
        }
        case 'retailOrWholesale':
          const {WHOLESALE, RETAIL} = inventoryConstants.saleType;
          property = 'saleType'
        if (item[property] === 'R' || item[property] === RETAIL) {
          return `R - ${RETAIL}`;
        } else if (item[property] === 'W' || item[property] === WHOLESALE) {
          return `W - ${WHOLESALE}`
        } else {
          return item[property]
        }

      default:
        if (item[id] === true) return 'true';
        if (item[id] === false) return 'false';
        return item[id];
    }
  }

  render() {
    const placeholderText = '';
    const blankOption = (
      <option key={'blank'} value={''}>
        {placeholderText}
      </option>
    );

    const uniqueOptions = this.getDataForDropdown(this.props.column.id).map(
      item => {
        return (
          <option key={item.key} style={styles.option} value={item.value}>
            {item.label || dataPlaceholder}
          </option>
        );
      }
    );

    return (
      <select
        onChange={event => this.props.onChange(event.target.value)}
        style={{
          ...styles.select,
          backgroundImage: this.props.filter ? 'none' : null,
        }}
        value={this.props.filter ? this.props.filter.value : ''}
      >
        {[blankOption, ...uniqueOptions]}
      </select>
    );
  }
}

styles = {
  select: {
    width: '100%',
    paddingTop: 'auto',
    paddingRight: 10,
    paddingBottom: 'auto',
    paddingLeft: 10,
    height: 28,
    borderRadius: 4,
  },
  option: {},
};

TableDropdownFilter.defaultProps = {
  updateCurrentData: () => false,
};

TableDropdownFilter.propTypes = {
  column: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  updateCurrentData: PropTypes.func,
};

export default TableDropdownFilter;
