import React, { Component } from 'react';
import Modal from 'react-modal';
import { getConfig } from '../utils/helpers';

const styles = {
  customStyles: {
    // content: replaced with css: '.marketplace-reusable__modal-content' in 'styles.css'
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 15,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
};

class BulkLotItemsModal extends Component {
  handleItemClick = id => {
    this.onClose();
    const path = `/item/${id}`;
    this.props.navigate(path);
  };

  onOpen = () => {
    //
  };

  onClose = () => {
    this.props.onClose();
  };

  renderProxyHardTabs() {
    if (getConfig('disableHardBid')) {
      return (
        <div style={styles.proxyHardContainer}>
          <button disabled style={styles.proxyButtonOnly}>
            Proxy Bid
          </button>
        </div>
      );
    }

    return (
      <div style={styles.proxyHardContainer}>
        <button
          type="button"
          style={{
            ...styles.proxyHardShared,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            color: this.state.bidType === '1' ? '#FFF' : '#404040',
            backgroundColor: this.state.bidType === '1' ? '#787878' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.bidType !== '1') this.setState({ bidType: '1' });
          }}
        >
          Proxy
        </button>
        <button
          type="button"
          style={{
            ...styles.proxyHardShared,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 'none',
            color: this.state.bidType === '2' ? '#FFF' : '#404040',
            backgroundColor: this.state.bidType === '2' ? '#787878' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.bidType !== '2') this.setState({ bidType: '2' });
          }}
        >
          Hard
        </button>
      </div>
    );
  }

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div style={styles.header}>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>Bulk Lot</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderContent() {
    return (this.props.bulkLotItems || []).map(item => {
      return (
        <div key={item.listingId}>
          <h4
            className="underline-on-hover"
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleItemClick(item.listingId)}
          >
            {item.cardTitle}
          </h4>
          <p>
            <strong style={{ marginRight: 4 }}>Vin:</strong>
            <span>{item.vin || item.vIN}</span>
          </p>
          <p>
            <strong style={{ marginRight: 4 }}>Location:</strong>
            <span>{item.location}</span>
          </p>
          <hr />
        </div>
      );
    });
  }

  renderFooter() {
    return (
      <div style={styles.footer}>
        <div />
        <div style={styles.buttons}>
          <button
            className="btn btn-primary button-cancel"
            style={{ minWidth: 100 }}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        className="marketplace-reusable__modal-content"
        contentLabel="Bulk List Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div className='marketplace-reusable__modal-container'>
          {this.renderHeader()}
          {this.renderDivider()}
          <div style={styles.body}>
            {this.renderContent()}
            {this.renderFooter()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default BulkLotItemsModal;
