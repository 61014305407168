const prodConfigs = require('./index.prod');
const qaConfigs = require('./index.qa');
const allConfigs = { ...qaConfigs, ...prodConfigs };

/*
  To change customer running locally just change the DEV_CUSTOMER value.
  It needs to to match one the keys from index.prod.js or index.qa.js
  You can just put the subdomain if the domain is siamarketplace.com or iasmarketplace.com
  Examples: ice-qa, ice-qa.iasmarketplace.com, mpdemo, mpdemo.siamarketplace.com
*/
// const DEV_CUSTOMER = 'frontrange.iasmarketplace.com';
// const DEV_CUSTOMER = 'mpdemo.siamarketplace.com';
const DEV_CUSTOMER = 'equipment-qa.holman.com';
// const DEV_CUSTOMER = 'ice-qa.iasmarketplace.com';
// const DEV_CUSTOMER = 'lorensen-qa.siamarketplace.com';
// const DEV_CUSTOMER = 'darwin';

const devConfig = allConfigs[DEV_CUSTOMER] ||
                  allConfigs[`${DEV_CUSTOMER}.siamarketplace.com`] ||
                  allConfigs[`${DEV_CUSTOMER}.iasmarketplace.com`] || 
                  { marketplaceId: '0' };
/*
  Override for (currently only used by Holman) agreeToTermsAtLogin config
    Jira Card:  MP-875
*/
// devConfig.agreeToTermsAtLogin = true;

if (devConfig.marketplaceId === '0') {
  console.error(`ERROR: No config found for "${devConfig}"`);
}

/*
  To sign in while running locally, you must either:
  1. Run ias-connect locally in a matching environment (i.e. qa or production)
  2. Put a valid refresh token in the url: e.g. http://localhost:3000/?refreshToken=abc123
*/
// devConfig.iasConnectRoot = 'http://localhost:5000';
// devConfig.apiRoot = `http://localhost:53980/api/services/`;

// Disable google analytics.
devConfig.gaId = null;

const config = { default: devConfig, localhost: devConfig, devEndpoint: DEV_CUSTOMER };

module.exports = config;
