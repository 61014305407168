import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from 'lodash';
import { shippingRequestBodyStatic } from '../../models';
import { loadBookShipping } from '../../actions';
import { toastr } from 'react-redux-toastr';

/** RoyaltyContact - Contact form to request transport services from Royalty Logistics.
 * 
 * @param {{
 *  mpId: string|number, 
 *  itemData: object, 
 *  user: object, 
 *  quoteProvider: 'RoyaltyShips' | 'Acertus' | 'Runbuggy', 
 *  confirmationText: string, 
 *  agreementText: string, 
 *  onClose: ()=>void, 
 *  selectedQuote: object
 * }} props 
 * @returns {ReactNode}
 */
export default function ContactForm({ mpId, itemData, user, quoteProvider, confirmationText, agreementText, onClose, selectedQuote }) {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      alignContent: 'space-around',
      flexWrap: 'wrap',
      padding: '0 .15rem',
    },
    divider: {
      borderColor: '#E5E5E5',
      width: '100%',
      alignSelf: 'center',
      marginTop: 15,
      marginBottom: 10,
    },
    fieldsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1.5fr)',
      gap: '.6em',
      alignItems: 'center',
      alignContent: 'space-between',
      textAlign: 'center'
    },
    buttons: {
      display: 'flex',
      justifyItems: 'center',
      flexDirection: 'row',
      alignSelf: 'flex-end',
      gap: '1em'
    },
    fieldLabel: {
      fontSize: 'clamp(1rem, 1.8vw, 1.25rem)',
      fontWeight: 'bolder',
      textDecorationLine: 'underline',
    },
    fieldValue: {
      fontSize: 'clamp(.85rem, 1.75vw, 1.1rem)',
    }
  }

  const [isDisclosureChecked, setIsDisclosureChecked] = useState(false);
  const disclosureRef = useRef(null);
  const dispatch = useDispatch();

  const formData = Object.assign({}, shippingRequestBodyStatic);
  const {
    firstName, phone, mobile, lastName,
    email, address1, address2,
    city, state, zipcode, accountName
  } = user;
  const streetAddress = address2 ? `${address1} ${address2}` : address1;
  const phoneNumber = phone || mobile || "";
  formData.vin = itemData?.vin;
  formData.quote_rate = selectedQuote?.rate;
  formData.billing_firstname = firstName;
  formData.billing_lastname = lastName;
  formData.billing_email = email;
  formData.billing_street = streetAddress;
  formData.billing_state = state;
  formData.billing_zip = zipcode;
  formData.billing_phone = phoneNumber;
  formData.delivery_company = accountName || "";
  const [form, setForm] = useState({...formData});
  
  function handleSubmit() {
    const { itemId } = itemData;
    const shippingFormData = {...form};
    if (selectedQuote?.rate !== form.quote_rate) {
      shippingFormData.quote_rate = selectedQuote?.rate;
    }
    dispatch(loadBookShipping(mpId, itemId, shippingFormData, quoteProvider))
    .then(res => {
      onClose();
      toastr.success(
        'Success!',
        confirmationText,
        { position: 'top-center',
          timeOut: 2500,
          onHideComplete: ()=>onClose()
        }
      );
    })
    .catch(shippingSubmitErr => {
      toastr.error(
        'Shipping request error',
        'An error occurred when requesting shipping, please try again later.',
        { position: 'top-center', 
          timeout: 3000,
          onHideComplete: ()=>onClose()
        }
      );
      console.error(shippingSubmitErr);
    });
  }

  function renderDisclosure() {

    return (
      <div style={{
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        justifyContent: 'space-around',
        justifyItems: 'center',
        width: 'auto',
        margin: '.25rem .5rem'
      }
      }>
        <label style={{ verticalAlign: 'center', margin: '0 auto', fontSize: '1.15em', fontStyle: 'italic' }} >
          {agreementText}
        </label>
        <input
          aria-label={'agree'}
          defaultChecked={false}
          onChange={() => { setIsDisclosureChecked(disclosureRef.current.checked) }}
          type={'checkbox'}
          style={{ verticalAlign: 'middle', margin: '0 auto', width: '1.25em', height: '1.25em' }}
          ref={disclosureRef}
        />
      </div>
    )
  }

  function renderFooter() {
    const isSubmitDisabled = !!(!isDisclosureChecked || isEmpty(selectedQuote) || selectedQuote?.errorMessage);
    return (
      <div style={styles.buttons}>
        {/* 'Cancel' button */}
        <button
          className="btn btn-primary button-cancel"
          style={{
            minWidth: 100,
          }}
          type="button"
          data-dismiss="modal"
          onClick={() => { onClose() }}
        >
          Cancel
        </button>

        {/* 'Submit' button */}
        <button
          className="btn btn-primary button-action"
          style={{
            opacity: isSubmitDisabled ? 0.25 : 1,
            minWidth: 120,
          }}
          disabled={isSubmitDisabled}
          type="button"
          onClick={() => handleSubmit()}
        >
          Book Transport
        </button>
      </div>
    );
  }

  function renderImmutable() {
    const {
      firstName, phone, mobile, lastName,
      email, address1, address2,
      city, state, zipcode, accountName
    } = user;
    const streetAddress = address2 ? `${address1} ${address2}` : address1;
    const phoneNumber = phone || mobile || "";
    const vin = itemData.vin;

    const contactElements = [
      { label: "First Name", value: firstName }, { label: "Last Name", value: lastName },
      { label: "Email address", value: email }, { label: "Phone Number", value: phoneNumber }
    ]
    const addressElements = [
      { label: "Street address", value: streetAddress }, { label: "City", value: city },
      { label: "State", value: state }, { label: "Zip", value: zipcode }
    ]

    return (
      <>
        {/* first, last, email, phone */}
        <div style={styles.fieldsContainer}>
          {contactElements.map((item, i) =>
            <div style={styles.container} key={i}>
              <label style={styles.fieldLabel} >
                {item.label}
              </label>
              <p style={styles.fieldValue}>{item.value}</p>
            </div>
          )}
        </div>

        {renderDivider()}

        {/* street, city, state, zip */}
        <div style={styles.fieldsContainer} >
          {addressElements.map((item, i) =>
            <div key={i}>
              <label style={styles.fieldLabel} >
                {item.label}
              </label>
              <p style={styles.fieldValue}>{item.value}</p>
            </div>
          )}
        </div>

        {renderDivider()}
      </>
    )
  }

  function renderDivider() {
    return (<hr style={styles.divider} />)
  }

  return (
    <>
      {renderDivider()}
      <div style={{ margin: ".25rem auto .75rem" }}>
        <h3 style={{ textAlign: "center" }}>Please Confirm Your Information: </h3>
      </div>
      {renderImmutable()}
      {renderDisclosure()}
      {renderDivider()}
      {renderFooter()}

    </>
  );
}