import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import { RED_03, GREY_06, RED_02, WHITE_01 } from '../../constants/colors';
import { compose } from '../../utils/helpers';
import withRouter from '../../../../hocs/withRouter';

class ConfirmationModal extends Component {
  onClose = () => {
    this.props.close();
  };
  onAction = () => {
    this.props.action();
    this.props.close();
  };
  render() {
    const {
      open,
      title,
      body,
      confirmBtnTxt = 'Delete',
      iconName = 'Bin',
      closeBtnVisible = true,
    } = this.props;
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        zIndex: 99,
        position: 'absolute',
        borderRadius: '8px',
        border: 'none',
        boxShadow:
          '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 99,
      },
    };
    return (
      <div>
        <Modal
          isOpen={open}
          shouldCloseOnEsc
          style={customStyles}
          contentLabel={'getAutograde'}
          ariaHideApp={false}
        >
          <div className="landing-page-modal-width">
            <div
              className="flex items-center justify-between mb-4 pb-3"
              style={{ borderBottom: '1px solid #E5E5E6' }}
            >
              <div className="flex justify-between items-center w-100">
                <div
                  style={{
                    backgroundColor: RED_02,
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    height: '32px',
                    minWidth: '32px',
                  }}
                >
                  <Icon name={iconName} />
                </div>
                <div
                  className="pl-4 pr-2 text-lg font-semibold modal-responsive-title"
                  style={{ flex: '1', color: RED_03 }}
                >
                  {title}
                </div>
                <div onClick={this.onClose} style={{ alignSelf: 'stretch' }}>
                  <Icon name="Cross" size={28} color={GREY_06} />
                </div>
              </div>
            </div>
            <div
              className="fs-16 text-base font-normal mb-8 modal-responsive-body"
              style={{ color: GREY_06 }}
            >
              {body}
            </div>
            <div className="flex justify-end modal-two-btn-container">
              {closeBtnVisible && (
                <div className="modal-single-button-width">
                  <Button
                    btnText="Cancel"
                    outlined={true}
                    outlinedBgColor={WHITE_01}
                    fontSize="14px"
                    borderRadius="6px"
                    onClickHandler={this.onClose}
                  />
                </div>
              )}
              {this.props.action ? (
                <div className="modal-single-button-width">
                  <Button
                    btnText={confirmBtnTxt}
                    fontSize="14px"
                    borderRadius="6px"
                    onClickHandler={this.onAction}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  action: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  confirmBtnTxt: PropTypes.string,
  iconName: PropTypes.string,
  closeBtnVisible: PropTypes.bool,
};

export default compose(withRouter)(ConfirmationModal);
