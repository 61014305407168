import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { loadSellerIfAccept, loadSellerIfReject, loadSellerIfs, resetSellerIfs } from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';
import { Button } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { SellerIfAcceptModal } from './SellerIfAccepttModal';
import { SellerIfRejectModal } from './SellerIfRejectModal';

class SellerIfs extends Component {
  state = {
    title: 'seller ifs',
    isSellerIfAcceptModalOpen: false,
    isSellerIfRejectModalOpen: false,
    acceptOrRejectItemId: '',
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerIfs();
    this.props.loadSellerIfs(getConfig('marketplaceId'));
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };
  
  handleIfAcceptSubmit = (accountId, comments) => {
    this.props.loadSellerIfAccept(
      getConfig('marketplaceId'),
      this.state.acceptOrRejectItemId,
      comments,
      accountId
    ).then(({response}) => {
      if (response.wsStatus === 'Success') {
        this.setState({isSellerIfAcceptModalOpen: false});
        toastr.success(response.wsStatus, response.wsMessage);
        this.loadData();
      } else {
        this.setState({isSellerIfAcceptModalOpen: false});
        toastr.error(response.wsStatus, response.wsMessage);
      }
    })
  }
  handleIfRejectSubmit = (comments="") => {
    this.props.loadSellerIfReject(
      getConfig('marketplaceId'), 
      this.state.acceptOrRejectItemId,
      comments,
    ).then(({response}) => {
      if (response.wsStatus === 'Success') {
        toastr.success(response.wsStatus, response.wsMessage);
        this.setState({isSellerIfRejectModalOpen: false});
        this.loadData();
      } else {
        this.setState({isSellerIfRejectModalOpen: false});
        toastr.error(response.wsStatus, response.wsMessage || "An unknown issue has occurred, please try again.");
      }
    })
  }
  handleConfirmButtonClick = ({itemId}) => {
    this.setState({
      acceptOrRejectItemId: itemId, 
      isSellerIfAcceptModalOpen: true
    });
  }
  handleRejectButtonClick = ({itemId}) => {
    this.setState({
      acceptOrRejectItemId: itemId, 
      isSellerIfRejectModalOpen: true
    });
  }

  render() {
    const loading = isEmpty(this.props.sellerIfs);
    const data = this.props.sellerIfs.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      {
          Header: 'Actions',
          headerStyle: { background: 'none' },
          id: 'buttons',
          sortable: false,
          filterable: false,
          Cell: row => {
            const {enableIfSaleStatusUpdate} = row?.original || 0;
            return (
              <div style={styles.buttonGroup}>
                {/* 'View Details' */}
                <Button
                  style={styles.button}
                  onClick={() =>
                    this.handleDetailsClick(row.original.itemId, data)
                  }
                >
                  View Details
                </Button>
                {/* If Sale - 'Accept' */}
                {enableIfSaleStatusUpdate 
                ? (<Button 
                    style={{
                      ...styles.button,
                      backgroundColor: styles.button.backgroundColor,
                    }} 
                    onClick={() => this.handleConfirmButtonClick(row.original)}
                    data-vehicle-index={row.index}
                    title={"Confirm the sale"}
                  >
                    Confirm
                  </Button>) : null}
                {/* If Sale - 'Reject' */}
                {enableIfSaleStatusUpdate 
                  ? (
                  <Button 
                    style={{
                      ...styles.button,
                      backgroundColor: styles.button.backgroundColor,
                    }} 
                    onClick={(e) => this.handleRejectButtonClick(row.original)}
                    data-vehicle-index={row.index}
                    title={"Reject the sale"}
                  >
                    Reject
                  </Button>) : null}
              </div>
            )
          }
        },
        'lotNumber',
        'vin',
        'year',
        'make',
        'model',
        'amount',
        'reservedDate',
        'buyer',
      ]
    );

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>If’s / Reserved</h4>
        {this.state.isSellerIfAcceptModalOpen
          ? (
            <SellerIfAcceptModal 
              isOpen={this.state.isSellerIfAcceptModalOpen}
              onClose={() => this.setState({isSellerIfAcceptModalOpen: false})}
              onConfirm={this.handleIfAcceptSubmit}
            />)
          : null
        }
        {this.state.isSellerIfRejectModalOpen
          ? (
            <SellerIfRejectModal
              isOpen={this.state.isSellerIfRejectModalOpen}
              onClose={() => this.setState({isSellerIfRejectModalOpen: false})}
              onConfirm={this.handleIfRejectSubmit}
            />)
          : null
        }
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { sellerIfs } = state.entities;
  return { sellerIfs };
};

export default compose(
  withTable,
  connect(
    mapStateToProps,
    { loadSellerIfs, resetSellerIfs, loadSellerIfAccept, loadSellerIfReject }
  )
)(SellerIfs);
